import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Decimal: { input: number; output: number; }
  JSONString: { input: string; output: string; }
  Time: { input: any; output: any; }
  UUID: { input: string; output: string; }
};

export type AcceptApplication = {
  readonly __typename?: 'AcceptApplication';
  readonly application?: Maybe<ApplicationNode>;
};

export type AcceptMessageSuggestionInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly suggestionId: Scalars['UUID']['input'];
};

export type AcceptMessageSuggestionPayload = {
  readonly __typename?: 'AcceptMessageSuggestionPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<ConversationType>;
};

export type AcceptServiceChargeWaiverInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly serviceChargeWaiverAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AcceptServiceChargeWaiverPayload = {
  readonly __typename?: 'AcceptServiceChargeWaiverPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly leaseholder?: Maybe<LeaseholderNode>;
};

export type AcceptTermsAndConditionsInput = {
  readonly acceptedAt: Scalars['DateTime']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptTermsAndConditionsPayload = {
  readonly __typename?: 'AcceptTermsAndConditionsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly success?: Maybe<Scalars['Boolean']['output']>;
};

export type AccountTransactionNode = {
  readonly __typename?: 'AccountTransactionNode';
  readonly account?: Maybe<PaymentAccountNode>;
  readonly amount: Scalars['BigInt']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly status: PaymentsPaymentStatusChoices;
};

export type AccountTransactionNodeConnection = {
  readonly __typename?: 'AccountTransactionNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AccountTransactionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `AccountTransactionNode` and its cursor. */
export type AccountTransactionNodeEdge = {
  readonly __typename?: 'AccountTransactionNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<AccountTransactionNode>;
};

export type ActionObject = AmenityReservationNode | ApplicationNode | CommunityProductPurchaseNode | CommunityUpdateCommentNode | KeyholderNode;

export type ActivateGroupInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ActivateGroupPayload = {
  readonly __typename?: 'ActivateGroupPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityGroup?: Maybe<CommunityGroupNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type Actor = ApplicantNode | ConciergeNode | ResidentNode | TenancyApplicantNode | UserType;

export type AddApplicationCommunity = {
  readonly __typename?: 'AddApplicationCommunity';
  readonly application?: Maybe<ApplicationNode>;
};

export type AddApplicationHome = {
  readonly __typename?: 'AddApplicationHome';
  readonly application?: Maybe<ApplicationNode>;
};

export type AddApplicationOwnershipType = {
  readonly __typename?: 'AddApplicationOwnershipType';
  readonly application?: Maybe<ApplicationNode>;
};

export type AddDocumentSentStatus = {
  readonly __typename?: 'AddDocumentSentStatus';
  readonly application?: Maybe<ApplicationNode>;
};

export type AddFeatureToClientInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly featureNames: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
};

export type AddFeatureToClientPayload = {
  readonly __typename?: 'AddFeatureToClientPayload';
  readonly client?: Maybe<ClientType>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type AddMemberInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly communities: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly role: Scalars['String']['input'];
};

export type AddMemberPayload = {
  readonly __typename?: 'AddMemberPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communities?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly email?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly role?: Maybe<Scalars['String']['output']>;
};

export type AddPackageToHomeInput = {
  readonly addressee: Scalars['String']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly home: Scalars['UUID']['input'];
  readonly manualHomeSearchQuery?: InputMaybe<Scalars['String']['input']>;
  readonly scanOutput?: InputMaybe<Scalars['String']['input']>;
};

export type AddPackageToHomePayload = {
  readonly __typename?: 'AddPackageToHomePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly package?: Maybe<PackageNode>;
};

export type AddUnknownCommunityStatus = {
  readonly __typename?: 'AddUnknownCommunityStatus';
  readonly application?: Maybe<ApplicationNode>;
};

export type AgreementSignatureNode = {
  readonly __typename?: 'AgreementSignatureNode';
  readonly agreement: GeneratedAgreementNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly email: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly signatureType: AstGeneratorAgreementSignatureSignatureTypeChoices;
  readonly signedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly status: AstGeneratorAgreementSignatureStatusChoices;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type AgreementSignatureNodeConnection = {
  readonly __typename?: 'AgreementSignatureNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AgreementSignatureNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `AgreementSignatureNode` and its cursor. */
export type AgreementSignatureNodeEdge = {
  readonly __typename?: 'AgreementSignatureNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<AgreementSignatureNode>;
};

export type AgreementSignatureOutput = {
  readonly __typename?: 'AgreementSignatureOutput';
  readonly email: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type AgreementSignaturesTenant = {
  readonly email: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
};

export type AgreementTenantNode = {
  readonly __typename?: 'AgreementTenantNode';
  readonly agreement: GeneratedAgreementNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly mobile: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type AgreementTenantNodeConnection = {
  readonly __typename?: 'AgreementTenantNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AgreementTenantNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `AgreementTenantNode` and its cursor. */
export type AgreementTenantNodeEdge = {
  readonly __typename?: 'AgreementTenantNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<AgreementTenantNode>;
};

export type AmenityNode = {
  readonly __typename?: 'AmenityNode';
  readonly communities?: Maybe<ReadonlyArray<Maybe<CommunityNode>>>;
  readonly community?: Maybe<CommunityNode>;
  readonly daysAvailable: ReadonlyArray<Scalars['String']['output']>;
  readonly description: Scalars['String']['output'];
  readonly endTime: Scalars['DateTime']['output'];
  readonly groups: CommunityGroupNodeConnection;
  readonly id: Scalars['UUID']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly live: Scalars['Boolean']['output'];
  readonly maxAdvanceReservationDays: Scalars['Int']['output'];
  readonly maxCapacity?: Maybe<Scalars['Int']['output']>;
  readonly maxHours?: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly reservable: Scalars['Boolean']['output'];
  readonly reservations: AmenityReservationNodeConnection;
  readonly startTime: Scalars['DateTime']['output'];
  readonly status: AmenityStatusEnum;
  readonly unpaginatedReservations?: Maybe<AmenityReservationNodeConnection>;
};


export type AmenityNodeGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type AmenityNodeReservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  amenity?: InputMaybe<Scalars['UUID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  user?: InputMaybe<Scalars['UUID']['input']>;
};


export type AmenityNodeUnpaginatedReservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  amenity?: InputMaybe<Scalars['UUID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  user?: InputMaybe<Scalars['UUID']['input']>;
};

export type AmenityNodeConnection = {
  readonly __typename?: 'AmenityNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AmenityNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `AmenityNode` and its cursor. */
export type AmenityNodeEdge = {
  readonly __typename?: 'AmenityNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<AmenityNode>;
};

export type AmenityReservationNode = {
  readonly __typename?: 'AmenityReservationNode';
  readonly amenity?: Maybe<AmenityNode>;
  readonly cancellationMessage?: Maybe<Scalars['String']['output']>;
  readonly end: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly isManager: Scalars['Boolean']['output'];
  readonly isMine?: Maybe<Scalars['Boolean']['output']>;
  readonly reason?: Maybe<Scalars['String']['output']>;
  readonly start: Scalars['DateTime']['output'];
  readonly user?: Maybe<UserType>;
};

export type AmenityReservationNodeConnection = {
  readonly __typename?: 'AmenityReservationNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<AmenityReservationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `AmenityReservationNode` and its cursor. */
export type AmenityReservationNodeEdge = {
  readonly __typename?: 'AmenityReservationNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<AmenityReservationNode>;
};

/** An enumeration. */
export enum AmenityStatusEnum {
  Available = 'Available',
  Bookable = 'Bookable',
  NotAvailable = 'NotAvailable'
}

export type ApplicantNode = {
  readonly __typename?: 'ApplicantNode';
  readonly applications: ApplicationNodeConnection;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly user: UserType;
};


export type ApplicantNodeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type Applicants = {
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationAttemptNode = {
  readonly __typename?: 'ApplicationAttemptNode';
  readonly application: ApplicationNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly proofOfAddress?: Maybe<Scalars['String']['output']>;
  readonly rejectedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly rejectionReason?: Maybe<Scalars['String']['output']>;
  readonly reviewer?: Maybe<ManagerType>;
};

export type ApplicationAttemptNodeConnection = {
  readonly __typename?: 'ApplicationAttemptNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ApplicationAttemptNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ApplicationAttemptNode` and its cursor. */
export type ApplicationAttemptNodeEdge = {
  readonly __typename?: 'ApplicationAttemptNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ApplicationAttemptNode>;
};

export type ApplicationHomeSearchNode = {
  readonly __typename?: 'ApplicationHomeSearchNode';
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
};

export type ApplicationHomeSearchNodeConnection = {
  readonly __typename?: 'ApplicationHomeSearchNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ApplicationHomeSearchNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ApplicationHomeSearchNode` and its cursor. */
export type ApplicationHomeSearchNodeEdge = {
  readonly __typename?: 'ApplicationHomeSearchNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ApplicationHomeSearchNode>;
};

export type ApplicationNode = {
  readonly __typename?: 'ApplicationNode';
  readonly addressLine1?: Maybe<Scalars['String']['output']>;
  readonly addressLine2?: Maybe<Scalars['String']['output']>;
  readonly applicant: ApplicantNode;
  readonly applicationAttempts: ApplicationAttemptNodeConnection;
  readonly approvedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly archivedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly community?: Maybe<CommunityNode>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly findCommunities?: Maybe<CommunityNodeConnection>;
  readonly findHomes?: Maybe<ApplicationHomeSearchNodeConnection>;
  readonly home?: Maybe<HomeType>;
  readonly id: Scalars['UUID']['output'];
  readonly isResident: Scalars['Boolean']['output'];
  readonly lastRejectionReason?: Maybe<Scalars['String']['output']>;
  readonly ownershipType?: Maybe<ResidentialManagementApplicationOwnershipTypeChoices>;
  readonly postcode: Scalars['String']['output'];
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly source: ResidentialManagementApplicationSourceChoices;
  readonly status: ResidentialManagementApplicationStatusChoices;
  readonly townOrCity?: Maybe<Scalars['String']['output']>;
};


export type ApplicationNodeApplicationAttemptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type ApplicationNodeFindCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type ApplicationNodeFindHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationNodeConnection = {
  readonly __typename?: 'ApplicationNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ApplicationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ApplicationNode` and its cursor. */
export type ApplicationNodeEdge = {
  readonly __typename?: 'ApplicationNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ApplicationNode>;
};

export type ArchiveApplicationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ArchiveApplicationPayload = {
  readonly __typename?: 'ArchiveApplicationPayload';
  readonly application?: Maybe<ApplicationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type ArchiveKeyInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ArchiveKeyPayload = {
  readonly __typename?: 'ArchiveKeyPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly key?: Maybe<KeyNode>;
};

export type ArchiveKeyholderInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ArchiveKeyholderPayload = {
  readonly __typename?: 'ArchiveKeyholderPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly keyholder?: Maybe<KeyholderNode>;
};

export type ArchiveMarketplaceItemInput = {
  readonly archivedReason?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ArchiveMarketplaceItemPayload = {
  readonly __typename?: 'ArchiveMarketplaceItemPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly marketplaceItem?: Maybe<MarketplaceItemNode>;
};

export type AssociateAgreementWithClient = {
  readonly __typename?: 'AssociateAgreementWithClient';
  readonly agreement?: Maybe<GeneratedAgreementType>;
  readonly homeId?: Maybe<Scalars['String']['output']>;
};

export type AssociateAgreementWithClientInput = {
  readonly id: Scalars['String']['input'];
};

export type AssociateSection21WithClient = {
  readonly __typename?: 'AssociateSection21WithClient';
  readonly homeId?: Maybe<Scalars['String']['output']>;
  readonly section21?: Maybe<GeneratedSection21Type>;
};

export type AssociateSection21WithClientInput = {
  readonly id: Scalars['String']['input'];
};

/** An enumeration. */
export enum AstGeneratorAgreementSignatureSignatureTypeChoices {
  /** Landlord */
  La = 'LA',
  /** Resident */
  Re = 'RE'
}

/** An enumeration. */
export enum AstGeneratorAgreementSignatureStatusChoices {
  /** Signed */
  Si = 'SI',
  /** Void */
  Vo = 'VO',
  /** Waiting */
  Wa = 'WA'
}

/** An enumeration. */
export enum AstGeneratorGeneratedAgreementFurnishedChoices {
  /** Fully furnished */
  Ff = 'FF',
  /** Partially furnished */
  Pf = 'PF',
  /** Unfurnished */
  Uf = 'UF'
}

/** An enumeration. */
export enum AstGeneratorGeneratedAgreementPaymentFrequencyChoices {
  /** Monthly */
  Mo = 'MO',
  /** Weekly */
  We = 'WE'
}

/** An enumeration. */
export enum AstGeneratorGeneratedAgreementStatusChoices {
  /** Fully signed */
  Fs = 'FS',
  /** Generated */
  Ge = 'GE',
  /** Pending */
  Pe = 'PE',
  /** Sent for signing */
  Ss = 'SS',
  /** Tenants signed */
  Ts = 'TS'
}

export type AverageConversationRatingType = {
  readonly __typename?: 'AverageConversationRatingType';
  readonly averageRating?: Maybe<Scalars['Float']['output']>;
  readonly date?: Maybe<Scalars['Date']['output']>;
};

export type BacsPaymentType = {
  readonly __typename?: 'BACSPaymentType';
  readonly last4: Scalars['String']['output'];
  readonly sortCode: Scalars['String']['output'];
};

export type BlockComplianceRequirementItemAttachmentNode = {
  readonly __typename?: 'BlockComplianceRequirementItemAttachmentNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type BlockComplianceRequirementItemAttachmentNodeConnection = {
  readonly __typename?: 'BlockComplianceRequirementItemAttachmentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<BlockComplianceRequirementItemAttachmentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `BlockComplianceRequirementItemAttachmentNode` and its cursor. */
export type BlockComplianceRequirementItemAttachmentNodeEdge = {
  readonly __typename?: 'BlockComplianceRequirementItemAttachmentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<BlockComplianceRequirementItemAttachmentNode>;
};

export type BlockComplianceRequirementItemNode = {
  readonly __typename?: 'BlockComplianceRequirementItemNode';
  readonly attachments: BlockComplianceRequirementItemAttachmentNodeConnection;
  readonly blockComplianceRequirements: BlockComplianceRequirementNodeConnection;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly expiryDate: Scalars['Date']['output'];
  readonly id: Scalars['UUID']['output'];
};


export type BlockComplianceRequirementItemNodeAttachmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type BlockComplianceRequirementItemNodeBlockComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  block?: InputMaybe<Scalars['UUID']['input']>;
  blocks?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type BlockComplianceRequirementItemNodeConnection = {
  readonly __typename?: 'BlockComplianceRequirementItemNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<BlockComplianceRequirementItemNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `BlockComplianceRequirementItemNode` and its cursor. */
export type BlockComplianceRequirementItemNodeEdge = {
  readonly __typename?: 'BlockComplianceRequirementItemNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<BlockComplianceRequirementItemNode>;
};

export type BlockComplianceRequirementNode = {
  readonly __typename?: 'BlockComplianceRequirementNode';
  readonly actionDate: Scalars['Date']['output'];
  readonly block: BlockNode;
  readonly id: Scalars['UUID']['output'];
  readonly item?: Maybe<BlockComplianceRequirementItemNode>;
  readonly items: BlockComplianceRequirementItemNodeConnection;
  readonly requirement: BlocksComplianceRequirementNode;
  readonly status: BlockComplianceRequirementStatus;
};


export type BlockComplianceRequirementNodeItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type BlockComplianceRequirementNodeConnection = {
  readonly __typename?: 'BlockComplianceRequirementNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<BlockComplianceRequirementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `BlockComplianceRequirementNode` and its cursor. */
export type BlockComplianceRequirementNodeEdge = {
  readonly __typename?: 'BlockComplianceRequirementNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<BlockComplianceRequirementNode>;
};

export enum BlockComplianceRequirementStatus {
  Checked = 'CHECKED',
  Expired = 'EXPIRED',
  Expiring = 'EXPIRING',
  Missing = 'MISSING'
}

export type BlockNode = {
  readonly __typename?: 'BlockNode';
  readonly blockComplianceRequirements: BlockComplianceRequirementNodeConnection;
  readonly community: CommunityNode;
  readonly homes: ReadonlyArray<HomeType>;
  readonly homesCount?: Maybe<Scalars['Int']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly shortCode?: Maybe<Scalars['String']['output']>;
};


export type BlockNodeBlockComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  block?: InputMaybe<Scalars['UUID']['input']>;
  blocks?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type BlockNodeConnection = {
  readonly __typename?: 'BlockNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<BlockNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `BlockNode` and its cursor. */
export type BlockNodeEdge = {
  readonly __typename?: 'BlockNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<BlockNode>;
};

export type BlocksComplianceRequirementNode = {
  readonly __typename?: 'BlocksComplianceRequirementNode';
  readonly blockComplianceRequirements: BlockComplianceRequirementNodeConnection;
  readonly blocks: BlockNodeConnection;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly shared: Scalars['Boolean']['output'];
};


export type BlocksComplianceRequirementNodeBlockComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  block?: InputMaybe<Scalars['UUID']['input']>;
  blocks?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type BlocksComplianceRequirementNodeBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type BookCommunityEventMutationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly eventId: Scalars['UUID']['input'];
};

export type BookCommunityEventMutationPayload = {
  readonly __typename?: 'BookCommunityEventMutationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly event?: Maybe<CommunityEventNode>;
};

export type BookPaidCommunityEventMutationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly eventId: Scalars['UUID']['input'];
};

export type BookPaidCommunityEventMutationPayload = {
  readonly __typename?: 'BookPaidCommunityEventMutationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly event?: Maybe<CommunityEventNode>;
  readonly payment: PaymentNode;
  readonly stripeClientSecret: Scalars['String']['output'];
};

export type CalendarLinkType = {
  readonly __typename?: 'CalendarLinkType';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly lastChecked?: Maybe<Scalars['DateTime']['output']>;
  readonly manager: ManagerType;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type CancelAmenityReservationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type CancelAmenityReservationPayload = {
  readonly __typename?: 'CancelAmenityReservationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type CancelPaymentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly payment: Scalars['UUID']['input'];
};

export type CancelPaymentPayload = {
  readonly __typename?: 'CancelPaymentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly payment: PaymentNode;
};

export type CancelScheduledPublishCommunityUpdateInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type CancelScheduledPublishCommunityUpdatePayload = {
  readonly __typename?: 'CancelScheduledPublishCommunityUpdatePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityUpdate?: Maybe<CommunityUpdateType>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type CardPaymentType = {
  readonly __typename?: 'CardPaymentType';
  readonly brand: Scalars['String']['output'];
  readonly last4: Scalars['String']['output'];
};

export type ChargeType = {
  readonly __typename?: 'ChargeType';
  readonly paymentMethodDetails: PaymentMethod;
};

export type CheckInKeyV2Input = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type CheckInKeyV2Payload = {
  readonly __typename?: 'CheckInKeyV2Payload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly key?: Maybe<KeyNode>;
};

export type CheckOutKeyV2Input = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly currentKeyholder?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type CheckOutKeyV2Payload = {
  readonly __typename?: 'CheckOutKeyV2Payload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly key?: Maybe<KeyNode>;
};

export type ClientAdminNode = {
  readonly __typename?: 'ClientAdminNode';
  readonly client?: Maybe<ClientType>;
  readonly communities?: Maybe<CommunityNodeConnection>;
  readonly id: Scalars['UUID']['output'];
  readonly user: UserType;
};


export type ClientAdminNodeCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ClientType = {
  readonly __typename?: 'ClientType';
  readonly asanaWebFormUrl?: Maybe<Scalars['String']['output']>;
  readonly btlSubscriptionPerHomeCost?: Maybe<Scalars['Float']['output']>;
  readonly btlSubscriptionTotalCost?: Maybe<Scalars['Float']['output']>;
  readonly buttonColor?: Maybe<Scalars['String']['output']>;
  readonly clientType: PeopleClientClientTypeChoices;
  readonly emergencyNumber?: Maybe<Scalars['String']['output']>;
  readonly features: ReadonlyArray<FeatureType>;
  readonly hasAddedHome?: Maybe<Scalars['Boolean']['output']>;
  readonly hasCheckedCompliance?: Maybe<Scalars['Boolean']['output']>;
  readonly hasCreatedTenancy?: Maybe<Scalars['Boolean']['output']>;
  readonly hasPayments?: Maybe<Scalars['Boolean']['output']>;
  readonly hasSentReference?: Maybe<Scalars['Boolean']['output']>;
  readonly hasSetupRentCollection?: Maybe<Scalars['Boolean']['output']>;
  readonly hasSignedAgreement?: Maybe<Scalars['Boolean']['output']>;
  readonly homes: ReadonlyArray<HomeType>;
  readonly homesCount?: Maybe<Scalars['Int']['output']>;
  readonly iconColor?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly integrations?: Maybe<IntegrationType>;
  readonly isSubscribed?: Maybe<Scalars['Boolean']['output']>;
  readonly logo?: Maybe<Scalars['String']['output']>;
  readonly managers: ReadonlyArray<ManagerType>;
  readonly name: Scalars['String']['output'];
  readonly paymentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly referenceStartedOn?: Maybe<Scalars['Date']['output']>;
  readonly stripeAccountId?: Maybe<Scalars['String']['output']>;
  readonly stripeAccountLink?: Maybe<Scalars['String']['output']>;
  readonly stripeDashboardLink?: Maybe<Scalars['String']['output']>;
  readonly subscriptions?: Maybe<ReadonlyArray<Maybe<SubscriptionNode>>>;
  readonly textColor?: Maybe<Scalars['String']['output']>;
  readonly themeColor?: Maybe<Scalars['String']['output']>;
  readonly tierUpTo?: Maybe<Scalars['Int']['output']>;
  readonly tokens: Scalars['Int']['output'];
};


export type ClientTypeBtlSubscriptionTotalCostArgs = {
  includeSetupFee?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ClientTypeStripeAccountLinkArgs = {
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ClientUserNode = {
  readonly __typename?: 'ClientUserNode';
  readonly clientAdmin?: Maybe<ClientAdminNode>;
  readonly communityManager?: Maybe<CommunityManagerNode>;
  readonly concierge?: Maybe<ConciergeNode>;
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly manager?: Maybe<ManagerType>;
  readonly mfaEnabled: Scalars['Boolean']['output'];
};

export type ClientUserNodeConnection = {
  readonly __typename?: 'ClientUserNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ClientUserNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ClientUserNode` and its cursor. */
export type ClientUserNodeEdge = {
  readonly __typename?: 'ClientUserNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ClientUserNode>;
};

export type CloseConversationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversationId: Scalars['UUID']['input'];
};

export type CloseConversationPayload = {
  readonly __typename?: 'CloseConversationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<ConversationType>;
};

export type CloseSurvey = {
  readonly __typename?: 'CloseSurvey';
  readonly survey?: Maybe<ResidentSurveyNode>;
};

export type CollectPackageInput = {
  readonly applicant?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly resident?: InputMaybe<Scalars['String']['input']>;
};

export type CollectPackagePayload = {
  readonly __typename?: 'CollectPackagePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type CollectPackagesForHomeInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly home: Scalars['UUID']['input'];
  readonly user?: InputMaybe<Scalars['UUID']['input']>;
};

export type CollectPackagesForHomePayload = {
  readonly __typename?: 'CollectPackagesForHomePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly home?: Maybe<HomeNode>;
};

export type CollectPackagesInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly packageIds: ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>;
  readonly userId: Scalars['UUID']['input'];
};

export type CollectPackagesPayload = {
  readonly __typename?: 'CollectPackagesPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly packages: ReadonlyArray<Maybe<PackageNode>>;
};

export type CommunityDashboardType = {
  readonly __typename?: 'CommunityDashboardType';
  readonly activeResidentsDifference?: Maybe<Scalars['Int']['output']>;
  readonly allTimeSatisfaction?: Maybe<Scalars['Decimal']['output']>;
  readonly averageResponseTime?: Maybe<Scalars['Int']['output']>;
  readonly downloadRate?: Maybe<Scalars['Int']['output']>;
  readonly downloadRateOverTime?: Maybe<ReadonlyArray<Maybe<DownloadRateType>>>;
  readonly listEngagementScores?: Maybe<ReadonlyArray<CommunityEngagementScoreType>>;
  readonly monthlyActiveResidents?: Maybe<Scalars['Int']['output']>;
  readonly openCount?: Maybe<Scalars['Int']['output']>;
  readonly ratingsOverTime?: Maybe<ReadonlyArray<Maybe<AverageConversationRatingType>>>;
  readonly responseTimeDifference?: Maybe<Scalars['Int']['output']>;
  readonly responseTimeOverTime?: Maybe<ReadonlyArray<Maybe<ResponseTimeType>>>;
  readonly snoozedCount?: Maybe<Scalars['Int']['output']>;
};

export type CommunityDocumentNode = {
  readonly __typename?: 'CommunityDocumentNode';
  readonly activatedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly community: CommunityNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly createdBy?: Maybe<ManagerType>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly groups: CommunityGroupNodeConnection;
  readonly id: Scalars['UUID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly link?: Maybe<Scalars['String']['output']>;
  readonly title: Scalars['String']['output'];
  readonly totalPotentialViewers: Scalars['Int']['output'];
  readonly url?: Maybe<Scalars['String']['output']>;
  readonly viewers: ResidentialManagementCommunityDocumentViewersChoices;
  readonly views: CommunityDocumentViewNodeConnection;
};


export type CommunityDocumentNodeGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CommunityDocumentNodeViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunityDocumentNodeConnection = {
  readonly __typename?: 'CommunityDocumentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityDocumentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityDocumentNode` and its cursor. */
export type CommunityDocumentNodeEdge = {
  readonly __typename?: 'CommunityDocumentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityDocumentNode>;
};

export type CommunityDocumentViewNode = {
  readonly __typename?: 'CommunityDocumentViewNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly user: UserType;
};

export type CommunityDocumentViewNodeConnection = {
  readonly __typename?: 'CommunityDocumentViewNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityDocumentViewNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityDocumentViewNode` and its cursor. */
export type CommunityDocumentViewNodeEdge = {
  readonly __typename?: 'CommunityDocumentViewNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityDocumentViewNode>;
};

export type CommunityEngagementScoreType = {
  readonly __typename?: 'CommunityEngagementScoreType';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly score: Scalars['Decimal']['output'];
};

export type CommunityEventNode = {
  readonly __typename?: 'CommunityEventNode';
  readonly attendanceFee?: Maybe<Scalars['Decimal']['output']>;
  readonly availableSpaces?: Maybe<Scalars['Int']['output']>;
  readonly community: CommunityNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly description: Scalars['String']['output'];
  readonly end: Scalars['DateTime']['output'];
  readonly groups: CommunityGroupNodeConnection;
  readonly guests?: Maybe<ContactNodeConnection>;
  readonly id: Scalars['UUID']['output'];
  readonly isAttending?: Maybe<Scalars['Boolean']['output']>;
  readonly maxSpaces: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly start: Scalars['DateTime']['output'];
};


export type CommunityEventNodeGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CommunityEventNodeGuestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowApplicants?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  downloaded?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CommunityEventNodeConnection = {
  readonly __typename?: 'CommunityEventNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityEventNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityEventNode` and its cursor. */
export type CommunityEventNodeEdge = {
  readonly __typename?: 'CommunityEventNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityEventNode>;
};

export type CommunityGroupNode = {
  readonly __typename?: 'CommunityGroupNode';
  readonly community?: Maybe<CommunityNode>;
  readonly deactivatedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly homes?: Maybe<HomeNodeConnection>;
  readonly id: Scalars['UUID']['output'];
  readonly isAutomatic: Scalars['Boolean']['output'];
  readonly isCommunityManager?: Maybe<Scalars['Boolean']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly numUsers?: Maybe<Scalars['Int']['output']>;
  readonly users?: Maybe<ContactNodeConnection>;
};


export type CommunityGroupNodeHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CommunityGroupNodeUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunityGroupNodeConnection = {
  readonly __typename?: 'CommunityGroupNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityGroupNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityGroupNode` and its cursor. */
export type CommunityGroupNodeEdge = {
  readonly __typename?: 'CommunityGroupNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityGroupNode>;
};

export type CommunityManagerNode = {
  readonly __typename?: 'CommunityManagerNode';
  readonly communities?: Maybe<CommunityNodeConnection>;
  readonly id: Scalars['UUID']['output'];
  readonly isActive?: Maybe<Scalars['Boolean']['output']>;
};


export type CommunityManagerNodeCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CommunityNode = {
  readonly __typename?: 'CommunityNode';
  readonly activatedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly appCoverImage?: Maybe<Scalars['String']['output']>;
  readonly asanaWebFormUrl?: Maybe<Scalars['String']['output']>;
  readonly avatar?: Maybe<Scalars['String']['output']>;
  readonly averageRentAmount?: Maybe<Scalars['Float']['output']>;
  readonly averageResidencyLength?: Maybe<Scalars['Float']['output']>;
  readonly blocks: BlockNodeConnection;
  readonly buttonColor?: Maybe<Scalars['String']['output']>;
  readonly client?: Maybe<ClientType>;
  readonly commentsEnabled: Scalars['Boolean']['output'];
  readonly communityChatEnabled: Scalars['Boolean']['output'];
  readonly communityEvents: CommunityEventNodeConnection;
  readonly communityGroups: CommunityGroupNodeConnection;
  readonly deactivatedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly engagementScore?: Maybe<Scalars['Decimal']['output']>;
  readonly engagementScoreDifference?: Maybe<Scalars['Float']['output']>;
  readonly externalId?: Maybe<Scalars['String']['output']>;
  readonly hasAsanaIntegration: Scalars['Boolean']['output'];
  readonly homeCount: Scalars['Int']['output'];
  readonly iconColor?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly isLeasehold: Scalars['Boolean']['output'];
  readonly logo?: Maybe<Scalars['String']['output']>;
  readonly managers: ReadonlyArray<ManagerType>;
  readonly marketplaceEnabled: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly officeHoursEnabled: Scalars['Boolean']['output'];
  readonly openConversationsCount?: Maybe<Scalars['Int']['output']>;
  readonly openPeriods?: Maybe<ReadonlyArray<CommunityOpenPeriodNode>>;
  readonly outOfOfficeMessage?: Maybe<Scalars['String']['output']>;
  readonly packageLocations: ReadonlyArray<Maybe<Scalars['String']['output']>>;
  readonly paymentsEnabled: Scalars['Boolean']['output'];
  readonly percentOccupied?: Maybe<Scalars['Float']['output']>;
  readonly postcode?: Maybe<Scalars['String']['output']>;
  readonly recentUnresolvedConversations?: Maybe<ReadonlyArray<ConversationType>>;
  readonly referencingEnabled: Scalars['Boolean']['output'];
  readonly requisiteDate?: Maybe<Scalars['Date']['output']>;
  readonly residentCount: Scalars['Int']['output'];
  readonly satisfactionScore?: Maybe<Scalars['Float']['output']>;
  readonly settings: CommunitySettingsType;
  readonly statisticDataPoint?: Maybe<CommunityStatisticDataPointNode>;
  readonly statisticDataPoints?: Maybe<ReadonlyArray<Maybe<CommunityStatisticDataPointNode>>>;
  readonly statisticDifference?: Maybe<Scalars['Float']['output']>;
  readonly status: Scalars['String']['output'];
  readonly team?: Maybe<CommunityTeamNodeConnection>;
  readonly textColor?: Maybe<Scalars['String']['output']>;
  readonly themeColor?: Maybe<Scalars['String']['output']>;
  readonly unreadConversations: Scalars['Int']['output'];
  readonly updates?: Maybe<ReadonlyArray<CommunityUpdateType>>;
};


export type CommunityNodeBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CommunityNodeCommunityEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  endDateAfter?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type CommunityNodeCommunityGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CommunityNodeStatisticDataPointArgs = {
  date?: InputMaybe<Scalars['Date']['input']>;
  statisticType: HistoricalDataPointType;
};


export type CommunityNodeStatisticDataPointsArgs = {
  frequency: HistoricalDataFrequency;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  statisticType: HistoricalDataPointType;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};


export type CommunityNodeStatisticDifferenceArgs = {
  since?: InputMaybe<Scalars['Date']['input']>;
  statisticType: HistoricalDataPointType;
};


export type CommunityNodeTeamArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunityNodeConnection = {
  readonly __typename?: 'CommunityNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityNode` and its cursor. */
export type CommunityNodeEdge = {
  readonly __typename?: 'CommunityNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityNode>;
};

export type CommunityOpenPeriodNode = {
  readonly __typename?: 'CommunityOpenPeriodNode';
  readonly end: Scalars['Time']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly start: Scalars['Time']['output'];
  readonly weekdayIndex: Scalars['Int']['output'];
};

export type CommunityOpenPeriodSerializerInput = {
  readonly end: Scalars['Time']['input'];
  readonly start: Scalars['Time']['input'];
  readonly weekdayIndex: Scalars['Int']['input'];
};

export type CommunityProductNode = {
  readonly __typename?: 'CommunityProductNode';
  readonly active: Scalars['Boolean']['output'];
  readonly client?: Maybe<ClientType>;
  readonly communities: CommunityNodeConnection;
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly price?: Maybe<Scalars['Decimal']['output']>;
  readonly purchases: CommunityProductPurchaseNodeConnection;
};


export type CommunityProductNodeCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type CommunityProductNodePurchasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type CommunityProductNodeConnection = {
  readonly __typename?: 'CommunityProductNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityProductNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityProductNode` and its cursor. */
export type CommunityProductNodeEdge = {
  readonly __typename?: 'CommunityProductNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityProductNode>;
};

export type CommunityProductPurchaseNode = {
  readonly __typename?: 'CommunityProductPurchaseNode';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly payment?: Maybe<PaymentNode>;
  readonly product?: Maybe<CommunityProductNode>;
  readonly user?: Maybe<UserType>;
};

export type CommunityProductPurchaseNodeConnection = {
  readonly __typename?: 'CommunityProductPurchaseNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityProductPurchaseNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityProductPurchaseNode` and its cursor. */
export type CommunityProductPurchaseNodeEdge = {
  readonly __typename?: 'CommunityProductPurchaseNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityProductPurchaseNode>;
};

export type CommunitySettingsType = {
  readonly __typename?: 'CommunitySettingsType';
  readonly accounts: Scalars['Boolean']['output'];
  readonly compliance: Scalars['Boolean']['output'];
  readonly conversationForwardingEmails: ReadonlyArray<ConversationForwardingEmailType>;
  readonly documents: Scalars['Boolean']['output'];
  readonly events: Scalars['Boolean']['output'];
  readonly facilities: Scalars['Boolean']['output'];
  readonly marketplace: Scalars['Boolean']['output'];
  readonly messages: Scalars['Boolean']['output'];
  readonly newsfeed: Scalars['Boolean']['output'];
  readonly occupancySurvey: Scalars['Boolean']['output'];
  readonly packageLabels: Scalars['Boolean']['output'];
  readonly products: Scalars['Boolean']['output'];
  readonly serviceChargeWaiver: Scalars['Boolean']['output'];
  readonly surveys: Scalars['Boolean']['output'];
  readonly termsAndConditions: Scalars['Boolean']['output'];
};

export type CommunityStatisticDataPointNode = {
  readonly __typename?: 'CommunityStatisticDataPointNode';
  readonly community: CommunityNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly date: Scalars['Date']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly type: ResidentialManagementCommunityStatisticDataPointTypeChoices;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly value: Scalars['Float']['output'];
};

export type CommunityTeamNode = {
  readonly __typename?: 'CommunityTeamNode';
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isAdmin?: Maybe<Scalars['Boolean']['output']>;
  readonly isCommunityManager?: Maybe<Scalars['Boolean']['output']>;
  readonly isConcierge?: Maybe<Scalars['Boolean']['output']>;
  readonly isManager?: Maybe<Scalars['Boolean']['output']>;
  readonly lastName: Scalars['String']['output'];
};

export type CommunityTeamNodeConnection = {
  readonly __typename?: 'CommunityTeamNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityTeamNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityTeamNode` and its cursor. */
export type CommunityTeamNodeEdge = {
  readonly __typename?: 'CommunityTeamNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityTeamNode>;
};

export type CommunityUpdateCommentNode = {
  readonly __typename?: 'CommunityUpdateCommentNode';
  readonly author: UserType;
  readonly comment: Scalars['String']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly userType: ResidentialManagementCommunityUpdateCommentUserTypeChoices;
};

export type CommunityUpdateCommentNodeConnection = {
  readonly __typename?: 'CommunityUpdateCommentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityUpdateCommentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityUpdateCommentNode` and its cursor. */
export type CommunityUpdateCommentNodeEdge = {
  readonly __typename?: 'CommunityUpdateCommentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityUpdateCommentNode>;
};

export type CommunityUpdateLikeType = {
  readonly __typename?: 'CommunityUpdateLikeType';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly update: CommunityUpdateType;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user: UserType;
};

export type CommunityUpdateNode = {
  readonly __typename?: 'CommunityUpdateNode';
  readonly attachment?: Maybe<Scalars['String']['output']>;
  readonly body: Scalars['String']['output'];
  readonly category: Scalars['String']['output'];
  readonly comments?: Maybe<CommunityUpdateCommentNodeConnection>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly groups?: Maybe<CommunityGroupNodeConnection>;
  readonly hasBookmarked?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly imageSearchTerm?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly isArk: Scalars['Boolean']['output'];
  readonly isLiked?: Maybe<Scalars['Boolean']['output']>;
  readonly numLikes?: Maybe<Scalars['Int']['output']>;
  readonly numberOfBookmarks?: Maybe<Scalars['Int']['output']>;
  readonly publishedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly residentLike?: Maybe<Scalars['UUID']['output']>;
  readonly scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  readonly stats: CommunityUpdateStatsType;
  readonly status: CommunityUpdateStatusEnum;
  readonly summary: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
  readonly uniqueReads?: Maybe<Scalars['Int']['output']>;
};


export type CommunityUpdateNodeCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CommunityUpdateNodeGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CommunityUpdateNodeConnection = {
  readonly __typename?: 'CommunityUpdateNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityUpdateNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityUpdateNode` and its cursor. */
export type CommunityUpdateNodeEdge = {
  readonly __typename?: 'CommunityUpdateNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityUpdateNode>;
};

export type CommunityUpdateStatsType = {
  readonly __typename?: 'CommunityUpdateStatsType';
  readonly totalComments: Scalars['Int']['output'];
  readonly totalLikes: Scalars['Int']['output'];
  readonly totalReads: Scalars['Int']['output'];
};

/** An enumeration. */
export enum CommunityUpdateStatusEnum {
  Dr = 'DR',
  Li = 'LI',
  Sc = 'SC'
}

export type CommunityUpdateType = {
  readonly __typename?: 'CommunityUpdateType';
  readonly attachment?: Maybe<Scalars['String']['output']>;
  readonly body: Scalars['String']['output'];
  readonly bookmarkedBy: ReadonlyArray<UserType>;
  readonly category: Scalars['String']['output'];
  readonly comments?: Maybe<CommunityUpdateCommentNodeConnection>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly createdBy?: Maybe<UserType>;
  readonly creatorType: ResidentialManagementCommunityUpdateCreatorTypeChoices;
  readonly groups: CommunityGroupNodeConnection;
  readonly hasBookmarked?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly imageSearchTerm?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly isArk: Scalars['Boolean']['output'];
  readonly likes: ReadonlyArray<CommunityUpdateLikeType>;
  readonly numLikes?: Maybe<Scalars['Int']['output']>;
  readonly publishedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly residentLike?: Maybe<Scalars['UUID']['output']>;
  readonly scheduledFor?: Maybe<Scalars['DateTime']['output']>;
  readonly stats: CommunityUpdateStatsType;
  readonly status: CommunityUpdateStatusEnum;
  readonly summary: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
  readonly uniqueReads?: Maybe<Scalars['Int']['output']>;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly updatedBy?: Maybe<UserType>;
};


export type CommunityUpdateTypeCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CommunityUpdateTypeGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CommunityVisitorNode = {
  readonly __typename?: 'CommunityVisitorNode';
  readonly community: CommunityNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly email: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly reasonForVisit?: Maybe<Scalars['String']['output']>;
  readonly residentName?: Maybe<Scalars['String']['output']>;
  readonly signedOutAt?: Maybe<Scalars['DateTime']['output']>;
  readonly vehicleRegistration?: Maybe<Scalars['String']['output']>;
};

export type CommunityVisitorNodeConnection = {
  readonly __typename?: 'CommunityVisitorNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<CommunityVisitorNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `CommunityVisitorNode` and its cursor. */
export type CommunityVisitorNodeEdge = {
  readonly __typename?: 'CommunityVisitorNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<CommunityVisitorNode>;
};

/** An enumeration. */
export enum ComplianceAttachmentTypeChoices {
  /** Home */
  Ho = 'HO',
  /** Requirement item */
  Ri = 'RI'
}

/** An enumeration. */
export enum ComplianceComplianceRequirementTypeChoices {
  /** Custom */
  Cu = 'CU',
  /** Deposit Certificate */
  Dc = 'DC',
  /** Electrical Installation Condition Report */
  Ei = 'EI',
  /** Energy Performance Assessment */
  Ep = 'EP',
  /** Gas Safety Inspection */
  Gs = 'GS',
  /** Local Authority Licensing */
  La = 'LA',
  /** Portable Appliance Test */
  Pa = 'PA',
  /** Smoke Alarm & Carbon Monoxide */
  Sa = 'SA',
  /** Tenancy Agreement */
  Ta = 'TA'
}

/** An enumeration. */
export enum ComplianceComplianceUpdateActionRequirementChoices {
  /** Action */
  Ac = 'AC',
  /** Advice */
  Ad = 'AD'
}

export type ComplianceRequirementNode = {
  readonly __typename?: 'ComplianceRequirementNode';
  readonly actionDate?: Maybe<Scalars['String']['output']>;
  readonly agreement?: Maybe<GeneratedAgreementNode>;
  readonly currentItem?: Maybe<RequirementItemNode>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly frequencyText?: Maybe<Scalars['String']['output']>;
  readonly home: HomeType;
  readonly id: Scalars['UUID']['output'];
  readonly items: RequirementItemNodeConnection;
  readonly name: Scalars['String']['output'];
  readonly residency?: Maybe<ResidencyType>;
  readonly shared: Scalars['Boolean']['output'];
  readonly status: Scalars['String']['output'];
  readonly type: ComplianceComplianceRequirementTypeChoices;
};


export type ComplianceRequirementNodeItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ComplianceRequirementNodeConnection = {
  readonly __typename?: 'ComplianceRequirementNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ComplianceRequirementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ComplianceRequirementNode` and its cursor. */
export type ComplianceRequirementNodeEdge = {
  readonly __typename?: 'ComplianceRequirementNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ComplianceRequirementNode>;
};

export type ComplianceUpdateReadType = {
  readonly __typename?: 'ComplianceUpdateReadType';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly manager: ManagerType;
  readonly update: ComplianceUpdateType;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type ComplianceUpdateType = {
  readonly __typename?: 'ComplianceUpdateType';
  readonly actionRequirement: ComplianceComplianceUpdateActionRequirementChoices;
  readonly actionText?: Maybe<Scalars['String']['output']>;
  readonly body: Scalars['String']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly dateText?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly infoSource?: Maybe<Scalars['String']['output']>;
  readonly reads: ReadonlyArray<ComplianceUpdateReadType>;
  readonly title: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly wasRead?: Maybe<Scalars['Boolean']['output']>;
};

export type ConciergeNode = {
  readonly __typename?: 'ConciergeNode';
  readonly client?: Maybe<ClientType>;
  readonly communities?: Maybe<CommunityNodeConnection>;
  readonly id: Scalars['UUID']['output'];
  readonly isActive?: Maybe<Scalars['Boolean']['output']>;
};


export type ConciergeNodeCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmResidencyWillRollInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmResidencyWillRollPayload = {
  readonly __typename?: 'ConfirmResidencyWillRollPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type ContactNode = {
  readonly __typename?: 'ContactNode';
  readonly applicant?: Maybe<ApplicantNode>;
  readonly communityGroups: CommunityGroupNodeConnection;
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastAppUse?: Maybe<Scalars['DateTime']['output']>;
  readonly lastName: Scalars['String']['output'];
  readonly leaseholder?: Maybe<LeaseholderNode>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly resident?: Maybe<ResidentNode>;
};


export type ContactNodeCommunityGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ContactNodeConnection = {
  readonly __typename?: 'ContactNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ContactNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ContactNode` and its cursor. */
export type ContactNodeEdge = {
  readonly __typename?: 'ContactNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ContactNode>;
};

export type ConversationActionModelType = {
  readonly __typename?: 'ConversationActionModelType';
  readonly conversation: ConversationType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly extra?: Maybe<Scalars['JSONString']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type ConversationEventType = ConversationActionModelType | ConversationMediaType | ConversationMessageType;

export type ConversationForwardingEmailType = {
  readonly __typename?: 'ConversationForwardingEmailType';
  readonly email: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type ConversationMediaType = {
  readonly __typename?: 'ConversationMediaType';
  readonly conversation: ConversationType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly file: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly sender: ResidentialManagementConversationMediaSenderChoices;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user: UserType;
};

export type ConversationMessageType = {
  readonly __typename?: 'ConversationMessageType';
  readonly body: Scalars['String']['output'];
  readonly conversation: ConversationType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly seen?: Maybe<Scalars['Boolean']['output']>;
  readonly sender: ResidentialManagementConversationMessageSenderChoices;
  readonly suggestion?: Maybe<MessageSuggestionType>;
  readonly suggestions: ReadonlyArray<MessageSuggestionType>;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user?: Maybe<UserType>;
};

export type ConversationNode = {
  readonly __typename?: 'ConversationNode';
  readonly actions: ReadonlyArray<ConversationActionModelType>;
  readonly asanaTaskId?: Maybe<Scalars['String']['output']>;
  readonly category: ResidentialManagementConversationCategoryChoices;
  readonly closedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly currentStatus?: Maybe<Scalars['String']['output']>;
  readonly currentUrgency?: Maybe<Scalars['String']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  readonly home?: Maybe<HomeType>;
  readonly id: Scalars['UUID']['output'];
  readonly latestEvent?: Maybe<ConversationEventType>;
  readonly latestMedia?: Maybe<ConversationMediaType>;
  readonly latestMessage?: Maybe<ConversationMessageType>;
  readonly latestOpen?: Maybe<ConversationOpenedType>;
  readonly latestStatus?: Maybe<ConversationStatusType>;
  readonly latestUpdatedDate?: Maybe<Scalars['DateTime']['output']>;
  readonly media: ReadonlyArray<ConversationMediaType>;
  readonly messages: ReadonlyArray<ConversationMessageType>;
  readonly note: Scalars['String']['output'];
  readonly notifications?: Maybe<ReadonlyArray<Maybe<ManagerNotificationType>>>;
  readonly openedCount?: Maybe<Scalars['Int']['output']>;
  readonly opens: ReadonlyArray<ConversationOpenedType>;
  readonly rating?: Maybe<ConversationRatingType>;
  readonly residency?: Maybe<ResidencyNode>;
  readonly resident?: Maybe<ResidentNode>;
  readonly responseTime?: Maybe<Scalars['Int']['output']>;
  readonly snoozedUntil?: Maybe<Scalars['DateTime']['output']>;
  readonly statuses: ReadonlyArray<ConversationStatusType>;
  readonly subject: Scalars['String']['output'];
  readonly tagSuggestions: ConversationTagSuggestionNodeConnection;
  readonly tags?: Maybe<ReadonlyArray<Maybe<ConversationTagNode>>>;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly urgencies: ReadonlyArray<ConversationUrgencyType>;
  readonly user?: Maybe<UserType>;
};


export type ConversationNodeTagSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ConversationNodeConnection = {
  readonly __typename?: 'ConversationNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ConversationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ConversationNode` and its cursor. */
export type ConversationNodeEdge = {
  readonly __typename?: 'ConversationNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ConversationNode>;
};

export type ConversationOpenedType = {
  readonly __typename?: 'ConversationOpenedType';
  readonly conversation: ConversationType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type ConversationRatingType = {
  readonly __typename?: 'ConversationRatingType';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly conversation: ConversationType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly rating: Scalars['Int']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
};

/** An enumeration. */
export enum ConversationRole {
  Concierge = 'Concierge',
  Leaseholder = 'Leaseholder',
  Manager = 'Manager',
  Resident = 'Resident'
}

export type ConversationStatusType = {
  readonly __typename?: 'ConversationStatusType';
  readonly conversation: ConversationType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type ConversationTagNode = {
  readonly __typename?: 'ConversationTagNode';
  readonly client: ClientType;
  readonly conversations: ConversationTypeConnection;
  readonly deletedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly isSelected?: Maybe<Scalars['Boolean']['output']>;
  readonly name: Scalars['String']['output'];
  readonly suggested?: Maybe<Scalars['Boolean']['output']>;
};


export type ConversationTagNodeConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['UUID']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  excludeId?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasResponseTime?: InputMaybe<Scalars['Boolean']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  isUrgent?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  residency?: InputMaybe<Scalars['UUID']['input']>;
  resident?: InputMaybe<Scalars['UUID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  subject_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type ConversationTagNodeConnection = {
  readonly __typename?: 'ConversationTagNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ConversationTagNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ConversationTagNode` and its cursor. */
export type ConversationTagNodeEdge = {
  readonly __typename?: 'ConversationTagNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ConversationTagNode>;
};

export type ConversationTagSuggestionNode = {
  readonly __typename?: 'ConversationTagSuggestionNode';
  readonly id: Scalars['UUID']['output'];
  readonly tag: ConversationTagNode;
};

export type ConversationTagSuggestionNodeConnection = {
  readonly __typename?: 'ConversationTagSuggestionNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ConversationTagSuggestionNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
};

/** A Relay edge containing a `ConversationTagSuggestionNode` and its cursor. */
export type ConversationTagSuggestionNodeEdge = {
  readonly __typename?: 'ConversationTagSuggestionNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ConversationTagSuggestionNode>;
};

export type ConversationType = {
  readonly __typename?: 'ConversationType';
  readonly actions: ReadonlyArray<ConversationActionModelType>;
  readonly asanaTaskId?: Maybe<Scalars['String']['output']>;
  readonly category: ResidentialManagementConversationCategoryChoices;
  readonly closedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly currentStatus?: Maybe<Scalars['String']['output']>;
  readonly currentUrgency?: Maybe<Scalars['String']['output']>;
  readonly hasUnread: Scalars['Boolean']['output'];
  readonly home?: Maybe<HomeType>;
  readonly id: Scalars['UUID']['output'];
  readonly latestEvent?: Maybe<ConversationEventType>;
  readonly latestMedia?: Maybe<ConversationMediaType>;
  readonly latestMessage?: Maybe<ConversationMessageType>;
  readonly latestOpen?: Maybe<ConversationOpenedType>;
  readonly latestStatus?: Maybe<ConversationStatusType>;
  readonly latestUpdatedDate?: Maybe<Scalars['DateTime']['output']>;
  readonly media: ReadonlyArray<ConversationMediaType>;
  readonly messages: ReadonlyArray<ConversationMessageType>;
  readonly note: Scalars['String']['output'];
  readonly notifications?: Maybe<ReadonlyArray<Maybe<ManagerNotificationType>>>;
  readonly openedCount?: Maybe<Scalars['Int']['output']>;
  readonly opens: ReadonlyArray<ConversationOpenedType>;
  readonly rating?: Maybe<ConversationRatingType>;
  readonly residency?: Maybe<ResidencyNode>;
  readonly resident?: Maybe<ResidentNode>;
  readonly responseTime?: Maybe<Scalars['Int']['output']>;
  readonly snoozedUntil?: Maybe<Scalars['DateTime']['output']>;
  readonly statuses: ReadonlyArray<ConversationStatusType>;
  readonly subject: Scalars['String']['output'];
  readonly tagSuggestions: ConversationTagSuggestionNodeConnection;
  readonly tags?: Maybe<ReadonlyArray<Maybe<ConversationTagNode>>>;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly urgencies: ReadonlyArray<ConversationUrgencyType>;
  readonly user?: Maybe<UserType>;
};


export type ConversationTypeTagSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ConversationTypeConnection = {
  readonly __typename?: 'ConversationTypeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ConversationTypeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ConversationType` and its cursor. */
export type ConversationTypeEdge = {
  readonly __typename?: 'ConversationTypeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ConversationType>;
};

export type ConversationUrgencyType = {
  readonly __typename?: 'ConversationUrgencyType';
  readonly conversation: ConversationType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly urgency?: Maybe<Scalars['String']['output']>;
};

export type CountersignAgreementInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type CountersignAgreementPayload = {
  readonly __typename?: 'CountersignAgreementPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type CreateAgreementForTenancyInput = {
  readonly additionalTerms?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly furnished?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly landlordAddress?: InputMaybe<Scalars['String']['input']>;
  readonly residency?: InputMaybe<Scalars['String']['input']>;
  readonly utilitiesIncluded?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAgreementForTenancyPayload = {
  readonly __typename?: 'CreateAgreementForTenancyPayload';
  readonly additionalTerms?: Maybe<Scalars['String']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly furnished?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly landlordAddress?: Maybe<Scalars['String']['output']>;
  readonly residency?: Maybe<Scalars['String']['output']>;
  readonly utilitiesIncluded?: Maybe<Scalars['String']['output']>;
};

export type CreateAmenityReservationMutationInput = {
  readonly amenityId: Scalars['UUID']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly timeSlots: ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>;
};

export type CreateAmenityReservationMutationPayload = {
  readonly __typename?: 'CreateAmenityReservationMutationPayload';
  readonly amenity?: Maybe<AmenityNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateApplicantAndApplicationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['UUID']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly home: Scalars['UUID']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly ownershipType: Scalars['String']['input'];
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateApplicantAndApplicationPayload = {
  readonly __typename?: 'CreateApplicantAndApplicationPayload';
  readonly applicant?: Maybe<ApplicantNode>;
  readonly application?: Maybe<ApplicationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateApplicationInput = {
  readonly addressLine1?: InputMaybe<Scalars['String']['input']>;
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly isResident?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lastName: Scalars['String']['input'];
  readonly moveInDate?: InputMaybe<Scalars['Date']['input']>;
  readonly ownershipType?: InputMaybe<Scalars['String']['input']>;
  readonly postcode: Scalars['String']['input'];
  readonly region?: InputMaybe<Scalars['String']['input']>;
  readonly townOrCity?: InputMaybe<Scalars['String']['input']>;
};

export type CreateApplicationPayload = {
  readonly __typename?: 'CreateApplicationPayload';
  readonly addressLine1?: Maybe<Scalars['String']['output']>;
  readonly addressLine2?: Maybe<Scalars['String']['output']>;
  readonly application?: Maybe<ApplicationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly community?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly isResident?: Maybe<Scalars['Boolean']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly moveInDate?: Maybe<Scalars['Date']['output']>;
  readonly ownershipType?: Maybe<Scalars['String']['output']>;
  readonly postcode?: Maybe<Scalars['String']['output']>;
  readonly region?: Maybe<Scalars['String']['output']>;
  readonly townOrCity?: Maybe<Scalars['String']['output']>;
};

export type CreateAttachmentOnHomeInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly homeId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAttachmentOnHomePayload = {
  readonly __typename?: 'CreateAttachmentOnHomePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly homeId?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type CreateAttachmentOnRequirementItemInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly requirementItemId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAttachmentOnRequirementItemPayload = {
  readonly __typename?: 'CreateAttachmentOnRequirementItemPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly requirementItemId?: Maybe<Scalars['String']['output']>;
};

export type CreateBlockComplianceRequirementItemInput = {
  readonly blockComplianceRequirements: ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly expiryDate: Scalars['Date']['input'];
};

export type CreateBlockComplianceRequirementItemPayload = {
  readonly __typename?: 'CreateBlockComplianceRequirementItemPayload';
  readonly blockComplianceRequirementItem?: Maybe<BlockComplianceRequirementItemNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateBlocks = {
  readonly __typename?: 'CreateBlocks';
  readonly blocks?: Maybe<ReadonlyArray<Maybe<BlockNode>>>;
};

export type CreateBlocksComplianceRequirementInput = {
  readonly blocks: ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly shared: Scalars['Boolean']['input'];
};

export type CreateBlocksComplianceRequirementPayload = {
  readonly __typename?: 'CreateBlocksComplianceRequirementPayload';
  readonly blocksComplianceRequirement?: Maybe<BlocksComplianceRequirementNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateCheckoutLinkInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly subscription: Scalars['UUID']['input'];
};

export type CreateCheckoutLinkPayload = {
  readonly __typename?: 'CreateCheckoutLinkPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly url: Scalars['String']['output'];
};

export type CreateCommunityDocumentInput = {
  readonly activatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['String']['input'];
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly file?: InputMaybe<Scalars['String']['input']>;
  readonly groups: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly title: Scalars['String']['input'];
  readonly viewers?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCommunityDocumentPayload = {
  readonly __typename?: 'CreateCommunityDocumentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityDocument?: Maybe<CommunityDocumentNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type CreateCommunityDocumentViewInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly document: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCommunityDocumentViewPayload = {
  readonly __typename?: 'CreateCommunityDocumentViewPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityDocumentView?: Maybe<CommunityDocumentViewNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type CreateCommunityProductInput = {
  readonly active?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly communities: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly description: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly price?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CreateCommunityProductPayload = {
  readonly __typename?: 'CreateCommunityProductPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityProduct?: Maybe<CommunityProductNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type CreateCommunityProductPaymentInput = {
  readonly amount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly communityProductId: Scalars['UUID']['input'];
};

export type CreateCommunityProductPaymentPayload = {
  readonly __typename?: 'CreateCommunityProductPaymentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly payment: PaymentNode;
  readonly stripeClientSecret: Scalars['String']['output'];
};

export type CreateCommunityUpdateReadInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly communityUpdate: Scalars['String']['input'];
};

export type CreateCommunityUpdateReadPayload = {
  readonly __typename?: 'CreateCommunityUpdateReadPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityUpdate?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type CreateCommunityVisitorInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly lastName: Scalars['String']['input'];
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
  readonly reasonForVisit?: InputMaybe<Scalars['String']['input']>;
  readonly residentName?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleRegistration?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCommunityVisitorPayload = {
  readonly __typename?: 'CreateCommunityVisitorPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityVisitor?: Maybe<CommunityVisitorNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type CreateComplianceRequirementInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly frequencyText?: InputMaybe<Scalars['String']['input']>;
  readonly homes: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly name: Scalars['String']['input'];
  readonly renewalFrequency?: InputMaybe<Scalars['String']['input']>;
};

export type CreateComplianceRequirementPayload = {
  readonly __typename?: 'CreateComplianceRequirementPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly complianceRequirements?: Maybe<ReadonlyArray<Maybe<ComplianceRequirementNode>>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly frequencyText?: Maybe<Scalars['String']['output']>;
  readonly homes?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly renewalFrequency?: Maybe<Scalars['String']['output']>;
};

export type CreateComplianceUpdateReadInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly update: Scalars['String']['input'];
};

export type CreateComplianceUpdateReadPayload = {
  readonly __typename?: 'CreateComplianceUpdateReadPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly update?: Maybe<Scalars['String']['output']>;
};

export type CreateConversation = {
  readonly __typename?: 'CreateConversation';
  readonly conversation?: Maybe<ConversationType>;
};

export type CreateConversationActionInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversation: Scalars['String']['input'];
  readonly extra?: InputMaybe<Scalars['JSONString']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly type: Scalars['String']['input'];
};

export type CreateConversationActionPayload = {
  readonly __typename?: 'CreateConversationActionPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<Scalars['String']['output']>;
  readonly createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly extra?: Maybe<Scalars['JSONString']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly type?: Maybe<Scalars['String']['output']>;
};

export type CreateConversationAndFirstMessage = {
  readonly __typename?: 'CreateConversationAndFirstMessage';
  readonly conversation?: Maybe<ConversationType>;
};

export type CreateConversationAndFirstMessageInput = {
  readonly body?: InputMaybe<Scalars['String']['input']>;
  readonly homeId: Scalars['UUID']['input'];
};

export type CreateConversationInput = {
  readonly category: Scalars['String']['input'];
  readonly comments?: InputMaybe<Scalars['String']['input']>;
  readonly contactId?: InputMaybe<Scalars['UUID']['input']>;
  readonly homeId?: InputMaybe<Scalars['UUID']['input']>;
  readonly residencyId?: InputMaybe<Scalars['UUID']['input']>;
  readonly residentId?: InputMaybe<Scalars['UUID']['input']>;
  readonly subject?: InputMaybe<Scalars['String']['input']>;
};

export type CreateConversationMessage = {
  readonly __typename?: 'CreateConversationMessage';
  readonly message?: Maybe<ConversationMessageType>;
};

export type CreateConversationMessageInput = {
  readonly body: Scalars['String']['input'];
  readonly conversationId: Scalars['UUID']['input'];
  readonly suggestionId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateConversationTagInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateConversationTagPayload = {
  readonly __typename?: 'CreateConversationTagPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type CreateGroupChatMessageInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly groupChat: Scalars['String']['input'];
  readonly text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupChatMessagePayload = {
  readonly __typename?: 'CreateGroupChatMessagePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly groupChatMessage?: Maybe<GroupChatMessageNode>;
};

export type CreateGroupInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly homes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly users?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateGroupPayload = {
  readonly __typename?: 'CreateGroupPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly community?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly homes?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly users?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
};

export type CreateKeyV2Input = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['String']['input'];
  readonly home?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly reference?: InputMaybe<Scalars['String']['input']>;
};

export type CreateKeyV2Payload = {
  readonly __typename?: 'CreateKeyV2Payload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly key?: Maybe<KeyNode>;
};

export type CreateKeyholderV2Input = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly company?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly key: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateKeyholderV2Payload = {
  readonly __typename?: 'CreateKeyholderV2Payload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly keyholder?: Maybe<KeyholderNode>;
};

export type CreateManagerConversationReadInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversation: Scalars['String']['input'];
  readonly unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateManagerConversationReadPayload = {
  readonly __typename?: 'CreateManagerConversationReadPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly unread?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateMarketplaceItemCommentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly comment: Scalars['String']['input'];
  readonly item: Scalars['String']['input'];
};

export type CreateMarketplaceItemCommentPayload = {
  readonly __typename?: 'CreateMarketplaceItemCommentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly item?: Maybe<Scalars['String']['output']>;
};

export type CreateNewDevice = {
  readonly __typename?: 'CreateNewDevice';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateNewDeviceInput = {
  readonly platform?: InputMaybe<Scalars['String']['input']>;
  readonly token: Scalars['String']['input'];
  readonly type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOccupancySurvey = {
  readonly __typename?: 'CreateOccupancySurvey';
  readonly occupancySurvey?: Maybe<OccupancySurveyNode>;
};

export type CreateOpenedConversation = {
  readonly __typename?: 'CreateOpenedConversation';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateOpenedConversationInput = {
  readonly conversationId: Scalars['UUID']['input'];
};

export type CreatePaymentInput = {
  readonly amount: Scalars['Decimal']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly paymentAccount?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreatePaymentPayload = {
  readonly __typename?: 'CreatePaymentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly payment: PaymentNode;
  readonly stripeClientSecret: Scalars['String']['output'];
};

export type CreateResidencyAndResidents = {
  readonly __typename?: 'CreateResidencyAndResidents';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
  readonly residency?: Maybe<ResidencyNode>;
};

export type CreateResidencyDocumentInput = {
  readonly activatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly file?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly residency: Scalars['String']['input'];
};

export type CreateResidencyDocumentPayload = {
  readonly __typename?: 'CreateResidencyDocumentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly residencyDocument?: Maybe<ResidencyDocumentNode>;
};

export type CreateResidencyDocumentViewInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly document: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateResidencyDocumentViewPayload = {
  readonly __typename?: 'CreateResidencyDocumentViewPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly residencyDocumentView?: Maybe<ResidencyDocumentViewNode>;
};

export type CreateResidencyDocuments = {
  readonly __typename?: 'CreateResidencyDocuments';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateResidencyDocumentsInput = {
  readonly complianceItems?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly customComplianceItems?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly residencyId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateResidencySubscriptionInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly depositAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly methods?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly paymentStartDate?: InputMaybe<Scalars['Date']['input']>;
  readonly residencyId: Scalars['String']['input'];
};

export type CreateResidencySubscriptionPayload = {
  readonly __typename?: 'CreateResidencySubscriptionPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly depositAmount?: Maybe<Scalars['Decimal']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly methods?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly paymentStartDate?: Maybe<Scalars['Date']['output']>;
  readonly residencyId?: Maybe<Scalars['String']['output']>;
};

export type CreateStripeAccount = {
  readonly __typename?: 'CreateStripeAccount';
  readonly client?: Maybe<ClientType>;
};

export type CreateSurveyInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly groups: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly options?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly question: Scalars['String']['input'];
};

export type CreateSurveyPayload = {
  readonly __typename?: 'CreateSurveyPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly groups?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly link?: Maybe<Scalars['String']['output']>;
  readonly options?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly question?: Maybe<Scalars['String']['output']>;
};

export type CreateTenancyApplicationInput = {
  readonly applicants?: InputMaybe<ReadonlyArray<InputMaybe<Applicants>>>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly homeId?: InputMaybe<Scalars['UUID']['input']>;
  readonly rentAmount?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CreateTenancyApplicationPayload = {
  readonly __typename?: 'CreateTenancyApplicationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly tenancyApplication?: Maybe<TenancyApplicationNode>;
};

export type CreateUpdateCommentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly comment: Scalars['String']['input'];
  readonly updateId: Scalars['UUID']['input'];
  readonly userType: Scalars['String']['input'];
};

export type CreateUpdateCommentPayload = {
  readonly __typename?: 'CreateUpdateCommentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityUpdateComment?: Maybe<CommunityUpdateCommentNode>;
};

export type CreditCheckNode = {
  readonly __typename?: 'CreditCheckNode';
  readonly activeBankruptcies: Scalars['Int']['output'];
  readonly activeCountyCourtJudgements: Scalars['Int']['output'];
  readonly aliases: ReadonlyArray<Scalars['String']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly riskLevel?: Maybe<Scalars['String']['output']>;
  readonly score: Scalars['Int']['output'];
  readonly totalBankruptcies: Scalars['Int']['output'];
  readonly totalCountyCourtJudgements: Scalars['Int']['output'];
};

export type DeactivateClientUserInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
};

export type DeactivateClientUserPayload = {
  readonly __typename?: 'DeactivateClientUserPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly clientUser?: Maybe<ClientUserNode>;
};

export type DeactivateGroupInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeactivateGroupPayload = {
  readonly __typename?: 'DeactivateGroupPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityGroup?: Maybe<CommunityGroupNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type DebugSchema = {
  readonly __typename?: 'DebugSchema';
  readonly _debug?: Maybe<DjangoDebug>;
  readonly accountTransactions?: Maybe<AccountTransactionNodeConnection>;
  readonly agreement?: Maybe<GeneratedAgreementNode>;
  readonly agreements?: Maybe<GeneratedAgreementNodeConnection>;
  readonly amenities?: Maybe<AmenityNodeConnection>;
  readonly amenity?: Maybe<AmenityNode>;
  readonly amenityReservation?: Maybe<AmenityReservationNode>;
  readonly amenityReservations?: Maybe<AmenityReservationNodeConnection>;
  readonly application?: Maybe<ApplicationNode>;
  readonly applications?: Maybe<ApplicationNodeConnection>;
  readonly block?: Maybe<BlockNode>;
  readonly blockComplianceRequirement?: Maybe<BlockComplianceRequirementNode>;
  readonly blockComplianceRequirements?: Maybe<BlockComplianceRequirementNodeConnection>;
  readonly blocks?: Maybe<BlockNodeConnection>;
  readonly clientUser?: Maybe<ClientUserNode>;
  readonly clientUsers?: Maybe<ClientUserNodeConnection>;
  readonly communities?: Maybe<CommunityNodeConnection>;
  readonly community?: Maybe<CommunityNode>;
  readonly communityDocument?: Maybe<CommunityDocumentNode>;
  readonly communityDocuments?: Maybe<CommunityDocumentNodeConnection>;
  readonly communityEvent?: Maybe<CommunityEventNode>;
  readonly communityEvents?: Maybe<CommunityEventNodeConnection>;
  readonly communityGroup?: Maybe<CommunityGroupNode>;
  readonly communityGroups?: Maybe<CommunityGroupNodeConnection>;
  readonly communityProduct?: Maybe<CommunityProductNode>;
  readonly communityProductPurchases?: Maybe<CommunityProductPurchaseNodeConnection>;
  readonly communityProducts?: Maybe<CommunityProductNodeConnection>;
  readonly communityUpdate?: Maybe<CommunityUpdateNode>;
  readonly communityUpdates?: Maybe<CommunityUpdateNodeConnection>;
  readonly communityVisitor?: Maybe<CommunityVisitorNode>;
  readonly communityVisitors?: Maybe<CommunityVisitorNodeConnection>;
  readonly complianceRequirement?: Maybe<ComplianceRequirementNode>;
  readonly complianceRequirements?: Maybe<ComplianceRequirementNodeConnection>;
  readonly contact?: Maybe<ContactNode>;
  readonly contacts?: Maybe<ContactNodeConnection>;
  readonly conversation?: Maybe<ConversationNode>;
  readonly conversationTag?: Maybe<ConversationTagNode>;
  readonly conversationTags?: Maybe<ConversationTagNodeConnection>;
  readonly conversations?: Maybe<ConversationNodeConnection>;
  readonly dashboard?: Maybe<CommunityDashboardType>;
  readonly document?: Maybe<DocumentNode>;
  readonly documents?: Maybe<DocumentNodeConnection>;
  readonly employerReference?: Maybe<EmployerReferenceNode>;
  readonly findComplianceUpdate?: Maybe<ComplianceUpdateType>;
  readonly findHome?: Maybe<HomeType>;
  readonly findSection21?: Maybe<GeneratedSection21Type>;
  readonly findTenancyAgreement?: Maybe<GeneratedAgreementType>;
  readonly getClient?: Maybe<ClientType>;
  readonly getClientAdmin?: Maybe<ClientAdminNode>;
  readonly getLandlord?: Maybe<LandlordNode>;
  readonly getLeaseholder?: Maybe<LeaseholderNode>;
  readonly getManager?: Maybe<ManagerType>;
  readonly getMe?: Maybe<ResidentType>;
  readonly getUser?: Maybe<UserType>;
  readonly groupChat?: Maybe<GroupChatNode>;
  readonly groupChatMessage?: Maybe<GroupChatMessageNode>;
  readonly groupChatMessages?: Maybe<GroupChatMessageNodeConnection>;
  readonly groupChats?: Maybe<GroupChatNodeConnection>;
  readonly home?: Maybe<HomeNode>;
  readonly homes?: Maybe<HomeNodeConnection>;
  readonly invoice?: Maybe<InvoiceNode>;
  readonly invoices?: Maybe<InvoiceNodeConnection>;
  readonly key?: Maybe<KeyNode>;
  readonly keyholder?: Maybe<KeyholderNode>;
  readonly keyholders?: Maybe<KeyholderNodeConnection>;
  readonly keys?: Maybe<KeyNodeConnection>;
  readonly landlordAttachment?: Maybe<LandlordAttachmentNode>;
  readonly landlordDocuments?: Maybe<ReadonlyArray<Maybe<LandlordDocumentNode>>>;
  readonly landlordReference?: Maybe<LandlordReferenceNode>;
  readonly leasehold?: Maybe<LeaseholdNode>;
  readonly leaseholds?: Maybe<LeaseholdNodeConnection>;
  readonly listComplianceUpdates?: Maybe<ReadonlyArray<ComplianceUpdateType>>;
  readonly listHomes?: Maybe<ReadonlyArray<HomeType>>;
  readonly listResidencies?: Maybe<ReadonlyArray<ResidencyType>>;
  readonly marketplaceItem?: Maybe<MarketplaceItemNode>;
  readonly marketplaceItems?: Maybe<MarketplaceItemNodeConnection>;
  readonly notification?: Maybe<NotificationNode>;
  readonly notifications?: Maybe<NotificationNodeConnection>;
  readonly occupancySurvey?: Maybe<OccupancySurveyNode>;
  readonly occupancySurveys?: Maybe<OccupancySurveyNodeConnection>;
  readonly package?: Maybe<PackageNode>;
  readonly packages?: Maybe<PackageNodeConnection>;
  readonly paymentAccount?: Maybe<PaymentAccountNode>;
  readonly paymentAccounts?: Maybe<PaymentAccountNodeConnection>;
  readonly productCosts?: Maybe<ProductCosts>;
  readonly profile?: Maybe<ProfileType>;
  readonly residencies?: Maybe<ResidencyNodeConnection>;
  readonly residency?: Maybe<ResidencyNode>;
  readonly resident?: Maybe<ResidentNode>;
  readonly residents?: Maybe<ResidentNodeConnection>;
  readonly searchEpc?: Maybe<ReadonlyArray<EpcResponseType>>;
  readonly section21?: Maybe<GeneratedSection21Node>;
  readonly signature?: Maybe<AgreementSignatureNode>;
  readonly survey?: Maybe<ResidentSurveyNode>;
  readonly surveys?: Maybe<ResidentSurveyNodeConnection>;
  readonly team?: Maybe<CommunityTeamNodeConnection>;
  readonly tenancyApplicant?: Maybe<TenancyApplicantNode>;
  readonly tenancyApplication?: Maybe<TenancyApplicationNode>;
  readonly tenancyApplications?: Maybe<TenancyApplicationNodeConnection>;
  readonly tenantReference?: Maybe<TenantReferenceNode>;
  readonly tenantReferences?: Maybe<TenantReferenceNodeConnection>;
};


export type DebugSchemaAccountTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaAgreementArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaAgreementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DebugSchemaAmenitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaAmenityArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaAmenityReservationArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaAmenityReservationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  amenity?: InputMaybe<Scalars['UUID']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
  user?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaApplicationArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaBlockArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaBlockComplianceRequirementArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaBlockComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  block?: InputMaybe<Scalars['UUID']['input']>;
  blocks?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaClientUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaClientUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaCommunityArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityDocumentArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaCommunityEventArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  endDateAfter?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityGroupArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaCommunityProductArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityProductPurchasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaCommunityProductsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaCommunityUpdateArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityUpdatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groups?: InputMaybe<Scalars['UUID']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  userBookmarked?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityVisitorArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaCommunityVisitorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onSite?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  signedOut?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DebugSchemaComplianceRequirementArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaContactArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  allowApplicants?: InputMaybe<Scalars['Boolean']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  downloaded?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaConversationArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaConversationTagArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaConversationTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DebugSchemaConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['UUID']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  excludeId?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasResponseTime?: InputMaybe<Scalars['Boolean']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  isUrgent?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  residency?: InputMaybe<Scalars['UUID']['input']>;
  resident?: InputMaybe<Scalars['UUID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sender?: InputMaybe<ConversationRole>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  subject_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaDashboardArgs = {
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>>;
};


export type DebugSchemaDocumentArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaEmployerReferenceArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaFindComplianceUpdateArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaFindHomeArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaFindSection21Args = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaFindTenancyAgreementArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaGroupChatArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaGroupChatMessageArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaGroupChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  groupChat?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DebugSchemaGroupChatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DebugSchemaHomeArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaHomesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  icontainsSearch?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  packagesStatus?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  trigramSearch?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaInvoiceArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  isCredit?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaKeyArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaKeyholderArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaKeyholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasCurrentKeyholder?: InputMaybe<Scalars['Boolean']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaLandlordAttachmentArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaLandlordDocumentsArgs = {
  homeId?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaLandlordReferenceArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaLeaseholdArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaLeaseholdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DebugSchemaListComplianceUpdatesArgs = {
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DebugSchemaListHomesArgs = {
  communityId?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaListResidenciesArgs = {
  communityId?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaMarketplaceItemArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaMarketplaceItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isClosed?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaNotificationArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DebugSchemaOccupancySurveyArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaOccupancySurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isOccupant?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaPackageArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaPackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaPaymentAccountArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaPaymentAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communityActive?: InputMaybe<Scalars['Boolean']['input']>;
  communityPaymentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type DebugSchemaResidenciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  referenceSent?: InputMaybe<Scalars['Boolean']['input']>;
  stage?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type DebugSchemaResidencyArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaResidentArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaResidentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  downloaded?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  notDownloaded?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  scanData?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  withRating?: InputMaybe<Scalars['Boolean']['input']>;
};


export type DebugSchemaSearchEpcArgs = {
  homeId?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaSection21Args = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaSignatureArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaSurveyArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaSurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasLink?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  statusBy?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaTeamArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type DebugSchemaTenancyApplicantArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaTenancyApplicationArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaTenancyApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasBeenSent?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type DebugSchemaTenantReferenceArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};


export type DebugSchemaTenantReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasBeenSent?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DeleteAmenityInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAmenityPayload = {
  readonly __typename?: 'DeleteAmenityPayload';
  readonly amenity?: Maybe<AmenityNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type DeleteApplicant = {
  readonly __typename?: 'DeleteApplicant';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteAttachmentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAttachmentPayload = {
  readonly __typename?: 'DeleteAttachmentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type DeleteCommunityDocumentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteCommunityDocumentPayload = {
  readonly __typename?: 'DeleteCommunityDocumentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityDocument?: Maybe<CommunityDocumentNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type DeleteCommunityProductInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteCommunityProductPayload = {
  readonly __typename?: 'DeleteCommunityProductPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityProduct?: Maybe<CommunityProductNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type DeleteCommunityUpdateCommentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteCommunityUpdateCommentPayload = {
  readonly __typename?: 'DeleteCommunityUpdateCommentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type DeleteCommunityUpdateInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
};

export type DeleteCommunityUpdatePayload = {
  readonly __typename?: 'DeleteCommunityUpdatePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type DeleteComplianceRequirementV2Input = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteComplianceRequirementV2Payload = {
  readonly __typename?: 'DeleteComplianceRequirementV2Payload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteConversationTagInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['UUID']['input'];
};

export type DeleteConversationTagPayload = {
  readonly __typename?: 'DeleteConversationTagPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversationTag?: Maybe<ConversationTagNode>;
};

export type DeleteHomeImageInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
};

export type DeleteHomeImagePayload = {
  readonly __typename?: 'DeleteHomeImagePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type DeleteHomeInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
};

export type DeleteHomePayload = {
  readonly __typename?: 'DeleteHomePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type DeleteMarketplaceItemImageInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteMarketplaceItemImagePayload = {
  readonly __typename?: 'DeleteMarketplaceItemImagePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly marketplaceItemImage?: Maybe<MarketplaceItemImageNode>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  readonly __typename?: 'DjangoDebug';
  /** Raise exceptions for this API query. */
  readonly exceptions?: Maybe<ReadonlyArray<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  readonly sql?: Maybe<ReadonlyArray<Maybe<DjangoDebugSql>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  readonly __typename?: 'DjangoDebugException';
  /** The class of the exception */
  readonly excType: Scalars['String']['output'];
  /** The message of the exception */
  readonly message: Scalars['String']['output'];
  /** The stack trace */
  readonly stack: Scalars['String']['output'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  readonly __typename?: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  readonly alias: Scalars['String']['output'];
  /** Duration of this database query in seconds. */
  readonly duration: Scalars['Float']['output'];
  /** Postgres connection encoding if available. */
  readonly encoding?: Maybe<Scalars['String']['output']>;
  /** Whether this database query was a SELECT. */
  readonly isSelect: Scalars['Boolean']['output'];
  /** Whether this database query took more than 10 seconds. */
  readonly isSlow: Scalars['Boolean']['output'];
  /** Postgres isolation level if available. */
  readonly isoLevel?: Maybe<Scalars['String']['output']>;
  /** JSON encoded database query parameters. */
  readonly params: Scalars['String']['output'];
  /** The raw SQL of this query, without params. */
  readonly rawSql: Scalars['String']['output'];
  /** The actual SQL sent to this database. */
  readonly sql?: Maybe<Scalars['String']['output']>;
  /** Start time of this database query. */
  readonly startTime: Scalars['Float']['output'];
  /** Stop time of this database query. */
  readonly stopTime: Scalars['Float']['output'];
  /** Postgres transaction ID if available. */
  readonly transId?: Maybe<Scalars['String']['output']>;
  /** Postgres transaction status if available. */
  readonly transStatus?: Maybe<Scalars['String']['output']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  readonly vendor: Scalars['String']['output'];
};

export type DocumentContent = CommunityDocumentNode | ResidencyDocumentNode;

export type DocumentNode = {
  readonly __typename?: 'DocumentNode';
  readonly contentObject?: Maybe<DocumentContent>;
  readonly id: Scalars['UUID']['output'];
};

export type DocumentNodeConnection = {
  readonly __typename?: 'DocumentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<DocumentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `DocumentNode` and its cursor. */
export type DocumentNodeEdge = {
  readonly __typename?: 'DocumentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<DocumentNode>;
};

export type DownloadRateType = {
  readonly __typename?: 'DownloadRateType';
  readonly date?: Maybe<Scalars['Date']['output']>;
  readonly downloadRate?: Maybe<Scalars['Float']['output']>;
};

export type EpcResponseType = {
  readonly __typename?: 'EPCResponseType';
  readonly checkedDate: Scalars['String']['output'];
  readonly city: Scalars['String']['output'];
  readonly currentEnergyEfficiency: Scalars['String']['output'];
  readonly currentEnergyRating: Scalars['String']['output'];
  readonly expiryDate: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly postcode: Scalars['String']['output'];
  readonly potentialEnergyEfficiency: Scalars['String']['output'];
  readonly potentialEnergyRating: Scalars['String']['output'];
};

export type EditKeyInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['String']['input'];
  readonly home?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly reference?: InputMaybe<Scalars['String']['input']>;
};

export type EditKeyPayload = {
  readonly __typename?: 'EditKeyPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly key?: Maybe<KeyNode>;
};

export type EditMemberInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly communities: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly email: Scalars['String']['input'];
  readonly role: Scalars['String']['input'];
};

export type EditMemberPayload = {
  readonly __typename?: 'EditMemberPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communities?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly email?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly role?: Maybe<Scalars['String']['output']>;
};

export type EmployerReferenceNode = {
  readonly __typename?: 'EmployerReferenceNode';
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly employeeInputtedCompanyName?: Maybe<Scalars['String']['output']>;
  readonly employeeName?: Maybe<Scalars['String']['output']>;
  readonly employerEmploymentDetails?: Maybe<EmploymentDetailsNode>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly jobTitle?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly step?: Maybe<TenancyApplicationEmployerReferenceStepChoices>;
  readonly submittedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmployerUpdateEmploymentDetailsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly employerReferenceId: Scalars['String']['input'];
  readonly employmentEnding?: InputMaybe<Scalars['Boolean']['input']>;
  readonly employmentType?: InputMaybe<Scalars['String']['input']>;
  readonly fullOrPartTime?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly income?: InputMaybe<Scalars['Decimal']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly paymentFrequency?: InputMaybe<Scalars['String']['input']>;
  readonly startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type EmployerUpdateEmploymentDetailsPayload = {
  readonly __typename?: 'EmployerUpdateEmploymentDetailsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly employmentEnding?: Maybe<Scalars['Boolean']['output']>;
  readonly employmentType?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly fullOrPartTime?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly income?: Maybe<Scalars['Decimal']['output']>;
  readonly jobTitle?: Maybe<Scalars['String']['output']>;
  readonly paymentFrequency?: Maybe<Scalars['String']['output']>;
  readonly startDate?: Maybe<Scalars['Date']['output']>;
};

export type EmployerUpdatePersonalDetailsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
};

export type EmployerUpdatePersonalDetailsPayload = {
  readonly __typename?: 'EmployerUpdatePersonalDetailsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly companyName?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly jobTitle?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
};

export type EmploymentDetailsNode = {
  readonly __typename?: 'EmploymentDetailsNode';
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly employmentEnding?: Maybe<Scalars['Boolean']['output']>;
  readonly employmentType: TenancyApplicationEmploymentDetailsEmploymentTypeChoices;
  readonly fullOrPartTime?: Maybe<TenancyApplicationEmploymentDetailsFullOrPartTimeChoices>;
  readonly id: Scalars['UUID']['output'];
  readonly income?: Maybe<Scalars['Decimal']['output']>;
  readonly jobTitle?: Maybe<Scalars['String']['output']>;
  readonly paymentFrequency?: Maybe<TenancyApplicationEmploymentDetailsPaymentFrequencyChoices>;
  readonly startDate?: Maybe<Scalars['Date']['output']>;
};

export type ErrorType = {
  readonly __typename?: 'ErrorType';
  readonly field: Scalars['String']['output'];
  readonly messages: ReadonlyArray<Scalars['String']['output']>;
};

export type FakeCreditCheckMutationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly creditSafePayload: Scalars['String']['input'];
  readonly tenantReferenceId: Scalars['UUID']['input'];
};

export type FakeCreditCheckMutationPayload = {
  readonly __typename?: 'FakeCreditCheckMutationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly tenantReference: TenantReferenceNode;
};

export type FakeSendTenantReferenceRequestInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly tenantReferenceId: Scalars['UUID']['input'];
};

export type FakeSendTenantReferenceRequestPayload = {
  readonly __typename?: 'FakeSendTenantReferenceRequestPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly tenantReference: TenantReferenceNode;
};

export type FakeStripeInvoicePaidEventInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly invoiceId: Scalars['String']['input'];
};

export type FakeStripeInvoicePaidEventPayload = {
  readonly __typename?: 'FakeStripeInvoicePaidEventPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly invoice?: Maybe<InvoiceNode>;
};

export type FeatureType = {
  readonly __typename?: 'FeatureType';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly isBeta: Scalars['Boolean']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type FixFloType = {
  readonly __typename?: 'FixFloType';
  readonly baseUrl?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
};

export type GeneratedAgreementNode = {
  readonly __typename?: 'GeneratedAgreementNode';
  readonly additionalTerms?: Maybe<Scalars['String']['output']>;
  readonly client?: Maybe<ClientType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly depositAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly filename?: Maybe<Scalars['String']['output']>;
  readonly furnished: AstGeneratorGeneratedAgreementFurnishedChoices;
  readonly home?: Maybe<HomeType>;
  readonly homeCity?: Maybe<Scalars['String']['output']>;
  readonly homeName?: Maybe<Scalars['String']['output']>;
  readonly homePostcode?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly landlordAddress?: Maybe<Scalars['String']['output']>;
  readonly landlordMobile?: Maybe<Scalars['String']['output']>;
  readonly landlordName?: Maybe<Scalars['String']['output']>;
  readonly paidFor: Scalars['Boolean']['output'];
  readonly paidForByInvoice?: Maybe<Scalars['Boolean']['output']>;
  readonly paymentFrequency: AstGeneratorGeneratedAgreementPaymentFrequencyChoices;
  readonly rentAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly requirementItem?: Maybe<RequirementItemNode>;
  readonly residency?: Maybe<ResidencyType>;
  readonly residentNames?: Maybe<Scalars['String']['output']>;
  readonly signatures: AgreementSignatureNodeConnection;
  readonly status: AstGeneratorGeneratedAgreementStatusChoices;
  readonly tenancyEnd?: Maybe<Scalars['Date']['output']>;
  readonly tenancyStart?: Maybe<Scalars['Date']['output']>;
  readonly tenantEmail?: Maybe<Scalars['String']['output']>;
  readonly tenantMobile?: Maybe<Scalars['String']['output']>;
  readonly tenants: AgreementTenantNodeConnection;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly utilitiesIncluded?: Maybe<Scalars['String']['output']>;
};


export type GeneratedAgreementNodeSignaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type GeneratedAgreementNodeTenantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GeneratedAgreementNodeConnection = {
  readonly __typename?: 'GeneratedAgreementNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<GeneratedAgreementNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `GeneratedAgreementNode` and its cursor. */
export type GeneratedAgreementNodeEdge = {
  readonly __typename?: 'GeneratedAgreementNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<GeneratedAgreementNode>;
};

export type GeneratedAgreementType = {
  readonly __typename?: 'GeneratedAgreementType';
  readonly additionalTerms?: Maybe<Scalars['String']['output']>;
  readonly client?: Maybe<ClientType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly depositAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly filename?: Maybe<Scalars['String']['output']>;
  readonly furnished: AstGeneratorGeneratedAgreementFurnishedChoices;
  readonly home?: Maybe<HomeType>;
  readonly homeCity?: Maybe<Scalars['String']['output']>;
  readonly homeName?: Maybe<Scalars['String']['output']>;
  readonly homePostcode?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly landlordAddress?: Maybe<Scalars['String']['output']>;
  readonly landlordMobile?: Maybe<Scalars['String']['output']>;
  readonly landlordName?: Maybe<Scalars['String']['output']>;
  readonly paidFor: Scalars['Boolean']['output'];
  readonly paymentFrequency: AstGeneratorGeneratedAgreementPaymentFrequencyChoices;
  readonly rentAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly requirementItem?: Maybe<RequirementItemNode>;
  readonly residency?: Maybe<ResidencyType>;
  readonly residentNames?: Maybe<Scalars['String']['output']>;
  readonly signatures: AgreementSignatureNodeConnection;
  readonly status: AstGeneratorGeneratedAgreementStatusChoices;
  readonly tenancyEnd?: Maybe<Scalars['Date']['output']>;
  readonly tenancyStart?: Maybe<Scalars['Date']['output']>;
  readonly tenantEmail?: Maybe<Scalars['String']['output']>;
  readonly tenantMobile?: Maybe<Scalars['String']['output']>;
  readonly tenants: AgreementTenantNodeConnection;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly utilitiesIncluded?: Maybe<Scalars['String']['output']>;
};


export type GeneratedAgreementTypeSignaturesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type GeneratedAgreementTypeTenantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type GeneratedSection21Node = {
  readonly __typename?: 'GeneratedSection21Node';
  readonly client?: Maybe<ClientType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly deadlineDate?: Maybe<Scalars['Date']['output']>;
  readonly depositCertificateProvided: Scalars['Boolean']['output'];
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly home?: Maybe<HomeType>;
  readonly homeCity?: Maybe<Scalars['String']['output']>;
  readonly homeName?: Maybe<Scalars['String']['output']>;
  readonly homePostcode?: Maybe<Scalars['String']['output']>;
  readonly homeRegion?: Maybe<Scalars['String']['output']>;
  readonly htrgProvided: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly inspectionDate?: Maybe<Scalars['Date']['output']>;
  readonly landlordAddress?: Maybe<Scalars['String']['output']>;
  readonly landlordEmail?: Maybe<Scalars['String']['output']>;
  readonly landlordName?: Maybe<Scalars['String']['output']>;
  readonly landlordPhone?: Maybe<Scalars['String']['output']>;
  readonly leadTenantEmail?: Maybe<Scalars['String']['output']>;
  readonly paidFor: Scalars['Boolean']['output'];
  readonly possessionDate?: Maybe<Scalars['Date']['output']>;
  readonly remindLandlord: Scalars['Boolean']['output'];
  readonly serviceDate?: Maybe<Scalars['Date']['output']>;
  readonly tenantEmails?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly tenants?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly viewedOnArk: Scalars['Boolean']['output'];
};

export type GeneratedSection21NodeConnection = {
  readonly __typename?: 'GeneratedSection21NodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<GeneratedSection21NodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `GeneratedSection21Node` and its cursor. */
export type GeneratedSection21NodeEdge = {
  readonly __typename?: 'GeneratedSection21NodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<GeneratedSection21Node>;
};

export type GeneratedSection21Type = {
  readonly __typename?: 'GeneratedSection21Type';
  readonly client?: Maybe<ClientType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly deadlineDate?: Maybe<Scalars['Date']['output']>;
  readonly depositCertificateProvided: Scalars['Boolean']['output'];
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly home?: Maybe<HomeType>;
  readonly homeCity?: Maybe<Scalars['String']['output']>;
  readonly homeName?: Maybe<Scalars['String']['output']>;
  readonly homePostcode?: Maybe<Scalars['String']['output']>;
  readonly homeRegion?: Maybe<Scalars['String']['output']>;
  readonly htrgProvided: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly inspectionDate?: Maybe<Scalars['Date']['output']>;
  readonly landlordAddress?: Maybe<Scalars['String']['output']>;
  readonly landlordEmail?: Maybe<Scalars['String']['output']>;
  readonly landlordName?: Maybe<Scalars['String']['output']>;
  readonly landlordPhone?: Maybe<Scalars['String']['output']>;
  readonly leadTenantEmail?: Maybe<Scalars['String']['output']>;
  readonly possessionDate?: Maybe<Scalars['Date']['output']>;
  readonly serviceDate?: Maybe<Scalars['Date']['output']>;
  readonly tenants?: Maybe<Scalars['String']['output']>;
  readonly viewedOnArk: Scalars['Boolean']['output'];
};

export type GroupChatMessageNode = {
  readonly __typename?: 'GroupChatMessageNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly media?: Maybe<Scalars['String']['output']>;
  readonly text?: Maybe<Scalars['String']['output']>;
  readonly user?: Maybe<UserType>;
};

export type GroupChatMessageNodeConnection = {
  readonly __typename?: 'GroupChatMessageNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<GroupChatMessageNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `GroupChatMessageNode` and its cursor. */
export type GroupChatMessageNodeEdge = {
  readonly __typename?: 'GroupChatMessageNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<GroupChatMessageNode>;
};

export type GroupChatNode = {
  readonly __typename?: 'GroupChatNode';
  readonly community: CommunityNode;
  readonly id: Scalars['UUID']['output'];
};

export type GroupChatNodeConnection = {
  readonly __typename?: 'GroupChatNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<GroupChatNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `GroupChatNode` and its cursor. */
export type GroupChatNodeEdge = {
  readonly __typename?: 'GroupChatNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<GroupChatNode>;
};

export enum HistoricalDataFrequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum HistoricalDataPointType {
  ActiveResidentCount = 'ACTIVE_RESIDENT_COUNT',
  AveragePackageCollectionTime = 'AVERAGE_PACKAGE_COLLECTION_TIME',
  DownloadRatePercentage = 'DOWNLOAD_RATE_PERCENTAGE',
  FacilityBookingsCount = 'FACILITY_BOOKINGS_COUNT',
  IncomingMessagesCount = 'INCOMING_MESSAGES_COUNT',
  KeyInCount = 'KEY_IN_COUNT',
  KeyOutCount = 'KEY_OUT_COUNT',
  PackageCollectedCount = 'PACKAGE_COLLECTED_COUNT',
  PackageDeliveredCount = 'PACKAGE_DELIVERED_COUNT'
}

export type HomeDetailsType = {
  readonly __typename?: 'HomeDetailsType';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly furnishing?: Maybe<ResidentialManagementHomeDetailsFurnishingChoices>;
  readonly garden?: Maybe<Scalars['Boolean']['output']>;
  readonly home: HomeType;
  readonly kitchen?: Maybe<Scalars['Boolean']['output']>;
  readonly numBathrooms?: Maybe<Scalars['Int']['output']>;
  readonly numBedrooms?: Maybe<Scalars['Int']['output']>;
  readonly numEnsuites?: Maybe<Scalars['Int']['output']>;
  readonly numLivingRooms?: Maybe<Scalars['Int']['output']>;
  readonly office?: Maybe<Scalars['Boolean']['output']>;
  readonly parking?: Maybe<ResidentialManagementHomeDetailsParkingChoices>;
};

export type HomeImageType = {
  readonly __typename?: 'HomeImageType';
  readonly home: HomeType;
  readonly id: Scalars['UUID']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
};

export type HomeImageTypeConnection = {
  readonly __typename?: 'HomeImageTypeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<HomeImageTypeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `HomeImageType` and its cursor. */
export type HomeImageTypeEdge = {
  readonly __typename?: 'HomeImageTypeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<HomeImageType>;
};

export type HomeNode = {
  readonly __typename?: 'HomeNode';
  readonly HMO: Scalars['Boolean']['output'];
  readonly activeResidency?: Maybe<ResidencyNode>;
  readonly address: Scalars['String']['output'];
  readonly addressLine1?: Maybe<Scalars['String']['output']>;
  readonly applications: ApplicationNodeConnection;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly client: ClientType;
  readonly community?: Maybe<CommunityNode>;
  readonly externalId?: Maybe<Scalars['String']['output']>;
  readonly fixfloUrl?: Maybe<Scalars['String']['output']>;
  readonly hasFixfloExternalId?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly images: HomeImageTypeConnection;
  readonly keys?: Maybe<KeyNodeConnection>;
  readonly leasehold?: Maybe<LeaseholdNode>;
  readonly name: Scalars['String']['output'];
  readonly packages?: Maybe<PackageNodeConnection>;
  readonly postcode: Scalars['String']['output'];
  readonly residencies?: Maybe<ResidencyNodeConnection>;
  readonly similarity?: Maybe<Scalars['Float']['output']>;
  readonly uncollectedPackageCount: Scalars['Int']['output'];
};


export type HomeNodeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type HomeNodeImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HomeNodeKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HomeNodePackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type HomeNodeResidenciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type HomeNodeConnection = {
  readonly __typename?: 'HomeNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<HomeNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `HomeNode` and its cursor. */
export type HomeNodeEdge = {
  readonly __typename?: 'HomeNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<HomeNode>;
};

export type HomeType = {
  readonly __typename?: 'HomeType';
  readonly HMO: Scalars['Boolean']['output'];
  readonly activeResidencies?: Maybe<ReadonlyArray<Maybe<ResidencyType>>>;
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly addressLine1?: Maybe<Scalars['String']['output']>;
  readonly applications: ApplicationNodeConnection;
  readonly archivedLeaseholds: LeaseholdNodeConnection;
  readonly block?: Maybe<BlockNode>;
  readonly cachedAddressees?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly client: ClientType;
  readonly community?: Maybe<CommunityNode>;
  readonly communityGroups: CommunityGroupNodeConnection;
  readonly complianceRequirements: ComplianceRequirementNodeConnection;
  readonly conversations: ConversationTypeConnection;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly currentPackageCount?: Maybe<Scalars['Int']['output']>;
  readonly currentResidency?: Maybe<ResidencyType>;
  readonly details?: Maybe<HomeDetailsType>;
  readonly generatedAgreements: GeneratedAgreementNodeConnection;
  readonly id: Scalars['UUID']['output'];
  readonly images: HomeImageTypeConnection;
  readonly keys: KeyNodeConnection;
  readonly latitude?: Maybe<Scalars['Decimal']['output']>;
  readonly leasehold?: Maybe<LeaseholdNode>;
  readonly longitude?: Maybe<Scalars['Decimal']['output']>;
  readonly name: Scalars['String']['output'];
  readonly numRooms: Scalars['Int']['output'];
  readonly postcode: Scalars['String']['output'];
  readonly residencies: ReadonlyArray<ResidencyType>;
  readonly section21s: GeneratedSection21NodeConnection;
  readonly shortCode?: Maybe<Scalars['String']['output']>;
  readonly tenancyApplications: TenancyApplicationNodeConnection;
  readonly uncollectedPackageCount: Scalars['Int']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
};


export type HomeTypeApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type HomeTypeArchivedLeaseholdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HomeTypeCommunityGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type HomeTypeComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type HomeTypeConversationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['UUID']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  excludeId?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasResponseTime?: InputMaybe<Scalars['Boolean']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  isUrgent?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  residency?: InputMaybe<Scalars['UUID']['input']>;
  resident?: InputMaybe<Scalars['UUID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  subject_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type HomeTypeGeneratedAgreementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HomeTypeImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HomeTypeKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasCurrentKeyholder?: InputMaybe<Scalars['Boolean']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type HomeTypeSection21sArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type HomeTypeTenancyApplicationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasBeenSent?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type IdentityVerificationNode = {
  readonly __typename?: 'IdentityVerificationNode';
  readonly documentExpiryDate?: Maybe<Scalars['Date']['output']>;
  readonly documentNumber?: Maybe<Scalars['String']['output']>;
  readonly documentType?: Maybe<Scalars['String']['output']>;
  readonly hasRightToRent?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly nationality?: Maybe<Scalars['String']['output']>;
  readonly passport?: Maybe<Scalars['String']['output']>;
  readonly passportSelfie?: Maybe<Scalars['String']['output']>;
  readonly personaInquiryId: Scalars['String']['output'];
  readonly selfie?: Maybe<Scalars['String']['output']>;
  readonly status: TenancyApplicationIdentityVerificationStatusChoices;
};

export type IgnoreMessageSuggestionInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly suggestionId: Scalars['UUID']['input'];
};

export type IgnoreMessageSuggestionPayload = {
  readonly __typename?: 'IgnoreMessageSuggestionPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<ConversationType>;
};

export type InitializeSignup = {
  readonly __typename?: 'InitializeSignup';
  readonly landlord?: Maybe<LandlordNode>;
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type IntegrationType = {
  readonly __typename?: 'IntegrationType';
  readonly fixflo?: Maybe<FixFloType>;
};

export type InviteDemoUserInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly communityId: Scalars['UUID']['input'];
  readonly email: Scalars['String']['input'];
};

export type InviteDemoUserPayload = {
  readonly __typename?: 'InviteDemoUserPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly residency: ResidencyNode;
};

export type InvoiceNode = {
  readonly __typename?: 'InvoiceNode';
  readonly id: Scalars['UUID']['output'];
  readonly isPaid: Scalars['Boolean']['output'];
};

export type InvoiceNodeConnection = {
  readonly __typename?: 'InvoiceNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<InvoiceNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `InvoiceNode` and its cursor. */
export type InvoiceNodeEdge = {
  readonly __typename?: 'InvoiceNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<InvoiceNode>;
};

export type Invoices = {
  readonly __typename?: 'Invoices';
  readonly amount: Scalars['Int']['output'];
  readonly charge?: Maybe<ChargeType>;
  readonly created: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly nextPaymentAttempt?: Maybe<Scalars['Date']['output']>;
  readonly number?: Maybe<Scalars['String']['output']>;
  readonly paidAt?: Maybe<Scalars['String']['output']>;
  readonly paymentIntent?: Maybe<PaymentIntentType>;
  readonly status: Scalars['String']['output'];
};

export type KeyEventNode = {
  readonly __typename?: 'KeyEventNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly key: KeyNode;
  readonly keyholder: KeyholderNode;
  readonly performedBy: UserType;
  readonly type: ResidentialManagementKeyEventTypeChoices;
};

export type KeyEventNodeConnection = {
  readonly __typename?: 'KeyEventNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KeyEventNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `KeyEventNode` and its cursor. */
export type KeyEventNodeEdge = {
  readonly __typename?: 'KeyEventNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<KeyEventNode>;
};

export type KeyNode = {
  readonly __typename?: 'KeyNode';
  readonly archivedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly checkoutDate?: Maybe<Scalars['DateTime']['output']>;
  readonly community: CommunityNode;
  readonly communityName: Scalars['String']['output'];
  readonly currentKeyholder?: Maybe<KeyholderNode>;
  readonly events: KeyEventNodeConnection;
  readonly home?: Maybe<HomeType>;
  readonly id: Scalars['UUID']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly keyholders: KeyholderNodeConnection;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly reference?: Maybe<Scalars['String']['output']>;
  readonly status: Scalars['String']['output'];
};


export type KeyNodeEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type KeyNodeKeyholdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type KeyNodeConnection = {
  readonly __typename?: 'KeyNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KeyNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `KeyNode` and its cursor. */
export type KeyNodeEdge = {
  readonly __typename?: 'KeyNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<KeyNode>;
};

export type KeyholderNode = {
  readonly __typename?: 'KeyholderNode';
  readonly archivedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly company?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly endDate?: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly key: KeyNode;
  readonly name: Scalars['String']['output'];
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['DateTime']['output'];
  readonly type: Scalars['String']['output'];
};

export type KeyholderNodeConnection = {
  readonly __typename?: 'KeyholderNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<KeyholderNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `KeyholderNode` and its cursor. */
export type KeyholderNodeEdge = {
  readonly __typename?: 'KeyholderNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<KeyholderNode>;
};

export type LandlordAttachmentNode = {
  readonly __typename?: 'LandlordAttachmentNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly home?: Maybe<HomeType>;
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly requirementItem?: Maybe<RequirementItemNode>;
  readonly type: ComplianceAttachmentTypeChoices;
};

export type LandlordAttachmentType = {
  readonly __typename?: 'LandlordAttachmentType';
  readonly client: ClientType;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly filename?: Maybe<Scalars['String']['output']>;
  readonly home?: Maybe<HomeType>;
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
  readonly objectId: Scalars['UUID']['output'];
  readonly requirementItem?: Maybe<RequirementItemNode>;
  readonly type: ComplianceAttachmentTypeChoices;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type LandlordDocumentNode = GeneratedAgreementNode | GeneratedSection21Node | LandlordAttachmentNode;

export type LandlordNode = {
  readonly __typename?: 'LandlordNode';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly avatar?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly emailPreferences: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly smsPreferences: Scalars['Boolean']['output'];
};

export type LandlordReferenceNode = {
  readonly __typename?: 'LandlordReferenceNode';
  readonly comments?: Maybe<Scalars['String']['output']>;
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly hasOutstandingRent: TenancyApplicationLandlordReferenceHasOutstandingRentChoices;
  readonly id: Scalars['UUID']['output'];
  readonly landlordOrAgent: Scalars['String']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly mobile: Scalars['String']['output'];
  readonly monthlyRentAmount: Scalars['Decimal']['output'];
  readonly rentPaidOnTime: TenancyApplicationLandlordReferenceRentPaidOnTimeChoices;
  readonly step?: Maybe<TenancyApplicationLandlordReferenceStepChoices>;
  readonly submittedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly tenancyType: TenancyApplicationLandlordReferenceTenancyTypeChoices;
  readonly tenantMoveInDate?: Maybe<Scalars['Date']['output']>;
  readonly tenantReference?: Maybe<TenantReferenceNode>;
  readonly treatedPropertyWell: TenancyApplicationLandlordReferenceTreatedPropertyWellChoices;
};

export type LandlordUpdateLandlordReferenceInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly landlordOrAgent: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly mobile: Scalars['String']['input'];
};

export type LandlordUpdateLandlordReferencePayload = {
  readonly __typename?: 'LandlordUpdateLandlordReferencePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly landlordOrAgent?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
};

export type LandlordUpdateRentalDetailsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly hasOutstandingRent?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly monthlyRentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly rentPaidOnTime?: InputMaybe<Scalars['String']['input']>;
  readonly tenancyType?: InputMaybe<Scalars['String']['input']>;
  readonly tenantMoveInDate?: InputMaybe<Scalars['Date']['input']>;
  readonly treatedPropertyWell?: InputMaybe<Scalars['String']['input']>;
};

export type LandlordUpdateRentalDetailsPayload = {
  readonly __typename?: 'LandlordUpdateRentalDetailsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly hasOutstandingRent?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly monthlyRentAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly rentPaidOnTime?: Maybe<Scalars['String']['output']>;
  readonly tenancyType?: Maybe<Scalars['String']['output']>;
  readonly tenantMoveInDate?: Maybe<Scalars['Date']['output']>;
  readonly treatedPropertyWell?: Maybe<Scalars['String']['output']>;
};

export type LeaseholdNode = {
  readonly __typename?: 'LeaseholdNode';
  readonly balance?: Maybe<Scalars['Decimal']['output']>;
  readonly balanceLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly externalId?: Maybe<Scalars['String']['output']>;
  readonly home?: Maybe<HomeType>;
  readonly id: Scalars['UUID']['output'];
  readonly lastPayment?: Maybe<PaymentNode>;
  readonly leaseholder: LeaseholderNodeConnection;
};


export type LeaseholdNodeLeaseholderArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LeaseholdNodeConnection = {
  readonly __typename?: 'LeaseholdNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<LeaseholdNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `LeaseholdNode` and its cursor. */
export type LeaseholdNodeEdge = {
  readonly __typename?: 'LeaseholdNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<LeaseholdNode>;
};

export type LeaseholderNode = {
  readonly __typename?: 'LeaseholderNode';
  readonly id: Scalars['UUID']['output'];
  readonly leaseholds: LeaseholdNodeConnection;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly serviceChargeWaiverAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly user: UserType;
};


export type LeaseholderNodeLeaseholdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LeaseholderNodeConnection = {
  readonly __typename?: 'LeaseholderNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<LeaseholderNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `LeaseholderNode` and its cursor. */
export type LeaseholderNodeEdge = {
  readonly __typename?: 'LeaseholderNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<LeaseholderNode>;
};

export type LogDocumentReadInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly document: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type LogDocumentReadPayload = {
  readonly __typename?: 'LogDocumentReadPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly document?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type LogManagerDashUse = {
  readonly __typename?: 'LogManagerDashUse';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type LogPublicDocumentReadInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly document: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type LogPublicDocumentReadPayload = {
  readonly __typename?: 'LogPublicDocumentReadPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly document?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type LogUserAppUseInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly deviceBrand: Scalars['String']['input'];
  readonly deviceType: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly locale?: InputMaybe<Scalars['String']['input']>;
  readonly systemName: Scalars['String']['input'];
};

export type LogUserAppUsePayload = {
  readonly __typename?: 'LogUserAppUsePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly deviceBrand?: Maybe<Scalars['String']['output']>;
  readonly deviceType?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly locale?: Maybe<Scalars['String']['output']>;
  readonly systemName?: Maybe<Scalars['String']['output']>;
};

export type ManagerAddApplicationHomeInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly home?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ManagerAddApplicationHomePayload = {
  readonly __typename?: 'ManagerAddApplicationHomePayload';
  readonly application?: Maybe<ApplicationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type ManagerCancelAmenityReservationInput = {
  readonly cancellationMessage?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ManagerCancelAmenityReservationPayload = {
  readonly __typename?: 'ManagerCancelAmenityReservationPayload';
  readonly amenityReservation?: Maybe<AmenityReservationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type ManagerCreateAmenityReservationInput = {
  readonly amenityId: Scalars['UUID']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly reason: Scalars['String']['input'];
  readonly timeSlots: ReadonlyArray<InputMaybe<TimeSlot>>;
};

export type ManagerCreateAmenityReservationPayload = {
  readonly __typename?: 'ManagerCreateAmenityReservationPayload';
  readonly amenity?: Maybe<AmenityNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type ManagerNotificationType = {
  readonly __typename?: 'ManagerNotificationType';
  readonly category?: Maybe<Scalars['String']['output']>;
  readonly client: ClientType;
  readonly contentObject?: Maybe<NotificationContentObjectType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly description: Scalars['String']['output'];
  readonly dismissed: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly objectId: Scalars['UUID']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type ManagerProfileType = {
  readonly __typename?: 'ManagerProfileType';
  readonly ASTPageViewed: Scalars['Boolean']['output'];
  readonly acceptedMarketing: Scalars['Boolean']['output'];
  readonly complianceEmailExplanation: Scalars['Boolean']['output'];
  readonly compliancePageViewed: Scalars['Boolean']['output'];
  readonly complianceUpdateEmails: Scalars['Boolean']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly manager: ManagerType;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly valueQuestionnaireAnswer?: Maybe<Scalars['Int']['output']>;
};

export type ManagerReserveAmenityInput = {
  readonly amenity?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly end: Scalars['DateTime']['input'];
  readonly reason?: InputMaybe<Scalars['String']['input']>;
  readonly start: Scalars['DateTime']['input'];
};

export type ManagerReserveAmenityPayload = {
  readonly __typename?: 'ManagerReserveAmenityPayload';
  readonly amenityReservation?: Maybe<AmenityReservationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type ManagerType = {
  readonly __typename?: 'ManagerType';
  readonly applicationAttempts: ApplicationAttemptNodeConnection;
  readonly calendarLink?: Maybe<CalendarLinkType>;
  readonly client?: Maybe<ClientType>;
  readonly communities?: Maybe<CommunityNodeConnection>;
  readonly community?: Maybe<CommunityNodeConnection>;
  readonly complianceUpdateReads: ReadonlyArray<ComplianceUpdateReadType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly createdDocuments: CommunityDocumentNodeConnection;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly emailPreferences: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly isActive?: Maybe<Scalars['Boolean']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly profile?: Maybe<ManagerProfileType>;
  readonly residencydocumentSet: ResidencyDocumentNodeConnection;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user: UserType;
};


export type ManagerTypeApplicationAttemptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type ManagerTypeCommunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type ManagerTypeCommunityArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  includeDeactivated?: InputMaybe<Scalars['Boolean']['input']>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  referencingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type ManagerTypeCreatedDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type ManagerTypeResidencydocumentSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MarketplaceItemCommentNode = {
  readonly __typename?: 'MarketplaceItemCommentNode';
  readonly author?: Maybe<UserType>;
  readonly comment: Scalars['String']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly name: Scalars['String']['output'];
};

export type MarketplaceItemCommentNodeConnection = {
  readonly __typename?: 'MarketplaceItemCommentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MarketplaceItemCommentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `MarketplaceItemCommentNode` and its cursor. */
export type MarketplaceItemCommentNodeEdge = {
  readonly __typename?: 'MarketplaceItemCommentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<MarketplaceItemCommentNode>;
};

export type MarketplaceItemImageNode = {
  readonly __typename?: 'MarketplaceItemImageNode';
  readonly id: Scalars['UUID']['output'];
  readonly image: Scalars['String']['output'];
};

export type MarketplaceItemImageNodeConnection = {
  readonly __typename?: 'MarketplaceItemImageNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MarketplaceItemImageNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `MarketplaceItemImageNode` and its cursor. */
export type MarketplaceItemImageNodeEdge = {
  readonly __typename?: 'MarketplaceItemImageNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<MarketplaceItemImageNode>;
};

export type MarketplaceItemNode = {
  readonly __typename?: 'MarketplaceItemNode';
  readonly archivedBy?: Maybe<UserType>;
  readonly archivedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly archivedReason?: Maybe<Scalars['String']['output']>;
  readonly closedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly comments: MarketplaceItemCommentNodeConnection;
  readonly community: CommunityNode;
  readonly condition?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly images: MarketplaceItemImageNodeConnection;
  readonly notifications?: Maybe<NotificationNodeConnection>;
  readonly price: Scalars['Decimal']['output'];
  readonly title: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user: UserType;
};


export type MarketplaceItemNodeCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type MarketplaceItemNodeImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type MarketplaceItemNodeNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MarketplaceItemNodeConnection = {
  readonly __typename?: 'MarketplaceItemNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<MarketplaceItemNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `MarketplaceItemNode` and its cursor. */
export type MarketplaceItemNodeEdge = {
  readonly __typename?: 'MarketplaceItemNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<MarketplaceItemNode>;
};

export type MessageSuggestionType = {
  readonly __typename?: 'MessageSuggestionType';
  readonly action?: Maybe<ResidentialManagementMessageSuggestionActionChoices>;
  readonly alteredMessage?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly suggestion?: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly acceptApplication?: Maybe<AcceptApplication>;
  readonly acceptMessageSuggestion?: Maybe<AcceptMessageSuggestionPayload>;
  readonly acceptServiceChargeWaiver?: Maybe<AcceptServiceChargeWaiverPayload>;
  readonly acceptTermsAndConditions?: Maybe<AcceptTermsAndConditionsPayload>;
  readonly activateGroup?: Maybe<ActivateGroupPayload>;
  readonly addApplicationCommunity?: Maybe<AddApplicationCommunity>;
  readonly addApplicationHome?: Maybe<AddApplicationHome>;
  readonly addApplicationOwnershipType?: Maybe<AddApplicationOwnershipType>;
  readonly addDocumentSentStatus?: Maybe<AddDocumentSentStatus>;
  readonly addFeatureToClient?: Maybe<AddFeatureToClientPayload>;
  readonly addMember?: Maybe<AddMemberPayload>;
  readonly addPackageToHome?: Maybe<AddPackageToHomePayload>;
  readonly addUnknownCommunityStatus?: Maybe<AddUnknownCommunityStatus>;
  readonly archiveApplication?: Maybe<ArchiveApplicationPayload>;
  readonly archiveKey?: Maybe<ArchiveKeyPayload>;
  readonly archiveKeyholder?: Maybe<ArchiveKeyholderPayload>;
  readonly archiveMarketplaceItem?: Maybe<ArchiveMarketplaceItemPayload>;
  readonly associateAgreementWithClient?: Maybe<AssociateAgreementWithClient>;
  readonly associateSection21WithClient?: Maybe<AssociateSection21WithClient>;
  readonly bookCommunityEvent?: Maybe<BookCommunityEventMutationPayload>;
  readonly bookPaidCommunityEvent?: Maybe<BookPaidCommunityEventMutationPayload>;
  readonly cancelAmenityReservation?: Maybe<CancelAmenityReservationPayload>;
  readonly cancelPayment?: Maybe<CancelPaymentPayload>;
  readonly cancelScheduledPublishCommunityUpdate?: Maybe<CancelScheduledPublishCommunityUpdatePayload>;
  readonly checkInKeyV2?: Maybe<CheckInKeyV2Payload>;
  readonly checkOutKeyV2?: Maybe<CheckOutKeyV2Payload>;
  readonly closeConversation?: Maybe<CloseConversationPayload>;
  readonly closeSurvey?: Maybe<CloseSurvey>;
  readonly collectPackage?: Maybe<CollectPackagePayload>;
  readonly collectPackages?: Maybe<CollectPackagesPayload>;
  readonly collectPackagesForHome?: Maybe<CollectPackagesForHomePayload>;
  readonly confirmResidencyWillRoll?: Maybe<ConfirmResidencyWillRollPayload>;
  readonly countersignAgreement?: Maybe<CountersignAgreementPayload>;
  readonly createAgreementForTenancy?: Maybe<CreateAgreementForTenancyPayload>;
  readonly createAmenityReservation?: Maybe<CreateAmenityReservationMutationPayload>;
  readonly createApplicantAndApplication?: Maybe<CreateApplicantAndApplicationPayload>;
  readonly createApplication?: Maybe<CreateApplicationPayload>;
  readonly createAttachmentOnHome?: Maybe<CreateAttachmentOnHomePayload>;
  readonly createAttachmentOnRequirementItem?: Maybe<CreateAttachmentOnRequirementItemPayload>;
  readonly createBlockComplianceRequirementItem?: Maybe<CreateBlockComplianceRequirementItemPayload>;
  readonly createBlocks?: Maybe<CreateBlocks>;
  readonly createBlocksComplianceRequirement?: Maybe<CreateBlocksComplianceRequirementPayload>;
  readonly createCheckoutLink?: Maybe<CreateCheckoutLinkPayload>;
  readonly createCommunityDocument?: Maybe<CreateCommunityDocumentPayload>;
  readonly createCommunityDocumentView?: Maybe<CreateCommunityDocumentViewPayload>;
  readonly createCommunityProduct?: Maybe<CreateCommunityProductPayload>;
  readonly createCommunityProductPayment?: Maybe<CreateCommunityProductPaymentPayload>;
  readonly createCommunityUpdateRead?: Maybe<CreateCommunityUpdateReadPayload>;
  readonly createCommunityVisitor?: Maybe<CreateCommunityVisitorPayload>;
  readonly createComplianceRequirement?: Maybe<CreateComplianceRequirementPayload>;
  readonly createComplianceUpdateRead?: Maybe<CreateComplianceUpdateReadPayload>;
  readonly createConversation?: Maybe<CreateConversation>;
  readonly createConversationAction?: Maybe<CreateConversationActionPayload>;
  readonly createConversationAndFirstMessage?: Maybe<CreateConversationAndFirstMessage>;
  readonly createConversationMessage?: Maybe<CreateConversationMessage>;
  readonly createConversationTag?: Maybe<CreateConversationTagPayload>;
  readonly createGroup?: Maybe<CreateGroupPayload>;
  readonly createGroupChatMessage?: Maybe<CreateGroupChatMessagePayload>;
  readonly createKeyV2?: Maybe<CreateKeyV2Payload>;
  readonly createKeyholderV2?: Maybe<CreateKeyholderV2Payload>;
  readonly createManagerConversationRead?: Maybe<CreateManagerConversationReadPayload>;
  readonly createMarketplaceItemComment?: Maybe<CreateMarketplaceItemCommentPayload>;
  readonly createNewDevice?: Maybe<CreateNewDevice>;
  readonly createOccupancySurvey?: Maybe<CreateOccupancySurvey>;
  readonly createOpenedConversation?: Maybe<CreateOpenedConversation>;
  readonly createPayment?: Maybe<CreatePaymentPayload>;
  readonly createResidencyAndResidents?: Maybe<CreateResidencyAndResidents>;
  readonly createResidencyDocument?: Maybe<CreateResidencyDocumentPayload>;
  readonly createResidencyDocumentView?: Maybe<CreateResidencyDocumentViewPayload>;
  readonly createResidencyDocuments?: Maybe<CreateResidencyDocuments>;
  readonly createResidencySubscription?: Maybe<CreateResidencySubscriptionPayload>;
  readonly createStripeAccount?: Maybe<CreateStripeAccount>;
  readonly createSurvey?: Maybe<CreateSurveyPayload>;
  readonly createTenancyApplication?: Maybe<CreateTenancyApplicationPayload>;
  readonly createUpdateComment?: Maybe<CreateUpdateCommentPayload>;
  readonly deactivateClientUser?: Maybe<DeactivateClientUserPayload>;
  readonly deactivateGroup?: Maybe<DeactivateGroupPayload>;
  readonly deleteAmenity?: Maybe<DeleteAmenityPayload>;
  readonly deleteApplicant?: Maybe<DeleteApplicant>;
  readonly deleteAttachment?: Maybe<DeleteAttachmentPayload>;
  readonly deleteCommunityDocument?: Maybe<DeleteCommunityDocumentPayload>;
  readonly deleteCommunityProduct?: Maybe<DeleteCommunityProductPayload>;
  readonly deleteCommunityUpdate?: Maybe<DeleteCommunityUpdatePayload>;
  readonly deleteComplianceRequirementV2?: Maybe<DeleteComplianceRequirementV2Payload>;
  readonly deleteConversationTag?: Maybe<DeleteConversationTagPayload>;
  readonly deleteHome?: Maybe<DeleteHomePayload>;
  readonly deleteHomeImage?: Maybe<DeleteHomeImagePayload>;
  readonly deleteMarketplaceItemImage?: Maybe<DeleteMarketplaceItemImagePayload>;
  readonly deleteUpdateComment?: Maybe<DeleteCommunityUpdateCommentPayload>;
  readonly editKey?: Maybe<EditKeyPayload>;
  readonly editMember?: Maybe<EditMemberPayload>;
  readonly employerUpdateEmploymentDetails?: Maybe<EmployerUpdateEmploymentDetailsPayload>;
  readonly employerUpdatePersonalDetails?: Maybe<EmployerUpdatePersonalDetailsPayload>;
  readonly fakeCreditCheck?: Maybe<FakeCreditCheckMutationPayload>;
  readonly fakeSendTenantReferenceRequest?: Maybe<FakeSendTenantReferenceRequestPayload>;
  readonly fakeStripeInvoicePaidEvent?: Maybe<FakeStripeInvoicePaidEventPayload>;
  readonly ignoreMessageSuggestion?: Maybe<IgnoreMessageSuggestionPayload>;
  readonly initializeCalendarLink?: Maybe<CalendarLinkType>;
  readonly initializeSignup?: Maybe<InitializeSignup>;
  readonly inviteDemoUser?: Maybe<InviteDemoUserPayload>;
  readonly landlordUpdateLandlordReference?: Maybe<LandlordUpdateLandlordReferencePayload>;
  readonly landlordUpdateRentalDetails?: Maybe<LandlordUpdateRentalDetailsPayload>;
  readonly logDocumentRead?: Maybe<LogDocumentReadPayload>;
  readonly logManagerDashUse?: Maybe<LogManagerDashUse>;
  readonly logPublicDocumentRead?: Maybe<LogPublicDocumentReadPayload>;
  readonly logUserAppUse?: Maybe<LogUserAppUsePayload>;
  readonly managerAddApplicationHome?: Maybe<ManagerAddApplicationHomePayload>;
  readonly managerCancelAmenityReservation?: Maybe<ManagerCancelAmenityReservationPayload>;
  readonly managerCreateAmenityReservation?: Maybe<ManagerCreateAmenityReservationPayload>;
  readonly managerReserveAmenity?: Maybe<ManagerReserveAmenityPayload>;
  readonly officiallyVerifyRightToRent?: Maybe<OfficiallyVerifyRightToRentPayload>;
  readonly orderConversationTags?: Maybe<OrderConversationTagsPayload>;
  readonly packageReminderToAll?: Maybe<PackageReminderToAll>;
  readonly packageReminderToHome?: Maybe<PackageReminderToHomePayload>;
  readonly payForDocument?: Maybe<PayForDocumentPayload>;
  readonly payForUnpaidApplicationReferences?: Maybe<PayForUnpaidApplicationReferencesPayload>;
  readonly publishCommunityUpdate?: Maybe<PublishCommunityUpdatePayload>;
  readonly rateConversation?: Maybe<RateConversationPayload>;
  readonly reOpenConversation?: Maybe<ReOpenConversationPayload>;
  readonly readAllNotifications?: Maybe<ReadAllNotificationsPayload>;
  readonly readAllNotificationsOnTarget?: Maybe<ReadAllNotificationsOnTargetPayload>;
  readonly readNotification?: Maybe<ReadNotificationPayload>;
  readonly referenceSignupError?: Maybe<ReferenceSignupErrorPayload>;
  readonly rejectApplication?: Maybe<RejectApplication>;
  readonly remindAllApplicants?: Maybe<RemindAllApplicants>;
  readonly remindApplicant?: Maybe<RemindApplicant>;
  readonly remindResident?: Maybe<RemindResidentPayload>;
  readonly removeConversationTag?: Maybe<RemoveConversationTagPayload>;
  readonly removeResidentFromUnpaidEvents?: Maybe<RemoveResidentFromUnpaidEventsPayload>;
  readonly resendMemberInviteEmail?: Maybe<ResendMemberInviteEmailPayload>;
  readonly reserveAmenity?: Maybe<ReserveAmenityPayload>;
  readonly residentArchiveKeyholder?: Maybe<ResidentArchiveKeyholderPayload>;
  readonly residentCreateKeyholder?: Maybe<ResidentCreateKeyholder>;
  readonly residentUnarchiveKeyholder?: Maybe<ResidentUnarchiveKeyholderPayload>;
  readonly residentUpdateKeyholder?: Maybe<ResidentUpdateKeyholderPayload>;
  readonly restartIdentityVerification?: Maybe<RestartIdentityVerificationPayload>;
  readonly revokeGeneratedAgreement?: Maybe<RevokeGeneratedAgreementPayload>;
  readonly saveAmenity?: Maybe<SaveAmenityPayload>;
  readonly saveCommunityEvent?: Maybe<SaveCommunityEventPayload>;
  readonly saveCommunityUpdate?: Maybe<SaveCommunityUpdatePayload>;
  readonly saveGeneratedAgreement?: Maybe<SaveGeneratedAgreementPayload>;
  readonly saveGeneratedSection21?: Maybe<SaveGeneratedSection21Payload>;
  readonly saveHome?: Maybe<SaveHomePayload>;
  readonly saveManagerProfile?: Maybe<SaveManagerProfilePayload>;
  readonly saveMarketplaceItem?: Maybe<SaveMarketplaceItemPayload>;
  readonly saveRequirementItem?: Maybe<SaveRequirementItemPayload>;
  readonly saveResidentSurveyAnswer?: Maybe<SaveResidentSurveyAnswerPayload>;
  readonly saveTenantEmploymentDetails?: Maybe<SaveTenantEmploymentDetailsPayload>;
  readonly schedulePublishCommunityUpdate?: Maybe<SchedulePublishCommunityUpdatePayload>;
  readonly sendAgreementForSigning?: Maybe<SendAgreementForSigning>;
  readonly sendDocument?: Maybe<SendDocument>;
  readonly sendManagerAmplitudeTest?: Maybe<SendManagerAmplitudeTestPayload>;
  readonly sendTenantReferenceRequests?: Maybe<SendTenantReferenceRequestsPayload>;
  readonly setResidencyCompletionDate?: Maybe<SetResidencyCompletionDatePayload>;
  readonly setUpManager?: Maybe<SetUpManagerPayload>;
  readonly shareComplianceRequirement?: Maybe<ShareComplianceRequirementPayload>;
  readonly signOutCommunityVisitor?: Maybe<SignOutCommunityVisitorPayload>;
  readonly snoozeConversation?: Maybe<SnoozeConversationPayload>;
  readonly submitEmployerReference?: Maybe<SubmitEmployerReferencePayload>;
  readonly submitIdentityVerification?: Maybe<SubmitIdentityVerificationPayload>;
  readonly submitLandlordReference?: Maybe<SubmitLandlordReferencePayload>;
  readonly submitTenantReference?: Maybe<SubmitTenantReferencePayload>;
  readonly subscribeLandlord?: Maybe<SubscribeLandlordPayload>;
  readonly subscribeToNotificationGroup?: Maybe<SubscribeToNotificationGroup>;
  readonly tagConversation?: Maybe<TagConversationPayload>;
  readonly toggleCommunityUpdateBookmark?: Maybe<ToggleCommunityUpdateBookmarkPayload>;
  readonly toggleCommunityUpdateLike?: Maybe<ToggleCommunityUpdateLikePayload>;
  readonly toggleMarketplaceItemClose?: Maybe<ToggleMarketplaceItemClosePayload>;
  readonly unarchiveApplication?: Maybe<UnarchiveApplicationPayload>;
  readonly unarchiveKey?: Maybe<UnarchiveKeyPayload>;
  readonly unarchiveKeyholder?: Maybe<UnarchiveKeyholderPayload>;
  readonly unbookCommunityEvent?: Maybe<UnbookCommunityEventMutationPayload>;
  readonly unsnoozeConversation?: Maybe<UnsnoozeConversationPayload>;
  readonly unsubscribeFromNotificationGroup?: Maybe<UnsubscribeFromNotificationGroup>;
  readonly updateAttachment?: Maybe<UpdateAttachmentPayload>;
  readonly updateClientAdmin?: Maybe<UpdateClientAdminPayload>;
  readonly updateCommunityDocument?: Maybe<UpdateCommunityDocumentPayload>;
  readonly updateCommunityProduct?: Maybe<UpdateCommunityProductPayload>;
  readonly updateCommunitySettings?: Maybe<UpdateCommunitySettingsPayload>;
  readonly updateConversationTag?: Maybe<UpdateConversationTagPayload>;
  readonly updateConversationTitle?: Maybe<UpdateConversationTitlePayload>;
  readonly updateConversationUrgency?: Maybe<UpdateConversationUrgency>;
  readonly updateEmployerReference?: Maybe<UpdateEmployerReferencePayload>;
  readonly updateEmployerReferenceStep?: Maybe<UpdateEmployerReferenceStepPayload>;
  readonly updateGroup?: Maybe<UpdateGroupPayload>;
  readonly updateHomeAddress?: Maybe<UpdateHomeAddressPayload>;
  readonly updateHomeDetails?: Maybe<UpdateHomeDetailsPayload>;
  readonly updateKeyholder?: Maybe<UpdateKeyholderPayload>;
  readonly updateLandlord?: Maybe<UpdateLandlordPayload>;
  readonly updateLandlordReference?: Maybe<UpdateLandlordReferencePayload>;
  readonly updateLandlordReferenceComments?: Maybe<UpdateLandlordReferenceCommentsPayload>;
  readonly updateLandlordReferenceStep?: Maybe<UpdateLandlordReferenceStepPayload>;
  readonly updateManager?: Maybe<UpdateManagerPayload>;
  readonly updateManagerNotificationSettings?: Maybe<UpdateManagerNotificationSettingsPayload>;
  readonly updateMobileNumber?: Maybe<UpdateUserMobileNumberPayload>;
  readonly updatePackageLocation?: Maybe<UpdatePackageLocationPayload>;
  readonly updateReferenceAddressHistory?: Maybe<UpdateReferenceAddressHistoryPayload>;
  readonly updateReferenceStartedOn?: Maybe<UpdateReferenceStartedOnPayload>;
  readonly updateResidencyAndResidents?: Maybe<UpdateResidencyAndResidents>;
  readonly updateResidencyDocument?: Maybe<UpdateResidencyDocumentPayload>;
  readonly updateRightToRent?: Maybe<UpdateRightToRentPayload>;
  readonly updateTenantReference?: Maybe<UpdateTenantReferencePayload>;
  readonly updateTenantReferenceStep?: Maybe<UpdateTenantReferenceStepPayload>;
  readonly updateUser?: Maybe<UpdateUserPayload>;
  readonly upgradeLandlordToSubscription?: Maybe<UpgradeLandlordToSubscriptionPayload>;
};


export type MutationAcceptApplicationArgs = {
  application: Scalars['UUID']['input'];
  home?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationAcceptMessageSuggestionArgs = {
  input: AcceptMessageSuggestionInput;
};


export type MutationAcceptServiceChargeWaiverArgs = {
  input: AcceptServiceChargeWaiverInput;
};


export type MutationAcceptTermsAndConditionsArgs = {
  input: AcceptTermsAndConditionsInput;
};


export type MutationActivateGroupArgs = {
  input: ActivateGroupInput;
};


export type MutationAddApplicationCommunityArgs = {
  application: Scalars['UUID']['input'];
  community: Scalars['UUID']['input'];
};


export type MutationAddApplicationHomeArgs = {
  application: Scalars['ID']['input'];
  home: Scalars['UUID']['input'];
};


export type MutationAddApplicationOwnershipTypeArgs = {
  application: Scalars['ID']['input'];
  isResident: Scalars['Boolean']['input'];
  ownershipType: Scalars['String']['input'];
};


export type MutationAddDocumentSentStatusArgs = {
  application: Scalars['ID']['input'];
};


export type MutationAddFeatureToClientArgs = {
  input: AddFeatureToClientInput;
};


export type MutationAddMemberArgs = {
  input: AddMemberInput;
};


export type MutationAddPackageToHomeArgs = {
  input: AddPackageToHomeInput;
};


export type MutationAddUnknownCommunityStatusArgs = {
  application: Scalars['ID']['input'];
};


export type MutationArchiveApplicationArgs = {
  input: ArchiveApplicationInput;
};


export type MutationArchiveKeyArgs = {
  input: ArchiveKeyInput;
};


export type MutationArchiveKeyholderArgs = {
  input: ArchiveKeyholderInput;
};


export type MutationArchiveMarketplaceItemArgs = {
  input: ArchiveMarketplaceItemInput;
};


export type MutationAssociateAgreementWithClientArgs = {
  input: AssociateAgreementWithClientInput;
};


export type MutationAssociateSection21WithClientArgs = {
  input: AssociateSection21WithClientInput;
};


export type MutationBookCommunityEventArgs = {
  input: BookCommunityEventMutationInput;
};


export type MutationBookPaidCommunityEventArgs = {
  input: BookPaidCommunityEventMutationInput;
};


export type MutationCancelAmenityReservationArgs = {
  input: CancelAmenityReservationInput;
};


export type MutationCancelPaymentArgs = {
  input: CancelPaymentInput;
};


export type MutationCancelScheduledPublishCommunityUpdateArgs = {
  input: CancelScheduledPublishCommunityUpdateInput;
};


export type MutationCheckInKeyV2Args = {
  input: CheckInKeyV2Input;
};


export type MutationCheckOutKeyV2Args = {
  input: CheckOutKeyV2Input;
};


export type MutationCloseConversationArgs = {
  input: CloseConversationInput;
};


export type MutationCloseSurveyArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationCollectPackageArgs = {
  input: CollectPackageInput;
};


export type MutationCollectPackagesArgs = {
  input: CollectPackagesInput;
};


export type MutationCollectPackagesForHomeArgs = {
  input: CollectPackagesForHomeInput;
};


export type MutationConfirmResidencyWillRollArgs = {
  input: ConfirmResidencyWillRollInput;
};


export type MutationCountersignAgreementArgs = {
  input: CountersignAgreementInput;
};


export type MutationCreateAgreementForTenancyArgs = {
  input: CreateAgreementForTenancyInput;
};


export type MutationCreateAmenityReservationArgs = {
  input: CreateAmenityReservationMutationInput;
};


export type MutationCreateApplicantAndApplicationArgs = {
  input: CreateApplicantAndApplicationInput;
};


export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};


export type MutationCreateAttachmentOnHomeArgs = {
  input: CreateAttachmentOnHomeInput;
};


export type MutationCreateAttachmentOnRequirementItemArgs = {
  input: CreateAttachmentOnRequirementItemInput;
};


export type MutationCreateBlockComplianceRequirementItemArgs = {
  input: CreateBlockComplianceRequirementItemInput;
};


export type MutationCreateBlocksArgs = {
  blockNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  communityId: Scalars['UUID']['input'];
};


export type MutationCreateBlocksComplianceRequirementArgs = {
  input: CreateBlocksComplianceRequirementInput;
};


export type MutationCreateCheckoutLinkArgs = {
  input: CreateCheckoutLinkInput;
};


export type MutationCreateCommunityDocumentArgs = {
  input: CreateCommunityDocumentInput;
};


export type MutationCreateCommunityDocumentViewArgs = {
  input: CreateCommunityDocumentViewInput;
};


export type MutationCreateCommunityProductArgs = {
  input: CreateCommunityProductInput;
};


export type MutationCreateCommunityProductPaymentArgs = {
  input: CreateCommunityProductPaymentInput;
};


export type MutationCreateCommunityUpdateReadArgs = {
  input: CreateCommunityUpdateReadInput;
};


export type MutationCreateCommunityVisitorArgs = {
  input: CreateCommunityVisitorInput;
};


export type MutationCreateComplianceRequirementArgs = {
  input: CreateComplianceRequirementInput;
};


export type MutationCreateComplianceUpdateReadArgs = {
  input: CreateComplianceUpdateReadInput;
};


export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
  sender: ConversationRole;
};


export type MutationCreateConversationActionArgs = {
  input: CreateConversationActionInput;
};


export type MutationCreateConversationAndFirstMessageArgs = {
  input: CreateConversationAndFirstMessageInput;
};


export type MutationCreateConversationMessageArgs = {
  input: CreateConversationMessageInput;
  sender: ConversationRole;
};


export type MutationCreateConversationTagArgs = {
  input: CreateConversationTagInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateGroupChatMessageArgs = {
  input: CreateGroupChatMessageInput;
};


export type MutationCreateKeyV2Args = {
  input: CreateKeyV2Input;
};


export type MutationCreateKeyholderV2Args = {
  input: CreateKeyholderV2Input;
};


export type MutationCreateManagerConversationReadArgs = {
  input: CreateManagerConversationReadInput;
};


export type MutationCreateMarketplaceItemCommentArgs = {
  input: CreateMarketplaceItemCommentInput;
};


export type MutationCreateNewDeviceArgs = {
  input: CreateNewDeviceInput;
};


export type MutationCreateOccupancySurveyArgs = {
  isOccupant: Scalars['Boolean']['input'];
  occupants?: InputMaybe<ReadonlyArray<InputMaybe<OccupantInput>>>;
  residencyId: Scalars['UUID']['input'];
};


export type MutationCreateOpenedConversationArgs = {
  input: CreateOpenedConversationInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreateResidencyAndResidentsArgs = {
  input: ResidencyAndResidentsInput;
};


export type MutationCreateResidencyDocumentArgs = {
  input: CreateResidencyDocumentInput;
};


export type MutationCreateResidencyDocumentViewArgs = {
  input: CreateResidencyDocumentViewInput;
};


export type MutationCreateResidencyDocumentsArgs = {
  input: CreateResidencyDocumentsInput;
};


export type MutationCreateResidencySubscriptionArgs = {
  input: CreateResidencySubscriptionInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationCreateTenancyApplicationArgs = {
  input: CreateTenancyApplicationInput;
};


export type MutationCreateUpdateCommentArgs = {
  input: CreateUpdateCommentInput;
};


export type MutationDeactivateClientUserArgs = {
  input: DeactivateClientUserInput;
};


export type MutationDeactivateGroupArgs = {
  input: DeactivateGroupInput;
};


export type MutationDeleteAmenityArgs = {
  input: DeleteAmenityInput;
};


export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput;
};


export type MutationDeleteCommunityDocumentArgs = {
  input: DeleteCommunityDocumentInput;
};


export type MutationDeleteCommunityProductArgs = {
  input: DeleteCommunityProductInput;
};


export type MutationDeleteCommunityUpdateArgs = {
  input: DeleteCommunityUpdateInput;
};


export type MutationDeleteComplianceRequirementV2Args = {
  input: DeleteComplianceRequirementV2Input;
};


export type MutationDeleteConversationTagArgs = {
  input: DeleteConversationTagInput;
};


export type MutationDeleteHomeArgs = {
  input: DeleteHomeInput;
};


export type MutationDeleteHomeImageArgs = {
  input: DeleteHomeImageInput;
};


export type MutationDeleteMarketplaceItemImageArgs = {
  input: DeleteMarketplaceItemImageInput;
};


export type MutationDeleteUpdateCommentArgs = {
  input: DeleteCommunityUpdateCommentInput;
};


export type MutationEditKeyArgs = {
  input: EditKeyInput;
};


export type MutationEditMemberArgs = {
  input: EditMemberInput;
};


export type MutationEmployerUpdateEmploymentDetailsArgs = {
  input: EmployerUpdateEmploymentDetailsInput;
};


export type MutationEmployerUpdatePersonalDetailsArgs = {
  input: EmployerUpdatePersonalDetailsInput;
};


export type MutationFakeCreditCheckArgs = {
  input: FakeCreditCheckMutationInput;
};


export type MutationFakeSendTenantReferenceRequestArgs = {
  input: FakeSendTenantReferenceRequestInput;
};


export type MutationFakeStripeInvoicePaidEventArgs = {
  input: FakeStripeInvoicePaidEventInput;
};


export type MutationIgnoreMessageSuggestionArgs = {
  input: IgnoreMessageSuggestionInput;
};


export type MutationInitializeSignupArgs = {
  acceptedMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  suppressWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationInviteDemoUserArgs = {
  input: InviteDemoUserInput;
};


export type MutationLandlordUpdateLandlordReferenceArgs = {
  input: LandlordUpdateLandlordReferenceInput;
};


export type MutationLandlordUpdateRentalDetailsArgs = {
  input: LandlordUpdateRentalDetailsInput;
};


export type MutationLogDocumentReadArgs = {
  input: LogDocumentReadInput;
};


export type MutationLogPublicDocumentReadArgs = {
  input: LogPublicDocumentReadInput;
};


export type MutationLogUserAppUseArgs = {
  input: LogUserAppUseInput;
};


export type MutationManagerAddApplicationHomeArgs = {
  input: ManagerAddApplicationHomeInput;
};


export type MutationManagerCancelAmenityReservationArgs = {
  input: ManagerCancelAmenityReservationInput;
};


export type MutationManagerCreateAmenityReservationArgs = {
  input: ManagerCreateAmenityReservationInput;
};


export type MutationManagerReserveAmenityArgs = {
  input: ManagerReserveAmenityInput;
};


export type MutationOfficiallyVerifyRightToRentArgs = {
  input: OfficiallyVerifyRightToRentInput;
};


export type MutationOrderConversationTagsArgs = {
  input: OrderConversationTagsInput;
};


export type MutationPackageReminderToAllArgs = {
  communities: ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>;
};


export type MutationPackageReminderToHomeArgs = {
  input: PackageReminderToHomeInput;
};


export type MutationPayForDocumentArgs = {
  input: PayForDocumentInput;
};


export type MutationPayForUnpaidApplicationReferencesArgs = {
  input: PayForUnpaidApplicationReferencesInput;
};


export type MutationPublishCommunityUpdateArgs = {
  input: PublishCommunityUpdateInput;
};


export type MutationRateConversationArgs = {
  input: RateConversationInput;
};


export type MutationReOpenConversationArgs = {
  input: ReOpenConversationInput;
};


export type MutationReadAllNotificationsArgs = {
  input: ReadAllNotificationsInput;
};


export type MutationReadAllNotificationsOnTargetArgs = {
  input: ReadAllNotificationsOnTargetInput;
};


export type MutationReadNotificationArgs = {
  input: ReadNotificationInput;
};


export type MutationReferenceSignupErrorArgs = {
  input: ReferenceSignupErrorInput;
};


export type MutationRejectApplicationArgs = {
  application: Scalars['UUID']['input'];
  rejectionReason: Scalars['String']['input'];
};


export type MutationRemindAllApplicantsArgs = {
  communities: ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>;
};


export type MutationRemindApplicantArgs = {
  application: Scalars['UUID']['input'];
};


export type MutationRemindResidentArgs = {
  input: RemindResidentInput;
};


export type MutationRemoveConversationTagArgs = {
  input: RemoveConversationTagInput;
};


export type MutationRemoveResidentFromUnpaidEventsArgs = {
  input: RemoveResidentFromUnpaidEventsInput;
};


export type MutationResendMemberInviteEmailArgs = {
  input: ResendMemberInviteEmailInput;
};


export type MutationReserveAmenityArgs = {
  input: ReserveAmenityInput;
};


export type MutationResidentArchiveKeyholderArgs = {
  input: ResidentArchiveKeyholderInput;
};


export type MutationResidentCreateKeyholderArgs = {
  input: ResidentCreateKeyholderInput;
};


export type MutationResidentUnarchiveKeyholderArgs = {
  input: ResidentUnarchiveKeyholderInput;
};


export type MutationResidentUpdateKeyholderArgs = {
  input: ResidentUpdateKeyholderInput;
};


export type MutationRestartIdentityVerificationArgs = {
  input: RestartIdentityVerificationInput;
};


export type MutationRevokeGeneratedAgreementArgs = {
  input: RevokeGeneratedAgreementInput;
};


export type MutationSaveAmenityArgs = {
  input: SaveAmenityInput;
};


export type MutationSaveCommunityEventArgs = {
  input: SaveCommunityEventInput;
};


export type MutationSaveCommunityUpdateArgs = {
  input: SaveCommunityUpdateInput;
};


export type MutationSaveGeneratedAgreementArgs = {
  input: SaveGeneratedAgreementInput;
};


export type MutationSaveGeneratedSection21Args = {
  input: SaveGeneratedSection21Input;
};


export type MutationSaveHomeArgs = {
  input: SaveHomeInput;
};


export type MutationSaveManagerProfileArgs = {
  input: SaveManagerProfileInput;
};


export type MutationSaveMarketplaceItemArgs = {
  input: SaveMarketplaceItemInput;
};


export type MutationSaveRequirementItemArgs = {
  input: SaveRequirementItemInput;
};


export type MutationSaveResidentSurveyAnswerArgs = {
  input: SaveResidentSurveyAnswerInput;
};


export type MutationSaveTenantEmploymentDetailsArgs = {
  input: SaveTenantEmploymentDetailsInput;
};


export type MutationSchedulePublishCommunityUpdateArgs = {
  input: SchedulePublishCommunityUpdateInput;
};


export type MutationSendAgreementForSigningArgs = {
  input: SendAgreementForSigningInput;
};


export type MutationSendDocumentArgs = {
  input: SendDocumentInput;
};


export type MutationSendManagerAmplitudeTestArgs = {
  input: SendManagerAmplitudeTestInput;
};


export type MutationSendTenantReferenceRequestsArgs = {
  input: SendTenantReferenceRequestsInput;
};


export type MutationSetResidencyCompletionDateArgs = {
  input: SetResidencyCompletionDateInput;
};


export type MutationSetUpManagerArgs = {
  input: SetUpManagerInput;
};


export type MutationShareComplianceRequirementArgs = {
  input: ShareComplianceRequirementInput;
};


export type MutationSignOutCommunityVisitorArgs = {
  input: SignOutCommunityVisitorInput;
};


export type MutationSnoozeConversationArgs = {
  input: SnoozeConversationInput;
};


export type MutationSubmitEmployerReferenceArgs = {
  input: SubmitEmployerReferenceInput;
};


export type MutationSubmitIdentityVerificationArgs = {
  input: SubmitIdentityVerificationInput;
};


export type MutationSubmitLandlordReferenceArgs = {
  input: SubmitLandlordReferenceInput;
};


export type MutationSubmitTenantReferenceArgs = {
  input: SubmitTenantReferenceInput;
};


export type MutationSubscribeLandlordArgs = {
  input: SubscribeLandlordInput;
};


export type MutationSubscribeToNotificationGroupArgs = {
  input: NotificationPreferencesInput;
};


export type MutationTagConversationArgs = {
  input: TagConversationInput;
};


export type MutationToggleCommunityUpdateBookmarkArgs = {
  input: ToggleCommunityUpdateBookmarkInput;
};


export type MutationToggleCommunityUpdateLikeArgs = {
  input: ToggleCommunityUpdateLikeInput;
};


export type MutationToggleMarketplaceItemCloseArgs = {
  input: ToggleMarketplaceItemCloseInput;
};


export type MutationUnarchiveApplicationArgs = {
  input: UnarchiveApplicationInput;
};


export type MutationUnarchiveKeyArgs = {
  input: UnarchiveKeyInput;
};


export type MutationUnarchiveKeyholderArgs = {
  input: UnarchiveKeyholderInput;
};


export type MutationUnbookCommunityEventArgs = {
  input: UnbookCommunityEventMutationInput;
};


export type MutationUnsnoozeConversationArgs = {
  input: UnsnoozeConversationInput;
};


export type MutationUnsubscribeFromNotificationGroupArgs = {
  input: NotificationPreferencesInput;
};


export type MutationUpdateAttachmentArgs = {
  input: UpdateAttachmentInput;
};


export type MutationUpdateClientAdminArgs = {
  input: UpdateClientAdminInput;
};


export type MutationUpdateCommunityDocumentArgs = {
  input: UpdateCommunityDocumentInput;
};


export type MutationUpdateCommunityProductArgs = {
  input: UpdateCommunityProductInput;
};


export type MutationUpdateCommunitySettingsArgs = {
  input: UpdateCommunitySettingsInput;
};


export type MutationUpdateConversationTagArgs = {
  input: UpdateConversationTagInput;
};


export type MutationUpdateConversationTitleArgs = {
  input: UpdateConversationTitleInput;
};


export type MutationUpdateConversationUrgencyArgs = {
  input: UpdateConversationUrgencyInput;
};


export type MutationUpdateEmployerReferenceArgs = {
  input: UpdateEmployerReferenceInput;
};


export type MutationUpdateEmployerReferenceStepArgs = {
  input: UpdateEmployerReferenceStepInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateHomeAddressArgs = {
  input: UpdateHomeAddressInput;
};


export type MutationUpdateHomeDetailsArgs = {
  input: UpdateHomeDetailsInput;
};


export type MutationUpdateKeyholderArgs = {
  input: UpdateKeyholderInput;
};


export type MutationUpdateLandlordArgs = {
  input: UpdateLandlordInput;
};


export type MutationUpdateLandlordReferenceArgs = {
  input: UpdateLandlordReferenceInput;
};


export type MutationUpdateLandlordReferenceCommentsArgs = {
  input: UpdateLandlordReferenceCommentsInput;
};


export type MutationUpdateLandlordReferenceStepArgs = {
  input: UpdateLandlordReferenceStepInput;
};


export type MutationUpdateManagerArgs = {
  input: UpdateManagerInput;
};


export type MutationUpdateManagerNotificationSettingsArgs = {
  input: UpdateManagerNotificationSettingsInput;
};


export type MutationUpdateMobileNumberArgs = {
  input: UpdateUserMobileNumberInput;
};


export type MutationUpdatePackageLocationArgs = {
  input: UpdatePackageLocationInput;
};


export type MutationUpdateReferenceAddressHistoryArgs = {
  input: UpdateReferenceAddressHistoryInput;
};


export type MutationUpdateReferenceStartedOnArgs = {
  input: UpdateReferenceStartedOnInput;
};


export type MutationUpdateResidencyAndResidentsArgs = {
  input: ResidencyAndResidentsInput;
};


export type MutationUpdateResidencyDocumentArgs = {
  input: UpdateResidencyDocumentInput;
};


export type MutationUpdateRightToRentArgs = {
  input: UpdateRightToRentInput;
};


export type MutationUpdateTenantReferenceArgs = {
  input: UpdateTenantReferenceInput;
};


export type MutationUpdateTenantReferenceStepArgs = {
  input: UpdateTenantReferenceStepInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpgradeLandlordToSubscriptionArgs = {
  input: UpgradeLandlordToSubscriptionInput;
};

export type NotificationContentObjectType = ConversationType | ResidencyType;

export type NotificationNode = {
  readonly __typename?: 'NotificationNode';
  readonly actionObject?: Maybe<ActionObject>;
  readonly actor?: Maybe<Actor>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly read: Scalars['Boolean']['output'];
  readonly recipient: UserType;
  readonly target?: Maybe<Target>;
  readonly title: Scalars['String']['output'];
  readonly verb?: Maybe<Scalars['String']['output']>;
};

export type NotificationNodeConnection = {
  readonly __typename?: 'NotificationNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<NotificationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `NotificationNode` and its cursor. */
export type NotificationNodeEdge = {
  readonly __typename?: 'NotificationNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<NotificationNode>;
};

export type NotificationPreferencesInput = {
  readonly type: Scalars['String']['input'];
};

export type OccupancySurveyNode = {
  readonly __typename?: 'OccupancySurveyNode';
  readonly id: Scalars['UUID']['output'];
  readonly isOccupant: Scalars['Boolean']['output'];
  readonly occupants: OccupantNodeConnection;
  readonly residency: ResidencyType;
  readonly user: UserType;
};


export type OccupancySurveyNodeOccupantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OccupancySurveyNodeConnection = {
  readonly __typename?: 'OccupancySurveyNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<OccupancySurveyNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `OccupancySurveyNode` and its cursor. */
export type OccupancySurveyNodeEdge = {
  readonly __typename?: 'OccupancySurveyNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<OccupancySurveyNode>;
};

export type OccupantInput = {
  readonly communicationNeeds?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly first: Scalars['String']['input'];
  readonly last: Scalars['String']['input'];
  readonly mobilityNeeds?: InputMaybe<Scalars['String']['input']>;
  readonly userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type OccupantNode = {
  readonly __typename?: 'OccupantNode';
  readonly communicationNeeds?: Maybe<Scalars['String']['output']>;
  readonly first: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly last: Scalars['String']['output'];
  readonly mobilityNeeds?: Maybe<Scalars['String']['output']>;
  readonly occupancySurvey: OccupancySurveyNode;
  readonly user?: Maybe<UserType>;
};

export type OccupantNodeConnection = {
  readonly __typename?: 'OccupantNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<OccupantNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `OccupantNode` and its cursor. */
export type OccupantNodeEdge = {
  readonly __typename?: 'OccupantNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<OccupantNode>;
};

export type OfficiallyVerifyRightToRentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly officialExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  readonly officialVerificationStatus?: InputMaybe<Scalars['String']['input']>;
};

export type OfficiallyVerifyRightToRentPayload = {
  readonly __typename?: 'OfficiallyVerifyRightToRentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly officialExpiryDate?: Maybe<Scalars['Date']['output']>;
  readonly officialVerificationStatus?: Maybe<Scalars['String']['output']>;
};

export type OrderConversationTagsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversationId: Scalars['UUID']['input'];
};

export type OrderConversationTagsPayload = {
  readonly __typename?: 'OrderConversationTagsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<ConversationNode>;
};

export type PackageNode = {
  readonly __typename?: 'PackageNode';
  readonly addressee?: Maybe<Scalars['String']['output']>;
  readonly collectedBy?: Maybe<UserType>;
  readonly collectedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly image?: Maybe<Scalars['String']['output']>;
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly lastAction: Scalars['DateTime']['output'];
  readonly location?: Maybe<Scalars['String']['output']>;
  readonly reminders?: Maybe<ReadonlyArray<PackageReminderNode>>;
  readonly residency: ResidencyType;
  readonly resident?: Maybe<ResidentType>;
  readonly scannedInBy?: Maybe<UserType>;
  readonly status: ResidentialManagementPackageStatusChoices;
};

export type PackageNodeConnection = {
  readonly __typename?: 'PackageNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PackageNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `PackageNode` and its cursor. */
export type PackageNodeEdge = {
  readonly __typename?: 'PackageNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<PackageNode>;
};

export type PackageReminderNode = {
  readonly __typename?: 'PackageReminderNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly user: UserType;
};

export type PackageReminderToAll = {
  readonly __typename?: 'PackageReminderToAll';
  readonly packages?: Maybe<ReadonlyArray<Maybe<PackageNode>>>;
};

export type PackageReminderToHomeInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type PackageReminderToHomePayload = {
  readonly __typename?: 'PackageReminderToHomePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly home?: Maybe<HomeType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  readonly __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']['output']>;
};

export type PayForDocumentInput = {
  readonly agreementId?: InputMaybe<Scalars['ID']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly section21Id?: InputMaybe<Scalars['ID']['input']>;
  readonly type: Scalars['String']['input'];
};

export type PayForDocumentPayload = {
  readonly __typename?: 'PayForDocumentPayload';
  readonly amount?: Maybe<Scalars['Float']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly invoice?: Maybe<InvoiceNode>;
  readonly stripeClientSecret?: Maybe<Scalars['String']['output']>;
};

export type PayForUnpaidApplicationReferencesInput = {
  readonly applicationUuid: Scalars['UUID']['input'];
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type PayForUnpaidApplicationReferencesPayload = {
  readonly __typename?: 'PayForUnpaidApplicationReferencesPayload';
  readonly amount?: Maybe<Scalars['Float']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly stripeClientSecret?: Maybe<Scalars['String']['output']>;
};

export type PaymentAccountNode = {
  readonly __typename?: 'PaymentAccountNode';
  readonly balance: Scalars['Decimal']['output'];
  readonly balanceLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  readonly home?: Maybe<HomeNode>;
  readonly id: Scalars['UUID']['output'];
  readonly lastPayment?: Maybe<PaymentNode>;
  readonly leasehold?: Maybe<LeaseholdNode>;
  readonly payments?: Maybe<PaymentNodeConnection>;
  readonly residency?: Maybe<ResidencyNode>;
  readonly unreconciledPaymentsTotal?: Maybe<Scalars['Float']['output']>;
};


export type PaymentAccountNodePaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type PaymentAccountNodeConnection = {
  readonly __typename?: 'PaymentAccountNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PaymentAccountNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `PaymentAccountNode` and its cursor. */
export type PaymentAccountNodeEdge = {
  readonly __typename?: 'PaymentAccountNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<PaymentAccountNode>;
};

export type PaymentIntentType = {
  readonly __typename?: 'PaymentIntentType';
  readonly clientSecret?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
};

export type PaymentMethod = {
  readonly __typename?: 'PaymentMethod';
  readonly bacsDebit?: Maybe<BacsPaymentType>;
  readonly card?: Maybe<CardPaymentType>;
  readonly type: Scalars['String']['output'];
};

export type PaymentNode = {
  readonly __typename?: 'PaymentNode';
  readonly amount: Scalars['BigInt']['output'];
  readonly clientSecret: Scalars['String']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly paymentIntentId: Scalars['String']['output'];
  readonly status: PaymentsPaymentStatusChoices;
  readonly type: PaymentsPaymentTypeChoices;
};

export type PaymentNodeConnection = {
  readonly __typename?: 'PaymentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<PaymentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `PaymentNode` and its cursor. */
export type PaymentNodeEdge = {
  readonly __typename?: 'PaymentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<PaymentNode>;
};

/** An enumeration. */
export enum PaymentsPaymentStatusChoices {
  /** Canceled */
  Ca = 'CA',
  /** Created */
  Cr = 'CR',
  /** Payment failed */
  Fa = 'FA',
  /** Partially funded */
  Pf = 'PF',
  /** Processing */
  Pr = 'PR',
  /** Requires action */
  Ra = 'RA',
  /** Succeeded */
  Su = 'SU'
}

/** An enumeration. */
export enum PaymentsPaymentTypeChoices {
  /** AST trial */
  At = 'AT',
  /** Community event */
  Ce = 'CE',
  /** Community product */
  Cp = 'CP',
  /** Deposit */
  De = 'DE',
  /** Payment account */
  Pa = 'PA'
}

/** An enumeration. */
export enum PeopleClientClientTypeChoices {
  /** Buy to let */
  Btl = 'BTL',
  /** Enterprise */
  Ent = 'ENT'
}

/** An enumeration. */
export enum PeopleUnsubscribeNotificationGroupTypeChoices {
  /** RESIDENT_APP_AMENITY */
  ResidentAppAmenity = 'RESIDENT_APP_AMENITY',
  /** RESIDENT_APP_COMMUNITY_PRODUCT */
  ResidentAppCommunityProduct = 'RESIDENT_APP_COMMUNITY_PRODUCT',
  /** RESIDENT_APP_CONVERSATION */
  ResidentAppConversation = 'RESIDENT_APP_CONVERSATION',
  /** RESIDENT_APP_DOCUMENT */
  ResidentAppDocument = 'RESIDENT_APP_DOCUMENT',
  /** RESIDENT_APP_EVENT */
  ResidentAppEvent = 'RESIDENT_APP_EVENT',
  /** RESIDENT_APP_GROUP_CHAT */
  ResidentAppGroupChat = 'RESIDENT_APP_GROUP_CHAT',
  /** RESIDENT_APP_KEY */
  ResidentAppKey = 'RESIDENT_APP_KEY',
  /** RESIDENT_APP_MARKETPLACE */
  ResidentAppMarketplace = 'RESIDENT_APP_MARKETPLACE',
  /** RESIDENT_APP_NEWSFEED */
  ResidentAppNewsfeed = 'RESIDENT_APP_NEWSFEED',
  /** RESIDENT_APP_PACKAGE */
  ResidentAppPackage = 'RESIDENT_APP_PACKAGE'
}

export type ProductCosts = {
  readonly __typename?: 'ProductCosts';
  readonly astCost?: Maybe<Scalars['Float']['output']>;
  readonly s21Cost?: Maybe<Scalars['Float']['output']>;
};

export enum ProfileHomesEnum {
  Application = 'application',
  Leasehold = 'leasehold',
  Residency = 'residency'
}

export type ProfileType = {
  readonly __typename?: 'ProfileType';
  readonly homes: ReadonlyArray<HomeNode>;
  readonly inProgressApplication?: Maybe<ApplicationNode>;
};


export type ProfileTypeHomesArgs = {
  userLinkType: ProfileHomesEnum;
};

export type PublicResidencyDocumentType = {
  readonly __typename?: 'PublicResidencyDocumentType';
  readonly id: Scalars['UUID']['output'];
};

export type PublishCommunityUpdateInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
};

export type PublishCommunityUpdatePayload = {
  readonly __typename?: 'PublishCommunityUpdatePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityUpdate?: Maybe<CommunityUpdateType>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type RateConversationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly conversation: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly rating: Scalars['Int']['input'];
};

export type RateConversationPayload = {
  readonly __typename?: 'RateConversationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly rating?: Maybe<Scalars['Int']['output']>;
};

export type ReOpenConversationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversationId: Scalars['UUID']['input'];
};

export type ReOpenConversationPayload = {
  readonly __typename?: 'ReOpenConversationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<ConversationType>;
};

export type ReadAllNotificationsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type ReadAllNotificationsOnTargetInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
};

export type ReadAllNotificationsOnTargetPayload = {
  readonly __typename?: 'ReadAllNotificationsOnTargetPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly notifications: NotificationNodeConnection;
};


export type ReadAllNotificationsOnTargetPayloadNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReadAllNotificationsPayload = {
  readonly __typename?: 'ReadAllNotificationsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type ReadNotificationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ReadNotificationPayload = {
  readonly __typename?: 'ReadNotificationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly notification?: Maybe<NotificationNode>;
};

export type ReferenceHistoricalAddressNode = {
  readonly __typename?: 'ReferenceHistoricalAddressNode';
  readonly buildingName?: Maybe<Scalars['String']['output']>;
  readonly buildingNumber?: Maybe<Scalars['String']['output']>;
  readonly city: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly fullAddress?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly postcode: Scalars['String']['output'];
  readonly startDate: Scalars['Date']['output'];
  readonly street?: Maybe<Scalars['String']['output']>;
};

export type ReferenceHistoricalAddressNodeConnection = {
  readonly __typename?: 'ReferenceHistoricalAddressNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ReferenceHistoricalAddressNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ReferenceHistoricalAddressNode` and its cursor. */
export type ReferenceHistoricalAddressNodeEdge = {
  readonly __typename?: 'ReferenceHistoricalAddressNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ReferenceHistoricalAddressNode>;
};

export type ReferenceHistoricalAddressSerializerInput = {
  readonly buildingName?: InputMaybe<Scalars['String']['input']>;
  readonly buildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly postcode: Scalars['String']['input'];
  readonly startDate: Scalars['Date']['input'];
  readonly street?: InputMaybe<Scalars['String']['input']>;
};

export type ReferenceSignupErrorInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
};

export type ReferenceSignupErrorPayload = {
  readonly __typename?: 'ReferenceSignupErrorPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RejectApplication = {
  readonly __typename?: 'RejectApplication';
  readonly application?: Maybe<ApplicationNode>;
};

export type RemindAllApplicants = {
  readonly __typename?: 'RemindAllApplicants';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemindApplicant = {
  readonly __typename?: 'RemindApplicant';
  readonly application?: Maybe<ApplicationNode>;
};

export type RemindResidentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type RemindResidentPayload = {
  readonly __typename?: 'RemindResidentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type RemoveConversationTagInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversationId: Scalars['UUID']['input'];
  readonly tagId: Scalars['UUID']['input'];
};

export type RemoveConversationTagPayload = {
  readonly __typename?: 'RemoveConversationTagPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversationTag?: Maybe<ConversationTagNode>;
};

export type RemoveResidentFromUnpaidEventsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveResidentFromUnpaidEventsPayload = {
  readonly __typename?: 'RemoveResidentFromUnpaidEventsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RequirementItemNode = {
  readonly __typename?: 'RequirementItemNode';
  readonly attachment?: Maybe<LandlordAttachmentType>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  readonly expiryDate?: Maybe<Scalars['Date']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly requirement: ComplianceRequirementNode;
  readonly status?: Maybe<Scalars['String']['output']>;
};

export type RequirementItemNodeConnection = {
  readonly __typename?: 'RequirementItemNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<RequirementItemNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `RequirementItemNode` and its cursor. */
export type RequirementItemNodeEdge = {
  readonly __typename?: 'RequirementItemNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<RequirementItemNode>;
};

export type ResendMemberInviteEmailInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['String']['input'];
};

export type ResendMemberInviteEmailPayload = {
  readonly __typename?: 'ResendMemberInviteEmailPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly clientUser?: Maybe<ClientUserNode>;
};

export type ReserveAmenityInput = {
  readonly amenity?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly end: Scalars['DateTime']['input'];
  readonly start: Scalars['DateTime']['input'];
};

export type ReserveAmenityPayload = {
  readonly __typename?: 'ReserveAmenityPayload';
  readonly amenityReservation?: Maybe<AmenityReservationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type ResidencyAndResidentsInput = {
  readonly depositAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly endDate?: InputMaybe<Scalars['Date']['input']>;
  readonly homeId?: InputMaybe<Scalars['UUID']['input']>;
  readonly paymentFrequency: Scalars['String']['input'];
  readonly paymentStartDate?: InputMaybe<Scalars['Date']['input']>;
  readonly rentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly residencyId?: InputMaybe<Scalars['UUID']['input']>;
  readonly sendTenantWelcomeEmail?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startDate?: InputMaybe<Scalars['Date']['input']>;
  readonly tenants?: InputMaybe<ReadonlyArray<InputMaybe<Tenants>>>;
};

export type ResidencyDocumentNode = {
  readonly __typename?: 'ResidencyDocumentNode';
  readonly activatedOn?: Maybe<Scalars['DateTime']['output']>;
  readonly community: CommunityNode;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['UUID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly link?: Maybe<Scalars['String']['output']>;
  readonly residency: ResidencyType;
  readonly title: Scalars['String']['output'];
  readonly url?: Maybe<Scalars['String']['output']>;
  readonly views: ResidencyDocumentViewNodeConnection;
};


export type ResidencyDocumentNodeViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ResidencyDocumentNodeConnection = {
  readonly __typename?: 'ResidencyDocumentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ResidencyDocumentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ResidencyDocumentNode` and its cursor. */
export type ResidencyDocumentNodeEdge = {
  readonly __typename?: 'ResidencyDocumentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ResidencyDocumentNode>;
};

export type ResidencyDocumentViewNode = {
  readonly __typename?: 'ResidencyDocumentViewNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly user: UserType;
};

export type ResidencyDocumentViewNodeConnection = {
  readonly __typename?: 'ResidencyDocumentViewNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ResidencyDocumentViewNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ResidencyDocumentViewNode` and its cursor. */
export type ResidencyDocumentViewNodeEdge = {
  readonly __typename?: 'ResidencyDocumentViewNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ResidencyDocumentViewNode>;
};

export type ResidencyNode = {
  readonly __typename?: 'ResidencyNode';
  readonly completionDate?: Maybe<Scalars['Date']['output']>;
  readonly complianceRequirements: ComplianceRequirementNodeConnection;
  readonly depositAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly depositPayment?: Maybe<PaymentNode>;
  readonly documents: ResidencyDocumentNodeConnection;
  readonly endDate: Scalars['Date']['output'];
  readonly externalId?: Maybe<Scalars['String']['output']>;
  readonly generatedAgreements: GeneratedAgreementNodeConnection;
  readonly home: HomeType;
  readonly id: Scalars['UUID']['output'];
  readonly occupancySurveys: OccupancySurveyNodeConnection;
  readonly packages: PackageNodeConnection;
  readonly paymentFrequency: Scalars['String']['output'];
  readonly paymentStartDate?: Maybe<Scalars['Date']['output']>;
  readonly publicDocuments: ReadonlyArray<PublicResidencyDocumentType>;
  readonly rentAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly residents: ReadonlyArray<ResidentType>;
  readonly rollingConfirmed: Scalars['Boolean']['output'];
  readonly stage?: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['Date']['output'];
  readonly subscription?: Maybe<SubscriptionNode>;
  readonly type?: Maybe<Scalars['String']['output']>;
};


export type ResidencyNodeComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type ResidencyNodeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidencyNodeGeneratedAgreementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidencyNodeOccupancySurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isOccupant?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type ResidencyNodePackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type ResidencyNodeConnection = {
  readonly __typename?: 'ResidencyNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ResidencyNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ResidencyNode` and its cursor. */
export type ResidencyNodeEdge = {
  readonly __typename?: 'ResidencyNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ResidencyNode>;
};

export type ResidencyType = {
  readonly __typename?: 'ResidencyType';
  readonly completionDate?: Maybe<Scalars['Date']['output']>;
  readonly complianceRequirements: ComplianceRequirementNodeConnection;
  readonly conversations?: Maybe<ReadonlyArray<ConversationType>>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly depositAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly documents: ResidencyDocumentNodeConnection;
  readonly emergencyNumber?: Maybe<Scalars['String']['output']>;
  readonly endDate: Scalars['Date']['output'];
  readonly generatedAgreements: GeneratedAgreementNodeConnection;
  readonly home: HomeType;
  readonly howToRentShared: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly notifications?: Maybe<ReadonlyArray<Maybe<ManagerNotificationType>>>;
  readonly occupancySurveys: OccupancySurveyNodeConnection;
  readonly packages?: Maybe<PackageNodeConnection>;
  readonly paymentAccount?: Maybe<PaymentAccountNode>;
  readonly paymentFrequency: Scalars['String']['output'];
  readonly paymentStartDate?: Maybe<Scalars['Date']['output']>;
  readonly publicDocuments: ReadonlyArray<PublicResidencyDocumentType>;
  readonly rentAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly residents: ReadonlyArray<ResidentType>;
  readonly rollingConfirmed: Scalars['Boolean']['output'];
  readonly stage?: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['Date']['output'];
  readonly subscription?: Maybe<SubscriptionNode>;
  readonly tenancyapplicationSet: TenancyApplicationNodeConnection;
  readonly updatedAt: Scalars['DateTime']['output'];
};


export type ResidencyTypeComplianceRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


export type ResidencyTypeConversationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidencyTypeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidencyTypeGeneratedAgreementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidencyTypeOccupancySurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isOccupant?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};


export type ResidencyTypePackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type ResidencyTypeTenancyapplicationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasBeenSent?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type ResidentArchiveKeyholderInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ResidentArchiveKeyholderPayload = {
  readonly __typename?: 'ResidentArchiveKeyholderPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly keyholder?: Maybe<KeyholderNode>;
};

export type ResidentCreateKeyholder = {
  readonly __typename?: 'ResidentCreateKeyholder';
  readonly keyholder?: Maybe<KeyholderNode>;
};

export type ResidentCreateKeyholderInput = {
  readonly company?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly endDate?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly startDate: Scalars['String']['input'];
};

export type ResidentNode = {
  readonly __typename?: 'ResidentNode';
  readonly avatar?: Maybe<Scalars['String']['output']>;
  readonly averageScore?: Maybe<Scalars['Float']['output']>;
  readonly conversation?: Maybe<ConversationNodeConnection>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly currentResidencies?: Maybe<ReadonlyArray<ResidencyType>>;
  readonly dateOfBirth?: Maybe<Scalars['String']['output']>;
  readonly documents: ResidencyDocumentNodeConnection;
  readonly email: Scalars['String']['output'];
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly hasAcceptedArkTerms: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly isLeaseholder?: Maybe<Scalars['Boolean']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly packages: PackageNodeConnection;
  readonly residencies: ReadonlyArray<ResidencyType>;
  readonly surveyAnswers: ReadonlyArray<ResidentSurveyAnswerType>;
  readonly tenancyapplicantSet: TenancyApplicantNodeConnection;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user: UserType;
};


export type ResidentNodeConversationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  client?: InputMaybe<Scalars['UUID']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  excludeId?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasResponseTime?: InputMaybe<Scalars['Boolean']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  isUrgent?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  residency?: InputMaybe<Scalars['UUID']['input']>;
  resident?: InputMaybe<Scalars['UUID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  subject_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type ResidentNodeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidentNodePackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type ResidentNodeTenancyapplicantSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ResidentNodeConnection = {
  readonly __typename?: 'ResidentNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ResidentNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ResidentNode` and its cursor. */
export type ResidentNodeEdge = {
  readonly __typename?: 'ResidentNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ResidentNode>;
};

export type ResidentSurveyAnswerType = {
  readonly __typename?: 'ResidentSurveyAnswerType';
  readonly answer?: Maybe<ResidentSurveyOptionType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly resident?: Maybe<ResidentType>;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user?: Maybe<UserType>;
};

export type ResidentSurveyNode = {
  readonly __typename?: 'ResidentSurveyNode';
  readonly answers: ReadonlyArray<ResidentSurveyAnswerType>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly groups: CommunityGroupNodeConnection;
  readonly id: Scalars['UUID']['output'];
  readonly link?: Maybe<Scalars['String']['output']>;
  readonly options: ReadonlyArray<ResidentSurveyOptionType>;
  readonly publishedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly question: Scalars['String']['output'];
  readonly rawCsv: Scalars['String']['output'];
  readonly residentHasAnswered?: Maybe<Scalars['Boolean']['output']>;
  readonly status: Scalars['String']['output'];
  readonly totalResidents: Scalars['Int']['output'];
};


export type ResidentSurveyNodeGroupsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ResidentSurveyNodeConnection = {
  readonly __typename?: 'ResidentSurveyNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<ResidentSurveyNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `ResidentSurveyNode` and its cursor. */
export type ResidentSurveyNodeEdge = {
  readonly __typename?: 'ResidentSurveyNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<ResidentSurveyNode>;
};

export type ResidentSurveyOptionType = {
  readonly __typename?: 'ResidentSurveyOptionType';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly option?: Maybe<Scalars['String']['output']>;
  readonly residents: ReadonlyArray<ResidentSurveyAnswerType>;
  readonly totalResidents: Scalars['Int']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
};

export type ResidentType = {
  readonly __typename?: 'ResidentType';
  readonly avatar?: Maybe<Scalars['String']['output']>;
  readonly averageScore?: Maybe<Scalars['Float']['output']>;
  readonly conversations?: Maybe<ReadonlyArray<ConversationType>>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly currentResidencies?: Maybe<ReadonlyArray<ResidencyType>>;
  readonly dateOfBirth?: Maybe<Scalars['String']['output']>;
  readonly documents: ResidencyDocumentNodeConnection;
  readonly email: Scalars['String']['output'];
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly hasAcceptedArkTerms: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly isLeaseholder?: Maybe<Scalars['Boolean']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly packages: PackageNodeConnection;
  readonly residencies: ReadonlyArray<ResidencyType>;
  readonly surveyAnswers: ReadonlyArray<ResidentSurveyAnswerType>;
  readonly tenancyapplicantSet: TenancyApplicantNodeConnection;
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user: UserType;
};


export type ResidentTypeConversationsArgs = {
  conversationLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidentTypeDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ResidentTypePackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  community?: InputMaybe<Scalars['UUID']['input']>;
  contact?: InputMaybe<Scalars['UUID']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['UUID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type ResidentTypeTenancyapplicantSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ResidentUnarchiveKeyholderInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ResidentUnarchiveKeyholderPayload = {
  readonly __typename?: 'ResidentUnarchiveKeyholderPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly keyholder?: Maybe<KeyholderNode>;
};

export type ResidentUpdateKeyholderInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly company?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ResidentUpdateKeyholderPayload = {
  readonly __typename?: 'ResidentUpdateKeyholderPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly company?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly endDate?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly startDate?: Maybe<Scalars['DateTime']['output']>;
};

/** An enumeration. */
export enum ResidentialManagementApplicationOwnershipTypeChoices {
  /** Owned */
  Ow = 'OW',
  /** Renting */
  Re = 'RE',
  /** Shared Ownership */
  So = 'SO'
}

/** An enumeration. */
export enum ResidentialManagementApplicationSourceChoices {
  /** Applicant */
  Ap = 'AP',
  /** Concierge */
  Co = 'CO'
}

/** An enumeration. */
export enum ResidentialManagementApplicationStatusChoices {
  /** Approved */
  Ap = 'AP',
  /** Document Sent */
  Ds = 'DS',
  /** Multiple Communities */
  Mc = 'MC',
  /** Pending */
  Pe = 'PE',
  /** Rejected */
  Re = 'RE',
  /** Unknown Community */
  Uc = 'UC'
}

/** An enumeration. */
export enum ResidentialManagementCommunityDocumentViewersChoices {
  /** All */
  Al = 'AL',
  /** Leaseholders */
  Le = 'LE',
  /** Residents */
  Re = 'RE'
}

/** An enumeration. */
export enum ResidentialManagementCommunityStatisticDataPointTypeChoices {
  /** ACTIVE_RESIDENT_COUNT */
  ActiveResidentCount = 'ACTIVE_RESIDENT_COUNT',
  /** AVERAGE_PACKAGE_COLLECTION_TIME */
  AveragePackageCollectionTime = 'AVERAGE_PACKAGE_COLLECTION_TIME',
  /** DOWNLOAD_RATE_PERCENTAGE */
  DownloadRatePercentage = 'DOWNLOAD_RATE_PERCENTAGE',
  /** FACILITY_BOOKINGS_COUNT */
  FacilityBookingsCount = 'FACILITY_BOOKINGS_COUNT',
  /** INCOMING_MESSAGES_COUNT */
  IncomingMessagesCount = 'INCOMING_MESSAGES_COUNT',
  /** KEY_IN_COUNT */
  KeyInCount = 'KEY_IN_COUNT',
  /** KEY_OUT_COUNT */
  KeyOutCount = 'KEY_OUT_COUNT',
  /** PACKAGE_COLLECTED_COUNT */
  PackageCollectedCount = 'PACKAGE_COLLECTED_COUNT',
  /** PACKAGE_DELIVERED_COUNT */
  PackageDeliveredCount = 'PACKAGE_DELIVERED_COUNT'
}

/** An enumeration. */
export enum ResidentialManagementCommunityUpdateCommentUserTypeChoices {
  /** Concierge */
  Co = 'CO',
  /** Manager */
  Ma = 'MA',
  /** Resident */
  Re = 'RE'
}

/** An enumeration. */
export enum ResidentialManagementCommunityUpdateCreatorTypeChoices {
  /** Concierge */
  Co = 'CO',
  /** Manager */
  Ma = 'MA'
}

/** An enumeration. */
export enum ResidentialManagementConversationCategoryChoices {
  /** Concierge */
  Co = 'CO',
  /** Documents */
  Do = 'DO',
  /** Electric */
  El = 'EL',
  /** Heating */
  He = 'HE',
  /** Other */
  Ot = 'OT',
  /** Payment Queries */
  Pa = 'PA'
}

/** An enumeration. */
export enum ResidentialManagementConversationMediaSenderChoices {
  /** Concierge */
  Co = 'CO',
  /** Leaseholder */
  Le = 'LE',
  /** Manager */
  Ma = 'MA',
  /** Resident */
  Re = 'RE'
}

/** An enumeration. */
export enum ResidentialManagementConversationMessageSenderChoices {
  /** Concierge */
  Co = 'CO',
  /** Leaseholder */
  Le = 'LE',
  /** Manager */
  Ma = 'MA',
  /** Resident */
  Re = 'RE'
}

/** An enumeration. */
export enum ResidentialManagementHomeDetailsFurnishingChoices {
  /** Fully Furnished */
  Fu = 'FU',
  /** None */
  No = 'NO',
  /** Partially Furnished */
  Pa = 'PA',
  /** Unfurnished */
  Un = 'UN'
}

/** An enumeration. */
export enum ResidentialManagementHomeDetailsParkingChoices {
  /** Not Available */
  Na = 'NA',
  /** None */
  No = 'NO',
  /** Off-Street */
  Of = 'OF',
  /** On-Street */
  On = 'ON'
}

/** An enumeration. */
export enum ResidentialManagementKeyEventTypeChoices {
  /** Check in */
  In = 'IN',
  /** Check out */
  Ou = 'OU'
}

/** An enumeration. */
export enum ResidentialManagementMessageSuggestionActionChoices {
  /** Accepted */
  Ac = 'AC',
  /** Altered */
  Al = 'AL',
  /** Ignored */
  Ig = 'IG'
}

/** An enumeration. */
export enum ResidentialManagementPackageStatusChoices {
  /** Collected */
  Co = 'CO',
  /** Delivered */
  De = 'DE'
}

export type ResponseTimeType = {
  readonly __typename?: 'ResponseTimeType';
  readonly averageResponseTime?: Maybe<Scalars['Int']['output']>;
  readonly date?: Maybe<Scalars['Date']['output']>;
};

export type RestartIdentityVerificationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type RestartIdentityVerificationPayload = {
  readonly __typename?: 'RestartIdentityVerificationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type RevokeGeneratedAgreementInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type RevokeGeneratedAgreementPayload = {
  readonly __typename?: 'RevokeGeneratedAgreementPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};

export type RightToRentNode = {
  readonly __typename?: 'RightToRentNode';
  readonly document?: Maybe<Scalars['String']['output']>;
  readonly hasShareCode: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly officialDocument?: Maybe<Scalars['String']['output']>;
  readonly officialExpiryDate?: Maybe<Scalars['Date']['output']>;
  readonly officialVerificationStatus?: Maybe<TenancyApplicationRightToRentOfficialVerificationStatusChoices>;
  readonly shareCode?: Maybe<Scalars['String']['output']>;
};

export type SaveAmenityInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly daysAvailable: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly description: Scalars['String']['input'];
  readonly endTime: Scalars['DateTime']['input'];
  readonly groups?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly live?: InputMaybe<Scalars['Boolean']['input']>;
  readonly maxAdvanceReservationDays?: InputMaybe<Scalars['Int']['input']>;
  readonly maxCapacity?: InputMaybe<Scalars['Int']['input']>;
  readonly maxHours?: InputMaybe<Scalars['Int']['input']>;
  readonly name: Scalars['String']['input'];
  readonly reservable?: InputMaybe<Scalars['Boolean']['input']>;
  readonly startTime: Scalars['DateTime']['input'];
};

export type SaveAmenityPayload = {
  readonly __typename?: 'SaveAmenityPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly daysAvailable?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly endTime?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly groups?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly live?: Maybe<Scalars['Boolean']['output']>;
  readonly maxAdvanceReservationDays?: Maybe<Scalars['Int']['output']>;
  readonly maxCapacity?: Maybe<Scalars['Int']['output']>;
  readonly maxHours?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly reservable?: Maybe<Scalars['Boolean']['output']>;
  readonly startTime?: Maybe<Scalars['DateTime']['output']>;
};

export type SaveCommunityEventInput = {
  readonly attendanceFee?: InputMaybe<Scalars['Decimal']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['String']['input'];
  readonly description: Scalars['String']['input'];
  readonly end: Scalars['DateTime']['input'];
  readonly groups: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly maxSpaces: Scalars['Int']['input'];
  readonly name: Scalars['String']['input'];
  readonly start: Scalars['DateTime']['input'];
};

export type SaveCommunityEventPayload = {
  readonly __typename?: 'SaveCommunityEventPayload';
  readonly attendanceFee?: Maybe<Scalars['Decimal']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly community?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly end?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly groups?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly maxSpaces?: Maybe<Scalars['Int']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly start?: Maybe<Scalars['DateTime']['output']>;
};

export type SaveCommunityUpdateInput = {
  readonly body: Scalars['String']['input'];
  readonly category?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly creatorType: Scalars['String']['input'];
  readonly groups?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly imageUrl?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly summary?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

export type SaveCommunityUpdatePayload = {
  readonly __typename?: 'SaveCommunityUpdatePayload';
  readonly body?: Maybe<Scalars['String']['output']>;
  readonly category?: Maybe<Scalars['String']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly creatorType?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly groups?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly summary?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type SaveGeneratedAgreementInput = {
  readonly additionalTerms?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly depositAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly file?: InputMaybe<Scalars['String']['input']>;
  readonly furnished?: InputMaybe<Scalars['String']['input']>;
  readonly homeCity?: InputMaybe<Scalars['String']['input']>;
  readonly homeName?: InputMaybe<Scalars['String']['input']>;
  readonly homePostcode?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly landlordAddress?: InputMaybe<Scalars['String']['input']>;
  readonly landlordMobile?: InputMaybe<Scalars['String']['input']>;
  readonly landlordName?: InputMaybe<Scalars['String']['input']>;
  readonly paymentFrequency?: InputMaybe<Scalars['String']['input']>;
  readonly rentAmount?: InputMaybe<Scalars['Decimal']['input']>;
  readonly tenancyEnd?: InputMaybe<Scalars['Date']['input']>;
  readonly tenancyStart?: InputMaybe<Scalars['Date']['input']>;
  readonly tenants: ReadonlyArray<InputMaybe<TenantSerializerInput>>;
  readonly utilitiesIncluded?: InputMaybe<Scalars['String']['input']>;
};

export type SaveGeneratedAgreementPayload = {
  readonly __typename?: 'SaveGeneratedAgreementPayload';
  readonly additionalTerms?: Maybe<Scalars['String']['output']>;
  readonly agreement?: Maybe<GeneratedAgreementNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly depositAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly furnished?: Maybe<Scalars['String']['output']>;
  readonly homeCity?: Maybe<Scalars['String']['output']>;
  readonly homeName?: Maybe<Scalars['String']['output']>;
  readonly homePostcode?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly landlordAddress?: Maybe<Scalars['String']['output']>;
  readonly landlordMobile?: Maybe<Scalars['String']['output']>;
  readonly landlordName?: Maybe<Scalars['String']['output']>;
  readonly paymentFrequency?: Maybe<Scalars['String']['output']>;
  readonly rentAmount?: Maybe<Scalars['Decimal']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tenancyEnd?: Maybe<Scalars['Date']['output']>;
  readonly tenancyStart?: Maybe<Scalars['Date']['output']>;
  readonly tenants?: Maybe<ReadonlyArray<Maybe<AgreementTenantNode>>>;
  readonly utilitiesIncluded?: Maybe<Scalars['String']['output']>;
};

export type SaveGeneratedSection21Input = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly deadlineDate?: InputMaybe<Scalars['Date']['input']>;
  readonly depositCertificateProvided?: InputMaybe<Scalars['Boolean']['input']>;
  readonly home?: InputMaybe<Scalars['String']['input']>;
  readonly homeCity?: InputMaybe<Scalars['String']['input']>;
  readonly homeName?: InputMaybe<Scalars['String']['input']>;
  readonly homePostcode?: InputMaybe<Scalars['String']['input']>;
  readonly homeRegion?: InputMaybe<Scalars['String']['input']>;
  readonly htrgProvided?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly inspectionDate?: InputMaybe<Scalars['Date']['input']>;
  readonly landlordAddress?: InputMaybe<Scalars['String']['input']>;
  readonly landlordEmail?: InputMaybe<Scalars['String']['input']>;
  readonly landlordName?: InputMaybe<Scalars['String']['input']>;
  readonly landlordPhone?: InputMaybe<Scalars['String']['input']>;
  readonly leadTenantEmail?: InputMaybe<Scalars['String']['input']>;
  readonly possessionDate?: InputMaybe<Scalars['Date']['input']>;
  readonly tenants?: InputMaybe<Scalars['String']['input']>;
  readonly viewedOnArk?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SaveGeneratedSection21Payload = {
  readonly __typename?: 'SaveGeneratedSection21Payload';
  readonly client?: Maybe<Scalars['String']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly deadlineDate?: Maybe<Scalars['Date']['output']>;
  readonly depositCertificateProvided?: Maybe<Scalars['Boolean']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly file?: Maybe<Scalars['String']['output']>;
  readonly home?: Maybe<Scalars['String']['output']>;
  readonly homeCity?: Maybe<Scalars['String']['output']>;
  readonly homeName?: Maybe<Scalars['String']['output']>;
  readonly homePostcode?: Maybe<Scalars['String']['output']>;
  readonly homeRegion?: Maybe<Scalars['String']['output']>;
  readonly htrgProvided?: Maybe<Scalars['Boolean']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly inspectionDate?: Maybe<Scalars['Date']['output']>;
  readonly landlordAddress?: Maybe<Scalars['String']['output']>;
  readonly landlordEmail?: Maybe<Scalars['String']['output']>;
  readonly landlordName?: Maybe<Scalars['String']['output']>;
  readonly landlordPhone?: Maybe<Scalars['String']['output']>;
  readonly leadTenantEmail?: Maybe<Scalars['String']['output']>;
  readonly possessionDate?: Maybe<Scalars['Date']['output']>;
  readonly tenants?: Maybe<Scalars['String']['output']>;
  readonly viewedOnArk?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveHomeInput = {
  readonly HMO?: InputMaybe<Scalars['Boolean']['input']>;
  readonly addressLine1?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly numRooms?: InputMaybe<Scalars['Int']['input']>;
  readonly postcode: Scalars['String']['input'];
};

export type SaveHomePayload = {
  readonly __typename?: 'SaveHomePayload';
  readonly HMO?: Maybe<Scalars['Boolean']['output']>;
  readonly addressLine1?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly community?: Maybe<Scalars['String']['output']>;
  readonly createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly numRooms?: Maybe<Scalars['Int']['output']>;
  readonly postcode?: Maybe<Scalars['String']['output']>;
};

export type SaveManagerProfileInput = {
  readonly ASTPageViewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly complianceEmailExplanation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly compliancePageViewed?: InputMaybe<Scalars['Boolean']['input']>;
  readonly complianceUpdateEmails?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly valueQuestionnaireAnswer?: InputMaybe<Scalars['Int']['input']>;
};

export type SaveManagerProfilePayload = {
  readonly __typename?: 'SaveManagerProfilePayload';
  readonly ASTPageViewed?: Maybe<Scalars['Boolean']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly complianceEmailExplanation?: Maybe<Scalars['Boolean']['output']>;
  readonly compliancePageViewed?: Maybe<Scalars['Boolean']['output']>;
  readonly complianceUpdateEmails?: Maybe<Scalars['Boolean']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly valueQuestionnaireAnswer?: Maybe<Scalars['Int']['output']>;
};

export type SaveMarketplaceItemInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['String']['input'];
  readonly condition?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly price: Scalars['Decimal']['input'];
  readonly title: Scalars['String']['input'];
};

export type SaveMarketplaceItemPayload = {
  readonly __typename?: 'SaveMarketplaceItemPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly community?: Maybe<Scalars['String']['output']>;
  readonly condition?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly price?: Maybe<Scalars['Decimal']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type SaveRequirementItemInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly expiryDate?: InputMaybe<Scalars['Date']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly requirement: Scalars['String']['input'];
};

export type SaveRequirementItemPayload = {
  readonly __typename?: 'SaveRequirementItemPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly comment?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly expiryDate?: Maybe<Scalars['Date']['output']>;
  readonly home?: Maybe<HomeType>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly requirement?: Maybe<Scalars['String']['output']>;
};

export type SaveResidentSurveyAnswerInput = {
  readonly answer?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly resident?: InputMaybe<Scalars['String']['input']>;
  readonly survey?: InputMaybe<Scalars['String']['input']>;
};

export type SaveResidentSurveyAnswerPayload = {
  readonly __typename?: 'SaveResidentSurveyAnswerPayload';
  readonly answer?: Maybe<Scalars['String']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly resident?: Maybe<Scalars['String']['output']>;
  readonly survey?: Maybe<Scalars['String']['output']>;
};

export type SaveTenantEmploymentDetailsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly employmentEnding?: InputMaybe<Scalars['Boolean']['input']>;
  readonly employmentType?: InputMaybe<Scalars['String']['input']>;
  readonly fullOrPartTime?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly income?: InputMaybe<Scalars['Decimal']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly paymentFrequency?: InputMaybe<Scalars['String']['input']>;
  readonly startDate?: InputMaybe<Scalars['Date']['input']>;
  readonly tenantReferenceId: Scalars['String']['input'];
};

export type SaveTenantEmploymentDetailsPayload = {
  readonly __typename?: 'SaveTenantEmploymentDetailsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly employmentEnding?: Maybe<Scalars['Boolean']['output']>;
  readonly employmentType?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly fullOrPartTime?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly income?: Maybe<Scalars['Decimal']['output']>;
  readonly jobTitle?: Maybe<Scalars['String']['output']>;
  readonly paymentFrequency?: Maybe<Scalars['String']['output']>;
  readonly startDate?: Maybe<Scalars['Date']['output']>;
};

export type SchedulePublishCommunityUpdateInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly scheduledFor?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SchedulePublishCommunityUpdatePayload = {
  readonly __typename?: 'SchedulePublishCommunityUpdatePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityUpdate?: Maybe<CommunityUpdateType>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SendAgreementForSigning = {
  readonly __typename?: 'SendAgreementForSigning';
  readonly tenants?: Maybe<ReadonlyArray<Maybe<AgreementSignatureOutput>>>;
};

export type SendAgreementForSigningInput = {
  readonly agreementId?: InputMaybe<Scalars['UUID']['input']>;
  readonly tenants?: InputMaybe<ReadonlyArray<InputMaybe<AgreementSignaturesTenant>>>;
};

export type SendDocument = {
  readonly __typename?: 'SendDocument';
  readonly section21?: Maybe<GeneratedSection21Type>;
};

export type SendDocumentInput = {
  readonly emails?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly id: Scalars['String']['input'];
};

export type SendManagerAmplitudeTestInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type SendManagerAmplitudeTestPayload = {
  readonly __typename?: 'SendManagerAmplitudeTestPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly manager?: Maybe<ManagerType>;
};

export type SendTenantReferenceRequestsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type SendTenantReferenceRequestsPayload = {
  readonly __typename?: 'SendTenantReferenceRequestsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly tenancyApplication?: Maybe<TenancyApplicationNode>;
};

export type SetResidencyCompletionDateInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly completionDate?: InputMaybe<Scalars['Date']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type SetResidencyCompletionDatePayload = {
  readonly __typename?: 'SetResidencyCompletionDatePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly completionDate?: Maybe<Scalars['Date']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type SetUpManagerInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly managerEmail: Scalars['String']['input'];
};

export type SetUpManagerPayload = {
  readonly __typename?: 'SetUpManagerPayload';
  readonly client?: Maybe<ClientType>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type ShareComplianceRequirementInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ShareComplianceRequirementPayload = {
  readonly __typename?: 'ShareComplianceRequirementPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type SignOutCommunityVisitorInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type SignOutCommunityVisitorPayload = {
  readonly __typename?: 'SignOutCommunityVisitorPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityVisitor?: Maybe<CommunityVisitorNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SnoozeConversationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversationId: Scalars['UUID']['input'];
  readonly snoozeForSeconds: Scalars['Int']['input'];
};

export type SnoozeConversationPayload = {
  readonly __typename?: 'SnoozeConversationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<ConversationType>;
};

export type SubmitEmployerReferenceInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitEmployerReferencePayload = {
  readonly __typename?: 'SubmitEmployerReferencePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type SubmitIdentityVerificationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitIdentityVerificationPayload = {
  readonly __typename?: 'SubmitIdentityVerificationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type SubmitLandlordReferenceInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitLandlordReferencePayload = {
  readonly __typename?: 'SubmitLandlordReferencePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type SubmitTenantReferenceInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type SubmitTenantReferencePayload = {
  readonly __typename?: 'SubmitTenantReferencePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type SubscribeLandlordInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly discountCode?: InputMaybe<Scalars['String']['input']>;
  readonly includeSetupFee?: InputMaybe<Scalars['Boolean']['input']>;
  readonly source?: InputMaybe<Scalars['String']['input']>;
};

export type SubscribeLandlordPayload = {
  readonly __typename?: 'SubscribeLandlordPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly discountCodeValid?: Maybe<Scalars['Boolean']['output']>;
  readonly firstMonthCost?: Maybe<Scalars['Float']['output']>;
  readonly monthlyCost?: Maybe<Scalars['Float']['output']>;
  readonly stripeClientSecret?: Maybe<Scalars['String']['output']>;
  readonly tierUpTo?: Maybe<Scalars['Int']['output']>;
};

export type SubscribeToNotificationGroup = {
  readonly __typename?: 'SubscribeToNotificationGroup';
  readonly unsubscribeNotificationGroup?: Maybe<UnsubscribeNotificationGroupNode>;
};

export type SubscriptionDetails = {
  readonly __typename?: 'SubscriptionDetails';
  readonly endDate: Scalars['String']['output'];
  readonly paymentMethod?: Maybe<PaymentMethod>;
  readonly price: Scalars['Float']['output'];
  readonly startDate: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
};

export type SubscriptionNode = {
  readonly __typename?: 'SubscriptionNode';
  readonly details?: Maybe<SubscriptionDetails>;
  readonly id: Scalars['UUID']['output'];
  readonly invoices?: Maybe<ReadonlyArray<Invoices>>;
  readonly residency?: Maybe<ResidencyNode>;
  readonly type?: Maybe<Scalars['String']['output']>;
  readonly upcomingInvoice?: Maybe<Invoices>;
};

export type TagConversationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversationId: Scalars['UUID']['input'];
  readonly tagId: Scalars['UUID']['input'];
};

export type TagConversationPayload = {
  readonly __typename?: 'TagConversationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversationTag?: Maybe<ConversationTagNode>;
};

export type Target = AmenityNode | ApplicationNode | CommunityNode | CommunityProductNode | CommunityUpdateNode | ConversationNode | KeyNode | ResidentSurveyNode | TenantReferenceNode;

export type TenancyApplicantNode = {
  readonly __typename?: 'TenancyApplicantNode';
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
  readonly reference?: Maybe<TenantReferenceNode>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly tenancyApplication: TenancyApplicationNode;
};

export type TenancyApplicantNodeConnection = {
  readonly __typename?: 'TenancyApplicantNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TenancyApplicantNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `TenancyApplicantNode` and its cursor. */
export type TenancyApplicantNodeEdge = {
  readonly __typename?: 'TenancyApplicantNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<TenancyApplicantNode>;
};

/** An enumeration. */
export enum TenancyApplicationEmployerReferenceStepChoices {
  /** ALMOST_DONE */
  AlmostDone = 'ALMOST_DONE',
  /** EMPLOYMENT */
  Employment = 'EMPLOYMENT',
  /** PERSONAL */
  Personal = 'PERSONAL'
}

/** An enumeration. */
export enum TenancyApplicationEmploymentDetailsEmploymentTypeChoices {
  /** CONTRACTOR */
  Contractor = 'CONTRACTOR',
  /** EMPLOYED */
  Employed = 'EMPLOYED',
  /** SELF_EMPLOYED */
  SelfEmployed = 'SELF_EMPLOYED',
  /** UNEMPLOYED */
  Unemployed = 'UNEMPLOYED'
}

/** An enumeration. */
export enum TenancyApplicationEmploymentDetailsFullOrPartTimeChoices {
  /** FULL_TIME */
  FullTime = 'FULL_TIME',
  /** PART_TIME */
  PartTime = 'PART_TIME'
}

/** An enumeration. */
export enum TenancyApplicationEmploymentDetailsPaymentFrequencyChoices {
  /** MONTHLY */
  Monthly = 'MONTHLY',
  /** WEEKLY */
  Weekly = 'WEEKLY'
}

/** An enumeration. */
export enum TenancyApplicationIdentityVerificationStatusChoices {
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** EXPIRED */
  Expired = 'EXPIRED',
  /** FAILED */
  Failed = 'FAILED',
  /** STARTED */
  Started = 'STARTED',
  /** SUBMITTED */
  Submitted = 'SUBMITTED'
}

/** An enumeration. */
export enum TenancyApplicationLandlordReferenceHasOutstandingRentChoices {
  /** NO */
  No = 'NO',
  /** YES */
  Yes = 'YES'
}

/** An enumeration. */
export enum TenancyApplicationLandlordReferenceRentPaidOnTimeChoices {
  /** NO */
  No = 'NO',
  /** YES */
  Yes = 'YES'
}

/** An enumeration. */
export enum TenancyApplicationLandlordReferenceStepChoices {
  /** ALMOST_DONE */
  AlmostDone = 'ALMOST_DONE',
  /** COMMENTS */
  Comments = 'COMMENTS',
  /** PERSONAL */
  Personal = 'PERSONAL',
  /** RENTAL_DETAILS */
  RentalDetails = 'RENTAL_DETAILS'
}

/** An enumeration. */
export enum TenancyApplicationLandlordReferenceTenancyTypeChoices {
  /** HMO */
  Hmo = 'HMO',
  /** PRIVATE_RESIDENTIAL */
  PrivateResidential = 'PRIVATE_RESIDENTIAL',
  /** SHARED */
  Shared = 'SHARED',
  /** SOCIAL_HOUSING */
  SocialHousing = 'SOCIAL_HOUSING'
}

/** An enumeration. */
export enum TenancyApplicationLandlordReferenceTreatedPropertyWellChoices {
  /** NO */
  No = 'NO',
  /** YES */
  Yes = 'YES'
}

export type TenancyApplicationNode = {
  readonly __typename?: 'TenancyApplicationNode';
  readonly affordabilityStatus: TenancyApplicationTenancyApplicationAffordabilityStatusChoices;
  readonly applicantNames?: Maybe<Scalars['String']['output']>;
  readonly applicants: TenancyApplicantNodeConnection;
  readonly combinedAffordability?: Maybe<Scalars['Int']['output']>;
  readonly completedOn?: Maybe<Scalars['Date']['output']>;
  readonly home: HomeType;
  readonly id: Scalars['UUID']['output'];
  readonly references?: Maybe<ReadonlyArray<Maybe<TenantReferenceNode>>>;
  readonly rentAmount: Scalars['Decimal']['output'];
  readonly status: TenancyApplicationTenancyApplicationStatusChoices;
  readonly unpaidTenantReferenceCount?: Maybe<Scalars['Int']['output']>;
  readonly unpaidTenantReferencesTotalCost?: Maybe<Scalars['Float']['output']>;
};


export type TenancyApplicationNodeApplicantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TenancyApplicationNodeConnection = {
  readonly __typename?: 'TenancyApplicationNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TenancyApplicationNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `TenancyApplicationNode` and its cursor. */
export type TenancyApplicationNodeEdge = {
  readonly __typename?: 'TenancyApplicationNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<TenancyApplicationNode>;
};

/** An enumeration. */
export enum TenancyApplicationRightToRentOfficialVerificationStatusChoices {
  /** LIMITED */
  Limited = 'LIMITED',
  /** PENDING */
  Pending = 'PENDING',
  /** REJECTED */
  Rejected = 'REJECTED',
  /** UNLIMITED */
  Unlimited = 'UNLIMITED'
}

/** An enumeration. */
export enum TenancyApplicationTenancyApplicationAffordabilityStatusChoices {
  /** CAUTION */
  Caution = 'CAUTION',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** FAILED */
  Failed = 'FAILED',
  /** WAITING */
  Waiting = 'WAITING'
}

/** An enumeration. */
export enum TenancyApplicationTenancyApplicationStatusChoices {
  /** CAUTION */
  Caution = 'CAUTION',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** FAILED */
  Failed = 'FAILED',
  /** WAITING */
  Waiting = 'WAITING'
}

/** An enumeration. */
export enum TenancyApplicationTenantReferenceStepChoices {
  /** ADDRESS_HISTORY */
  AddressHistory = 'ADDRESS_HISTORY',
  /** ALMOST_DONE */
  AlmostDone = 'ALMOST_DONE',
  /** EMPLOYER */
  Employer = 'EMPLOYER',
  /** EMPLOYMENT */
  Employment = 'EMPLOYMENT',
  /** IDENTITY */
  Identity = 'IDENTITY',
  /** LANDLORD */
  Landlord = 'LANDLORD',
  /** PERSONAL */
  Personal = 'PERSONAL',
  /** RIGHT_TO_RENT */
  RightToRent = 'RIGHT_TO_RENT'
}

/** An enumeration. */
export enum TenancyApplicationTenantReferenceTitleChoices {
  /** DR */
  Dr = 'DR',
  /** MISS */
  Miss = 'MISS',
  /** MR */
  Mr = 'MR',
  /** MRS */
  Mrs = 'MRS',
  /** MS */
  Ms = 'MS',
  /** MX */
  Mx = 'MX'
}

export type TenantReferenceNode = {
  readonly __typename?: 'TenantReferenceNode';
  readonly applicant: TenancyApplicantNode;
  readonly application: TenancyApplicationNode;
  readonly clientType?: Maybe<Scalars['String']['output']>;
  readonly creditCheck?: Maybe<CreditCheckNode>;
  readonly creditCheckPermission: Scalars['Boolean']['output'];
  readonly creditCheckStatus?: Maybe<Scalars['String']['output']>;
  readonly currentAddress?: Maybe<ReferenceHistoricalAddressNode>;
  readonly dateOfBirth?: Maybe<Scalars['Date']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly employerReference?: Maybe<EmployerReferenceNode>;
  readonly employmentStatus?: Maybe<Scalars['String']['output']>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly historicalAddresses: ReferenceHistoricalAddressNodeConnection;
  readonly home: HomeNode;
  readonly id: Scalars['UUID']['output'];
  readonly identityVerification?: Maybe<IdentityVerificationNode>;
  readonly identityVerificationStatus?: Maybe<Scalars['String']['output']>;
  readonly isPaid?: Maybe<Scalars['Boolean']['output']>;
  readonly landlordReference?: Maybe<LandlordReferenceNode>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly lettingHistoryStatus?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly rentAffordability?: Maybe<Scalars['Decimal']['output']>;
  readonly rightToRent?: Maybe<RightToRentNode>;
  readonly rightToRentStatus?: Maybe<Scalars['String']['output']>;
  readonly sentAt?: Maybe<Scalars['DateTime']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
  readonly step?: Maybe<TenancyApplicationTenantReferenceStepChoices>;
  readonly submittedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly tenantEmploymentDetails?: Maybe<EmploymentDetailsNode>;
  readonly title?: Maybe<TenancyApplicationTenantReferenceTitleChoices>;
};


export type TenantReferenceNodeHistoricalAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type TenantReferenceNodeConnection = {
  readonly __typename?: 'TenantReferenceNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<TenantReferenceNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `TenantReferenceNode` and its cursor. */
export type TenantReferenceNodeEdge = {
  readonly __typename?: 'TenantReferenceNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<TenantReferenceNode>;
};

export type TenantSerializerInput = {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
};

export type Tenants = {
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
};

export type TimeSlot = {
  readonly end?: InputMaybe<Scalars['DateTime']['input']>;
  readonly start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ToggleCommunityUpdateBookmarkInput = {
  readonly bookmarkedBy?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ToggleCommunityUpdateBookmarkPayload = {
  readonly __typename?: 'ToggleCommunityUpdateBookmarkPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityUpdate?: Maybe<CommunityUpdateType>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type ToggleCommunityUpdateLikeInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['UUID']['input'];
};

export type ToggleCommunityUpdateLikePayload = {
  readonly __typename?: 'ToggleCommunityUpdateLikePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly update?: Maybe<CommunityUpdateNode>;
};

export type ToggleMarketplaceItemCloseInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type ToggleMarketplaceItemClosePayload = {
  readonly __typename?: 'ToggleMarketplaceItemClosePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly marketplaceItem?: Maybe<MarketplaceItemNode>;
};

export type UnarchiveApplicationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveApplicationPayload = {
  readonly __typename?: 'UnarchiveApplicationPayload';
  readonly application?: Maybe<ApplicationNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type UnarchiveKeyInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveKeyPayload = {
  readonly __typename?: 'UnarchiveKeyPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly key?: Maybe<KeyNode>;
};

export type UnarchiveKeyholderInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveKeyholderPayload = {
  readonly __typename?: 'UnarchiveKeyholderPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly keyholder?: Maybe<KeyholderNode>;
};

export type UnbookCommunityEventMutationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly eventId: Scalars['UUID']['input'];
};

export type UnbookCommunityEventMutationPayload = {
  readonly __typename?: 'UnbookCommunityEventMutationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly event?: Maybe<CommunityEventNode>;
};

export type UnsnoozeConversationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly conversationId: Scalars['UUID']['input'];
};

export type UnsnoozeConversationPayload = {
  readonly __typename?: 'UnsnoozeConversationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly conversation?: Maybe<ConversationType>;
};

export type UnsubscribeFromNotificationGroup = {
  readonly __typename?: 'UnsubscribeFromNotificationGroup';
  readonly unsubscribeNotificationGroup?: Maybe<UnsubscribeNotificationGroupNode>;
};

export type UnsubscribeNotificationGroupNode = {
  readonly __typename?: 'UnsubscribeNotificationGroupNode';
  readonly id: Scalars['UUID']['output'];
  readonly type: PeopleUnsubscribeNotificationGroupTypeChoices;
  readonly users: ReadonlyArray<UserType>;
};

export type UnsubscribeNotificationGroupNodeConnection = {
  readonly __typename?: 'UnsubscribeNotificationGroupNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UnsubscribeNotificationGroupNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `UnsubscribeNotificationGroupNode` and its cursor. */
export type UnsubscribeNotificationGroupNodeEdge = {
  readonly __typename?: 'UnsubscribeNotificationGroupNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<UnsubscribeNotificationGroupNode>;
};

export type UpdateAttachmentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly homeId: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAttachmentPayload = {
  readonly __typename?: 'UpdateAttachmentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly homeId?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type UpdateClientAdminInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
};

export type UpdateClientAdminPayload = {
  readonly __typename?: 'UpdateClientAdminPayload';
  readonly clientAdmin?: Maybe<ClientAdminNode>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateCommunityDocumentInput = {
  readonly activatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community: Scalars['String']['input'];
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly file?: InputMaybe<Scalars['String']['input']>;
  readonly groups: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly title: Scalars['String']['input'];
  readonly viewers?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCommunityDocumentPayload = {
  readonly __typename?: 'UpdateCommunityDocumentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityDocument?: Maybe<CommunityDocumentNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type UpdateCommunityProductInput = {
  readonly active?: InputMaybe<Scalars['Boolean']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly communities: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  readonly description: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly price?: InputMaybe<Scalars['Decimal']['input']>;
};

export type UpdateCommunityProductPayload = {
  readonly __typename?: 'UpdateCommunityProductPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly communityProduct?: Maybe<CommunityProductNode>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type UpdateCommunitySettingsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly commentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly communityChatEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly deactivatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly marketplaceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly name: Scalars['String']['input'];
  readonly officeHoursEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly openPeriods: ReadonlyArray<InputMaybe<CommunityOpenPeriodSerializerInput>>;
  readonly outOfOfficeMessage?: InputMaybe<Scalars['String']['input']>;
  readonly paymentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly status: Scalars['String']['input'];
};

export type UpdateCommunitySettingsPayload = {
  readonly __typename?: 'UpdateCommunitySettingsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly commentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly communityChatEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly deactivatedOn?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly marketplaceEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly officeHoursEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly openPeriods?: Maybe<ReadonlyArray<Maybe<CommunityOpenPeriodNode>>>;
  readonly outOfOfficeMessage?: Maybe<Scalars['String']['output']>;
  readonly paymentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};

export type UpdateConversationTagInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConversationTagPayload = {
  readonly __typename?: 'UpdateConversationTagPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type UpdateConversationTitleInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly subject: Scalars['String']['input'];
};

export type UpdateConversationTitlePayload = {
  readonly __typename?: 'UpdateConversationTitlePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly subject?: Maybe<Scalars['String']['output']>;
};

export type UpdateConversationUrgency = {
  readonly __typename?: 'UpdateConversationUrgency';
  readonly conversation?: Maybe<ConversationType>;
};

export type UpdateConversationUrgencyInput = {
  readonly conversationId: Scalars['UUID']['input'];
  readonly urgency: Scalars['String']['input'];
};

export type UpdateEmployerReferenceInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployerReferencePayload = {
  readonly __typename?: 'UpdateEmployerReferencePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly companyName?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly jobTitle?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
};

export type UpdateEmployerReferenceStepInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly step?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployerReferenceStepPayload = {
  readonly __typename?: 'UpdateEmployerReferenceStepPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly step?: Maybe<Scalars['String']['output']>;
};

export type UpdateGroupInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly community?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly homes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly users?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateGroupPayload = {
  readonly __typename?: 'UpdateGroupPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly community?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly homes?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly users?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
};

export type UpdateHomeAddressInput = {
  readonly addressLine1?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly postcode: Scalars['String']['input'];
};

export type UpdateHomeAddressPayload = {
  readonly __typename?: 'UpdateHomeAddressPayload';
  readonly addressLine1?: Maybe<Scalars['String']['output']>;
  readonly city?: Maybe<Scalars['String']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly postcode?: Maybe<Scalars['String']['output']>;
};

export type UpdateHomeDetailsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly furnishing?: InputMaybe<Scalars['String']['input']>;
  readonly garden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly home: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly kitchen?: InputMaybe<Scalars['Boolean']['input']>;
  readonly numBathrooms?: InputMaybe<Scalars['Int']['input']>;
  readonly numBedrooms?: InputMaybe<Scalars['Int']['input']>;
  readonly numEnsuites?: InputMaybe<Scalars['Int']['input']>;
  readonly numLivingRooms?: InputMaybe<Scalars['Int']['input']>;
  readonly office?: InputMaybe<Scalars['Boolean']['input']>;
  readonly parking?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateHomeDetailsPayload = {
  readonly __typename?: 'UpdateHomeDetailsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly furnishing?: Maybe<Scalars['String']['output']>;
  readonly garden?: Maybe<Scalars['Boolean']['output']>;
  readonly home?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly kitchen?: Maybe<Scalars['Boolean']['output']>;
  readonly numBathrooms?: Maybe<Scalars['Int']['output']>;
  readonly numBedrooms?: Maybe<Scalars['Int']['output']>;
  readonly numEnsuites?: Maybe<Scalars['Int']['output']>;
  readonly numLivingRooms?: Maybe<Scalars['Int']['output']>;
  readonly office?: Maybe<Scalars['Boolean']['output']>;
  readonly parking?: Maybe<Scalars['String']['output']>;
};

export type UpdateKeyholderInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly company?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly endDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateKeyholderPayload = {
  readonly __typename?: 'UpdateKeyholderPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly company?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly endDate?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly notes?: Maybe<Scalars['String']['output']>;
  readonly phoneNumber?: Maybe<Scalars['String']['output']>;
  readonly startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type UpdateLandlordInput = {
  readonly address?: InputMaybe<Scalars['String']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly emailPreferences: Scalars['Boolean']['input'];
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
  readonly smsPreferences: Scalars['Boolean']['input'];
};

export type UpdateLandlordPayload = {
  readonly __typename?: 'UpdateLandlordPayload';
  readonly address?: Maybe<Scalars['String']['output']>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  readonly emailPreferences?: Maybe<Scalars['Boolean']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly smsPreferences?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateLandlordReferenceCommentsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly comments?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLandlordReferenceCommentsPayload = {
  readonly __typename?: 'UpdateLandlordReferenceCommentsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly comments?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type UpdateLandlordReferenceInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly landlordOrAgent: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly mobile: Scalars['String']['input'];
  readonly tenantReference: Scalars['String']['input'];
};

export type UpdateLandlordReferencePayload = {
  readonly __typename?: 'UpdateLandlordReferencePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly email?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly landlordOrAgent?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly tenantReference?: Maybe<Scalars['String']['output']>;
};

export type UpdateLandlordReferenceStepInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly step?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLandlordReferenceStepPayload = {
  readonly __typename?: 'UpdateLandlordReferenceStepPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly step?: Maybe<Scalars['String']['output']>;
};

export type UpdateManagerInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
};

export type UpdateManagerNotificationSettingsInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly emailPreferences: Scalars['Boolean']['input'];
};

export type UpdateManagerNotificationSettingsPayload = {
  readonly __typename?: 'UpdateManagerNotificationSettingsPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly manager?: Maybe<ManagerType>;
};

export type UpdateManagerPayload = {
  readonly __typename?: 'UpdateManagerPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly manager?: Maybe<ManagerType>;
};

export type UpdatePackageLocationInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly location?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePackageLocationPayload = {
  readonly __typename?: 'UpdatePackageLocationPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly package?: Maybe<PackageNode>;
};

export type UpdateReferenceAddressHistoryInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly historicalAddresses: ReadonlyArray<InputMaybe<ReferenceHistoricalAddressSerializerInput>>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReferenceAddressHistoryPayload = {
  readonly __typename?: 'UpdateReferenceAddressHistoryPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly historicalAddresses?: Maybe<ReadonlyArray<Maybe<ReferenceHistoricalAddressNode>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
};

export type UpdateReferenceStartedOnInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReferenceStartedOnPayload = {
  readonly __typename?: 'UpdateReferenceStartedOnPayload';
  readonly client?: Maybe<ClientType>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateResidencyAndResidents = {
  readonly __typename?: 'UpdateResidencyAndResidents';
  readonly ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateResidencyDocumentInput = {
  readonly activatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly file?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly link?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly residency: Scalars['String']['input'];
};

export type UpdateResidencyDocumentPayload = {
  readonly __typename?: 'UpdateResidencyDocumentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly residencyDocument?: Maybe<ResidencyDocumentNode>;
};

export type UpdateRightToRentInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly document?: InputMaybe<Scalars['String']['input']>;
  readonly hasShareCode?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly shareCode?: InputMaybe<Scalars['String']['input']>;
  readonly tenantReference: Scalars['String']['input'];
};

export type UpdateRightToRentPayload = {
  readonly __typename?: 'UpdateRightToRentPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly document?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly hasShareCode?: Maybe<Scalars['Boolean']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly shareCode?: Maybe<Scalars['String']['output']>;
  readonly tenantReference?: Maybe<Scalars['String']['output']>;
};

export type UpdateTenantReferenceInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly creditCheckPermission?: InputMaybe<Scalars['Boolean']['input']>;
  readonly dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTenantReferencePayload = {
  readonly __typename?: 'UpdateTenantReferencePayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly creditCheckPermission?: Maybe<Scalars['Boolean']['output']>;
  readonly dateOfBirth?: Maybe<Scalars['Date']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type UpdateTenantReferenceStepInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly step?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTenantReferenceStepPayload = {
  readonly __typename?: 'UpdateTenantReferenceStepPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly step?: Maybe<Scalars['String']['output']>;
};

export type UpdateUserInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
};

export type UpdateUserMobileNumberInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly mobile: Scalars['String']['input'];
};

export type UpdateUserMobileNumberPayload = {
  readonly __typename?: 'UpdateUserMobileNumberPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly error?: Maybe<Scalars['String']['output']>;
  readonly user?: Maybe<UserType>;
};

export type UpdateUserPayload = {
  readonly __typename?: 'UpdateUserPayload';
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
  readonly user?: Maybe<UserType>;
};

export type UpgradeLandlordToSubscriptionInput = {
  readonly clientMutationId?: InputMaybe<Scalars['String']['input']>;
  readonly generatedAgreementUuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpgradeLandlordToSubscriptionPayload = {
  readonly __typename?: 'UpgradeLandlordToSubscriptionPayload';
  readonly client?: Maybe<ClientType>;
  readonly clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UserAppUseNode = {
  readonly __typename?: 'UserAppUseNode';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly deviceBrand: Scalars['String']['output'];
  readonly deviceType: Scalars['String']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly locale?: Maybe<Scalars['String']['output']>;
  readonly systemName: Scalars['String']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly user?: Maybe<UserType>;
};

export type UserAppUseNodeConnection = {
  readonly __typename?: 'UserAppUseNodeConnection';
  /** Contains the nodes in this connection. */
  readonly edges: ReadonlyArray<Maybe<UserAppUseNodeEdge>>;
  /** Pagination data for this connection. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars['Int']['output'];
};

/** A Relay edge containing a `UserAppUseNode` and its cursor. */
export type UserAppUseNodeEdge = {
  readonly __typename?: 'UserAppUseNodeEdge';
  /** A cursor for use in pagination */
  readonly cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  readonly node?: Maybe<UserAppUseNode>;
};

export type UserType = {
  readonly __typename?: 'UserType';
  readonly appUses?: Maybe<UserAppUseNodeConnection>;
  readonly applicant?: Maybe<ApplicantNode>;
  readonly clientAdmin?: Maybe<ClientAdminNode>;
  readonly communityManager?: Maybe<CommunityManagerNode>;
  readonly concierge?: Maybe<ConciergeNode>;
  readonly email: Scalars['String']['output'];
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly hasUsedApp: Scalars['Boolean']['output'];
  readonly id: Scalars['UUID']['output'];
  readonly isDemo: Scalars['Boolean']['output'];
  readonly landlord?: Maybe<LandlordNode>;
  readonly lastAppUse?: Maybe<Scalars['DateTime']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
  readonly leaseholder?: Maybe<LeaseholderNode>;
  readonly manager?: Maybe<ManagerType>;
  readonly mobile?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly resident?: Maybe<ResidentType>;
  readonly termsAndConditionsAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly unsubscribeNotificationGroups?: Maybe<UnsubscribeNotificationGroupNodeConnection>;
};


export type UserTypeAppUsesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserTypeUnsubscribeNotificationGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CommunitySelectQueryVariables = Exact<{
  manager?: InputMaybe<Scalars['UUID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type CommunitySelectQuery = { readonly __typename?: 'DebugSchema', readonly communities?: { readonly __typename?: 'CommunityNodeConnection', readonly pageInfo: { readonly __typename?: 'PageInfo', readonly endCursor?: string | null, readonly hasNextPage: boolean }, readonly edges: ReadonlyArray<{ readonly __typename?: 'CommunityNodeEdge', readonly node?: { readonly __typename?: 'CommunityNode', readonly id: string, readonly name: string } | null } | null> } | null };

export type ListConversationsQueryVariables = Exact<{
  communities?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
  home?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  after?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  isUrgent?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  residencyId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type ListConversationsQuery = { readonly __typename?: 'DebugSchema', readonly conversations?: { readonly __typename?: 'ConversationNodeConnection', readonly pageInfo: { readonly __typename?: 'PageInfo', readonly endCursor?: string | null, readonly hasNextPage: boolean }, readonly edges: ReadonlyArray<{ readonly __typename?: 'ConversationNodeEdge', readonly node?: { readonly __typename?: 'ConversationNode', readonly id: string, readonly subject: string, readonly snoozedUntil?: string | null, readonly closedAt?: string | null, readonly hasUnread: boolean, readonly currentUrgency?: string | null, readonly user?: { readonly __typename?: 'UserType', readonly id: string, readonly firstName?: string | null, readonly lastName?: string | null, readonly email: string } | null, readonly home?: { readonly __typename?: 'HomeType', readonly name: string } | null, readonly latestEvent?: { readonly __typename?: 'ConversationActionModelType', readonly id: string, readonly createdAt: string } | { readonly __typename?: 'ConversationMediaType', readonly id: string, readonly createdAt: string, readonly mediaSender: ResidentialManagementConversationMediaSenderChoices } | { readonly __typename?: 'ConversationMessageType', readonly id: string, readonly body: string, readonly createdAt: string, readonly messageSender: ResidentialManagementConversationMessageSenderChoices } | null } | null } | null> } | null };

export type ListHomesForConversationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListHomesForConversationsQuery = { readonly __typename?: 'DebugSchema', readonly listHomes?: ReadonlyArray<{ readonly __typename?: 'HomeType', readonly id: string, readonly name: string }> | null };

export type UserCommunityItemFragment = { readonly __typename?: 'CommunityNode', readonly id: string, readonly name: string, readonly settings: { readonly __typename?: 'CommunitySettingsType', readonly newsfeed: boolean, readonly messages: boolean, readonly surveys: boolean, readonly documents: boolean, readonly events: boolean, readonly facilities: boolean, readonly marketplace: boolean, readonly accounts: boolean, readonly products: boolean } };

export type GetManagerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetManagerQuery = { readonly __typename?: 'DebugSchema', readonly getManager?: { readonly __typename?: 'ManagerType', readonly id: string, readonly email?: string | null, readonly mobile?: string | null, readonly community?: { readonly __typename?: 'CommunityNodeConnection', readonly edges: ReadonlyArray<{ readonly __typename?: 'CommunityNodeEdge', readonly node?: { readonly __typename?: 'CommunityNode', readonly id: string, readonly name: string } | null } | null> } | null } | null, readonly getClientAdmin?: { readonly __typename?: 'ClientAdminNode', readonly id: string } | null };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { readonly __typename?: 'DebugSchema', readonly getUser?: { readonly __typename?: 'UserType', readonly id: string, readonly email: string, readonly firstName?: string | null, readonly lastName?: string | null, readonly mobile?: string | null, readonly resident?: { readonly __typename?: 'ResidentType', readonly id: string } | null, readonly concierge?: { readonly __typename?: 'ConciergeNode', readonly id: string, readonly isActive?: boolean | null } | null, readonly manager?: { readonly __typename?: 'ManagerType', readonly id: string, readonly isActive?: boolean | null, readonly client?: { readonly __typename?: 'ClientType', readonly clientType: PeopleClientClientTypeChoices } | null } | null, readonly communityManager?: { readonly __typename?: 'CommunityManagerNode', readonly id: string, readonly isActive?: boolean | null, readonly communities?: { readonly __typename?: 'CommunityNodeConnection', readonly edges: ReadonlyArray<{ readonly __typename?: 'CommunityNodeEdge', readonly node?: { readonly __typename?: 'CommunityNode', readonly id: string } | null } | null> } | null } | null, readonly clientAdmin?: { readonly __typename?: 'ClientAdminNode', readonly id: string } | null } | null };

export const UserCommunityItemFragmentDoc = gql`
    fragment UserCommunityItem on CommunityNode {
  id
  name
  settings {
    newsfeed
    messages
    surveys
    documents
    events
    facilities
    marketplace
    accounts
    products
  }
}
    `;
export const CommunitySelectDocument = gql`
    query CommunitySelect($manager: UUID, $after: String, $search: String) {
  communities(
    manager: $manager
    after: $after
    search: $search
    orderBy: "name"
    includeDeactivated: true
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCommunitySelectQuery__
 *
 * To run a query within a React component, call `useCommunitySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitySelectQuery({
 *   variables: {
 *      manager: // value for 'manager'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCommunitySelectQuery(baseOptions?: Apollo.QueryHookOptions<CommunitySelectQuery, CommunitySelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunitySelectQuery, CommunitySelectQueryVariables>(CommunitySelectDocument, options);
      }
export function useCommunitySelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunitySelectQuery, CommunitySelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunitySelectQuery, CommunitySelectQueryVariables>(CommunitySelectDocument, options);
        }
export type CommunitySelectQueryHookResult = ReturnType<typeof useCommunitySelectQuery>;
export type CommunitySelectLazyQueryHookResult = ReturnType<typeof useCommunitySelectLazyQuery>;
export type CommunitySelectQueryResult = Apollo.QueryResult<CommunitySelectQuery, CommunitySelectQueryVariables>;
export const ListConversationsDocument = gql`
    query listConversations($communities: [ID], $home: UUID, $status: [String], $after: String, $search: String, $orderBy: String, $isUrgent: String, $date: Date, $contactId: UUID, $clientId: UUID, $residencyId: UUID) {
  conversations(
    communities: $communities
    home: $home
    status: $status
    after: $after
    search: $search
    orderBy: $orderBy
    isUrgent: $isUrgent
    date: $date
    contact: $contactId
    client: $clientId
    residency: $residencyId
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        subject
        snoozedUntil
        closedAt
        user {
          id
          firstName
          lastName
          email
        }
        home {
          name
        }
        hasUnread
        currentUrgency
        latestEvent {
          ... on ConversationMessageType {
            id
            body
            createdAt
            messageSender: sender
          }
          ... on ConversationMediaType {
            id
            createdAt
            mediaSender: sender
          }
          ... on ConversationActionModelType {
            id
            createdAt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListConversationsQuery__
 *
 * To run a query within a React component, call `useListConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConversationsQuery({
 *   variables: {
 *      communities: // value for 'communities'
 *      home: // value for 'home'
 *      status: // value for 'status'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *      orderBy: // value for 'orderBy'
 *      isUrgent: // value for 'isUrgent'
 *      date: // value for 'date'
 *      contactId: // value for 'contactId'
 *      clientId: // value for 'clientId'
 *      residencyId: // value for 'residencyId'
 *   },
 * });
 */
export function useListConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ListConversationsQuery, ListConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConversationsQuery, ListConversationsQueryVariables>(ListConversationsDocument, options);
      }
export function useListConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConversationsQuery, ListConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConversationsQuery, ListConversationsQueryVariables>(ListConversationsDocument, options);
        }
export type ListConversationsQueryHookResult = ReturnType<typeof useListConversationsQuery>;
export type ListConversationsLazyQueryHookResult = ReturnType<typeof useListConversationsLazyQuery>;
export type ListConversationsQueryResult = Apollo.QueryResult<ListConversationsQuery, ListConversationsQueryVariables>;
export const ListHomesForConversationsDocument = gql`
    query ListHomesForConversations {
  listHomes {
    id
    name
  }
}
    `;

/**
 * __useListHomesForConversationsQuery__
 *
 * To run a query within a React component, call `useListHomesForConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHomesForConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHomesForConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListHomesForConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ListHomesForConversationsQuery, ListHomesForConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListHomesForConversationsQuery, ListHomesForConversationsQueryVariables>(ListHomesForConversationsDocument, options);
      }
export function useListHomesForConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListHomesForConversationsQuery, ListHomesForConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListHomesForConversationsQuery, ListHomesForConversationsQueryVariables>(ListHomesForConversationsDocument, options);
        }
export type ListHomesForConversationsQueryHookResult = ReturnType<typeof useListHomesForConversationsQuery>;
export type ListHomesForConversationsLazyQueryHookResult = ReturnType<typeof useListHomesForConversationsLazyQuery>;
export type ListHomesForConversationsQueryResult = Apollo.QueryResult<ListHomesForConversationsQuery, ListHomesForConversationsQueryVariables>;
export const GetManagerDocument = gql`
    query GetManager {
  getManager {
    id
    email
    mobile
    community {
      edges {
        node {
          id
          name
        }
      }
    }
  }
  getClientAdmin {
    id
  }
}
    `;

/**
 * __useGetManagerQuery__
 *
 * To run a query within a React component, call `useGetManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagerQuery(baseOptions?: Apollo.QueryHookOptions<GetManagerQuery, GetManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManagerQuery, GetManagerQueryVariables>(GetManagerDocument, options);
      }
export function useGetManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManagerQuery, GetManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManagerQuery, GetManagerQueryVariables>(GetManagerDocument, options);
        }
export type GetManagerQueryHookResult = ReturnType<typeof useGetManagerQuery>;
export type GetManagerLazyQueryHookResult = ReturnType<typeof useGetManagerLazyQuery>;
export type GetManagerQueryResult = Apollo.QueryResult<GetManagerQuery, GetManagerQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  getUser {
    id
    email
    firstName
    lastName
    mobile
    resident {
      id
    }
    concierge {
      id
      isActive
    }
    manager {
      id
      client {
        clientType
      }
      isActive
    }
    communityManager {
      id
      isActive
      communities {
        edges {
          node {
            id
          }
        }
      }
    }
    clientAdmin {
      id
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;