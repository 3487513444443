import {DateItem} from 'common';

import {
  useNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadNotificationMutation,
} from '@/generated/graphql';
import useLoadMoreOnScroll from '@/hooks/useFetchMoreOnScroll';
import {useSubscribeToNotifications} from '@/hooks/useInitializeNotifications';
import {Notifications, NotificationsActive} from '@mui/icons-material';
import {Box, Link, Typography, useTheme} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';

type Props = {
  close: () => void;
  scrollRef: React.RefObject<HTMLElement>;
};

const NotificationsMenuContent = (props: Props) => {
  const {palette} = useTheme();
  const {data, refetch, fetchMore} = useNotificationsQuery();
  const navigate = useNavigate();

  useSubscribeToNotifications(() => {
    refetch();
  });

  const [readNotification] = useReadNotificationMutation({
    optimisticResponse: item => ({
      readNotification: {
        notification: {
          id: `${item.input.id}`,
          read: true,
        },
      },
    }),
    update: (cache, {data}) => {
      if (data?.readNotification?.notification?.id) {
        cache.modify({
          fields: {
            notifications: existing => {
              return {...existing, totalCount: existing.totalCount - 1};
            },
          },
        });
      }
    },
  });

  const [readAllNotifications] = useReadAllNotificationsMutation({
    update: cache => {
      cache.modify({
        fields: {
          notifications: existing => ({...existing, totalCount: 0}),
        },
      });
    },
    refetchQueries: ['Notifications'],
  });

  useLoadMoreOnScroll(data?.notifications, fetchMore, props.scrollRef.current);

  return (
    <>
      {data?.notifications?.totalCount === 0 && (
        <Typography m={8}>No notifications to show</Typography>
      )}
      {(data?.notifications?.totalCount || 0) > 0 && (
        <Link color={palette.info.dark} onClick={() => readAllNotifications()}>
          <Typography my={2} textAlign="center">
            Mark all as read
          </Typography>
        </Link>
      )}
      {data?.notifications?.edges.map(edge => {
        const notification = edge!.node!;
        return (
          <Box
            sx={{
              maxWidth: '500px',
              display: 'flex',
              alignItems: 'center',
              px: 3,
              py: 2,

              '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              },
            }}
            key={notification.id}
            onClick={() => {
              if (!notification.read) {
                readNotification({
                  variables: {
                    input: {
                      id: notification.id,
                    },
                  },
                });
              }
              if (notification.actionObject) {
                switch (notification.actionObject.__typename) {
                  case 'ApplicationNode':
                    navigate(`/verification/${notification.actionObject.id}`);
                    break;
                  case 'CommunityUpdateCommentNode':
                    if (
                      notification.target?.__typename === 'CommunityUpdateNode'
                    ) {
                      navigate(`/updates/${notification.target.id}/comments`);
                    }
                    break;
                  case 'KeyholderNode':
                    if (notification.target?.__typename === 'KeyNode') {
                      navigate(`/keys/${notification.target.id}`);
                    }
                    break;
                  case 'CommunityProductPurchaseNode':
                    if (
                      notification.target?.__typename === 'CommunityProductNode'
                    ) {
                      navigate(`/community-products/${notification.target.id}`);
                    }
                    break;
                  case 'AmenityReservationNode':
                    if (notification.target?.__typename === 'AmenityNode') {
                      navigate(`/facilities/${notification.target.id}`);
                    }
                    break;
                }
                return;
              }
              switch (notification.target?.__typename) {
                case 'ApplicationNode':
                  navigate(`/verification/${notification.target.id}`);
                  break;
                case 'ConversationNode':
                  navigate(`/messages/${notification.target.id}`);
                  break;
                case 'CommunityUpdateNode':
                  navigate(`/updates/${notification.target.id}/edit`);
                  break;
                case 'ResidentSurveyNode':
                  navigate(`/surveys/poll/${notification.target.id}`);
                  break;
                case 'KeyNode':
                  navigate(`/keys/${notification.target.id}`);
                  break;
                case 'TenantReferenceNode':
                  navigate(
                    `/tenant-reference-report/${notification.target.uuid}`,
                  );
                  break;
                case 'CommunityEventNode':
                  navigate(`/events/${notification.target.id}`);
                  break;
                default:
                  break;
              }
              props.close();
            }}
          >
            {notification.read ? (
              <Notifications
                sx={{
                  mr: 2,
                  color: 'grey.300',
                  fontSize: 24,
                }}
              />
            ) : (
              <NotificationsActive
                sx={{
                  mr: 2,
                  color: palette.error.light,
                  fontSize: 24,
                }}
              />
            )}
            <Box>
              <Typography key={notification.id} mb={0.5}>
                {notification.title}
              </Typography>
              <DateItem
                date={notification.createdAt}
                color={palette.grey[500]}
                relative
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default NotificationsMenuContent;
