import React from 'react';
import {ConversationListItemData} from './Conversations';
import {ConversationListItemProps} from './ConversationListItem';
import {EmptyList} from '../EmptyList';
import {useOnScrolledToBottom} from '../../hooks/useOnScrolledToBottom';
import {ListSkeleton} from '../ListSkeleton';

type Props = {
  isFiltered: boolean;
  data: ConversationListItemData[];
  ListItemComponent: React.FC<ConversationListItemProps>;
  onScrolledToBottom: () => void;
  loading: boolean;
};

export const ConversationsList = ({
  isFiltered,
  data,
  ListItemComponent,
  onScrolledToBottom,
  loading,
}: Props) => {
  useOnScrolledToBottom(onScrolledToBottom);
  if (data.length === 0 && loading) {
    return <ListSkeleton height={106} />;
  }
  if (data.length === 0) {
    if (isFiltered) {
      return (
        <EmptyList text="🔍  No conversations found, try updating the filters" />
      );
    }
    return (
      <EmptyList text="You don't have any messages yet. ✉️ Get started with the button on the top right!" />
    );
  }

  return (
    <>
      {data.map(item => (
        <ListItemComponent key={item.conversationId} data={item} />
      ))}
      {loading && <ListSkeleton height={106} />}
    </>
  );
};
